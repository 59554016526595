import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { useEffect, useState } from "react";
import Loader from "../loader/loader";
import { Model } from "../3d-model/modal";
import {IProps} from "../../mini-grid";

const Content = (props: IProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 3000); // 3-second delay

    return () => clearTimeout(timeout);
  }, []);

  return isLoaded ? (
    <>
      <Model
        scale={[0.3, 0.3, 0.3]}
        position={[0, -1.5 - 0.65, -1]}
        {...props}
      />
      <EffectComposer>
        <Bloom
          luminanceThreshold={0.4}
          luminanceSmoothing={0.9}
          intensity={1}
        />
      </EffectComposer>
    </>
  ) : (
    <Loader />
  );
};

export default Content;
