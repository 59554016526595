import { Col, Row } from "react-bootstrap";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import styles from "./app-banner-with-icon.module.scss";

interface IProps {
  content: string | React.ReactNode;
  bannerVariant: "yellow" | "lightRed" | "brown";
  icon: MtIcon;
  iconVariant: "#CBCD68" | "#CB343D" | "#CD9368";
  button?: React.ReactNode;
  alignItem?: "start" | "center" | "end";
}
const AppBannerWithIcon = (props: IProps) => {
  const {
    content,
    bannerVariant,
    icon,
    iconVariant,
    button,
    alignItem = "center",
  } = props;
  return (
    <Row
      className={`align-items-center justify-content-between mx-0 p-2 ${styles.appBanner} ${styles[bannerVariant]}`}
    >
      <Col>
        <Row className={`align-items-${alignItem}`}>
          <Col className="col-auto px-1">
            <MaterialIcon icon={icon} color={iconVariant} />
          </Col>
          <Col className="font-size-12 text-light font-weight-500">
            {content}
          </Col>
        </Row>
      </Col>
      {button && <Col className="col-auto">{button}</Col>}
    </Row>
  );
};

export default AppBannerWithIcon;
