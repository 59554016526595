import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import IGenerationFilters from "../../../shared/oversight-core/interfaces/generation-filters";
import { RootState } from "../../store";

const defaultGenerationFilters: IGenerationFilters = {
  selectedDate: new Date().getTime(),
  selectedTotalGenerationDate: new Date().getTime(),
  selectedDateType: EDateTypes.DAILY,
};

export const generationFilterSlice = createSlice({
  name: "generationFilter",
  initialState: { ...defaultGenerationFilters },
  reducers: {
    setGenerationFilter: (state, action: PayloadAction<IGenerationFilters>) => {
      state.selectedDate = action.payload.selectedDate;
      state.selectedTotalGenerationDate =
        action.payload.selectedTotalGenerationDate;
      state.selectedDateType = action.payload.selectedDateType;
    },
  },
});

export const { setGenerationFilter } = generationFilterSlice.actions;

export const selectGenerationFilter = (state: RootState) =>
  state.generationFilter;

export default generationFilterSlice.reducer;
