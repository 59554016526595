import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IFilters from "../../../shared/oversight-core/interfaces/filters";
import { ISelectedSpace } from "../../../shared/oversight-core/interfaces/selected-space";
import { RootState } from "../../store";

const defaultFilters: IFilters = {
  selectedSpace: { id: "", clusterId: "", name: "All Spaces" },
  selectedFilterSpace: { id: "", clusterId: "", name: "All Spaces" },
  searchText: "",
  selectedDeviceType: "",
  selectedGroupBy: "Space",
  badgeValue: 0,
  powerConsumerIdOfOpenedViewDeviceModal: "",
  isSpaceListChanged: false,
  isClear: false,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: { ...defaultFilters },
  reducers: {
    setFilter: (
      state,
      action: PayloadAction<
        Omit<
          IFilters,
          | "selectedFilterSpace"
          | "powerConsumerIdOfOpenedViewDeviceModal"
          | "isSpaceListChanged"
        >
      >
    ) => {
      state.selectedSpace = { ...action.payload.selectedSpace };
      state.selectedGroupBy = action.payload.selectedGroupBy;
      state.searchText = action.payload.searchText;
      state.selectedDeviceType = action.payload.selectedDeviceType;
      state.badgeValue = action.payload.badgeValue;
      state.isClear = action.payload.isClear;
    },
    setSelectedSpace: (state, action: PayloadAction<ISelectedSpace>) => {
      state.selectedSpace = { ...action.payload };
    },
    setSelectedFilterSpace: (state, action: PayloadAction<ISelectedSpace>) => {
      state.selectedSpace = { ...action.payload };
      state.selectedFilterSpace = { ...action.payload };
    },
    setSmartControllerIdOfOpenedViewDeviceModal: (
      state,
      action: PayloadAction<string>
    ) => {
      state.powerConsumerIdOfOpenedViewDeviceModal = action.payload;
    },
    setIsSpaceListChanged: (state) => {
      state.isSpaceListChanged = !state.isSpaceListChanged;
    },
  },
});

export const {
  setFilter,
  setSelectedSpace,
  setSelectedFilterSpace,
  setSmartControllerIdOfOpenedViewDeviceModal,
  setIsSpaceListChanged,
} = filterSlice.actions;

export const selectFilter = (state: RootState) => state.filter;

export default filterSlice.reducer;
