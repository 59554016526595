import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useSwitchAcControllerMutation,
  useSwitchSmartControllerMutation,
  useSwitchSpaceMutation,
  useUpdateAcTemperatureMutation,
} from "../../../../redux/api/controller/controllerAPI";
import {
  useLazyCheckDevicesWithAbnormalPowerConsumptionQuery,
  useLazyGetSpaceForControllersQuery,
} from "../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectSmartControllersFilter,
  setSelectedSmartControllerFilterSpace,
  setSmartControllerFilterSelectedSpace,
  setSmartControllersFilter,
} from "../../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import SmartControllersFilter from "../../../../shared/components/smart-controllers-filter/smart-controllers-filter";
import SpacePathViewBreadcrumb from "../../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import CheckDevicesWithAbnormalPowerConsumptionResponseDTO from "../../../../shared/oversight-core/dtos/response-dtos/check-devices-with-abnormal-power-consumption-response-dto";
import { EDeviceStatus } from "../../../../shared/oversight-core/enums/device-status";
import { OvstErrorCode } from "../../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../../shared/oversight-core/enums/smart-controller-type";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceShallowHierarchyView } from "../../../../shared/oversight-core/interfaces/space-shallow-hierarchy-view";
import AppBannerWithIcon from "../../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { showSwitchErrorMessage } from "../../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showWarningMessage,
} from "../../../../shared/oversight-core/utils/toast";
import AbnormalEnergyUsageModal from "../../space-clusters/components/models/abnormal-energy-usage-modal/abnormal-energy-usage-modal";
import AcControllerErrorModal from "../components/models/ac-controller-error-modal/ac-controller-error-modal";
import ControllerSpaceContainer from "../controller-space-container/controller-space-container";

const Controller = () => {
  const { pathname } = useLocation();
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [space, setSpace] = useState<ISpaceView[]>([]);
  const [spaceId, setSpaceId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showAcControllerErrorModal, setShowAcControllerErrorModal] =
    useState(false);
  const [deviceState, setDeviceState] = useState<EDeviceStatus>();
  const [isTemperature, setIsTemperature] = useState(false);
  const [showAbnormalEnergyUsageModal, setShowAbnormalEnergyUsageModal] =
    useState(false);
  const [isAbnormalPowerConsumers, setIsAbnormalPowerConsumers] =
    useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
    setUpdateCurrentStateDevicesWithAbnormalPowerConsumption,
  ] = useState(false);

  const smartControllersFiltersStore = useSelector(
    selectSmartControllersFilter
  );

  const [
    triggerGetSpace,
    {
      data: spaceData,
      isSuccess: getSpaceSuccess,
      isFetching: isFetchingSpace,
    },
  ] = useLazyGetSpaceForControllersQuery();
  const [switchSmartPlugState, { isLoading: isLoadingSwitchSmartPlugState }] =
    useSwitchSmartControllerMutation();
  const [
    switchAcControllerState,
    { isLoading: isLoadingSwitchAcControllerState },
  ] = useSwitchAcControllerMutation();
  const [switchSpaceState, { isLoading: isLoadingSwitchSpaceState }] =
    useSwitchSpaceMutation();
  const [updateAcTemperature, { isLoading: isLoadingUpdateAcTemperature }] =
    useUpdateAcTemperatureMutation();
  const [triggerCheckDevicesWithAbnormalPowerConsumption] =
    useLazyCheckDevicesWithAbnormalPowerConsumptionQuery();

  useEffect(() => {
    if (
      smartControllersFiltersStore.selectedSpace.clusterId &&
      smartControllersFiltersStore.selectedSpace.id
    ) {
      setClusterId(smartControllersFiltersStore.selectedSpace.clusterId);
      setSpaceId(smartControllersFiltersStore.selectedSpace.id);
    }
  }, [smartControllersFiltersStore.selectedSpace]);

  const updateCurrentState = () => {
    triggerGetSpace({ clusterId, spaceId });
  };

  useEffect(() => {
    if (clusterId && spaceId) {
      updateCurrentState();
    }
  }, [clusterId, spaceId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clusterId && spaceId) {
        updateCurrentState();
        setIsFirstTime(false);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [clusterId, spaceId]);

  useEffect(() => {
    if (getSpaceSuccess && spaceData) {
      setSpace([
        {
          ...spaceData.subSpaceCluster.rootSpace,
          clusterId: spaceData.subSpaceCluster.id,
          accountNumber:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.accountNumber
              : "",
          accountNumberLabel:
            spaceData.subSpaceCluster.actualRootSpace.id ===
            spaceData.subSpaceCluster.rootSpace.id
              ? spaceData.subSpaceCluster.serviceProviderAccount.label
              : "",
        },
      ]);
    }
  }, [getSpaceSuccess, spaceData]);

  useEffect(() => {
    if (
      smartControllersFiltersStore.selectedSpace.id !==
      getSplitPath()[getSplitPath().length - 1]
    ) {
      navigate(AppRoute.CONTROLLERS);
    }
  }, [smartControllersFiltersStore]);

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSmartControllerFilterSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const updateSmartControllersFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    selectedSmartDeviceType: string,
    selectedSearchTextType: string,
    badgeValue: number,
    isClear: boolean
  ) => {
    const currentClusterId =
      smartControllersFiltersStore.selectedSpace.clusterId;
    const currentSpaceId = smartControllersFiltersStore.selectedSpace.id;
    const currentSearchText = smartControllersFiltersStore.searchText;

    dispatch(
      setSelectedSmartControllerFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setSmartControllersFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        selectedSmartDeviceType: selectedSmartDeviceType,
        selectedSearchTextType: selectedSearchTextType,
        badgeValue: badgeValue,
        isClear: isClear,
      })
    );

    if (
      !selectedSpace.clusterId ||
      selectedSpace.clusterId !== currentClusterId ||
      selectedSpace.id !== currentSpaceId ||
      selectedDeviceType ||
      selectedSmartDeviceType ||
      selectedGroupBy !== "Space" ||
      currentSearchText !== searchText
    ) {
      navigate(AppRoute.CONTROLLERS);
    }
  };

  const updateControllerStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: EDeviceStatus,
    controllerType: ESmartControllerType
  ) => {
    (controllerType === ESmartControllerType.SMART_PLUG
      ? switchSmartPlugState({
          spaceClusterId: clusterId,
          spaceId: spaceId,
          smartPlugId: smartDeviceId,
          switchingPowerState: status,
        })
      : switchAcControllerState({
          spaceClusterId: clusterId,
          spaceId: spaceId,
          acControllerId: smartDeviceId,
          switchingPowerState: status,
        })
    )
      .unwrap()
      .then((res) => {
        updateCurrentState();
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0031) {
            setIsTemperature(false);
            setDeviceState(status);
            setShowAcControllerErrorModal(true);
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const updateSpaceStatus = (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => {
    switchSpaceState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      switchingPowerState: status,
      forceTurnOn: true,
    })
      .unwrap()
      .then((res) => {
        updateCurrentState();
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage} ${res.failedTypes}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
        }
      });
  };

  const sendSelectedSpace = (
    space: ISpaceView,
    breadcrumb?: ISpaceShallowHierarchyView[]
  ) => {
    dispatch(
      setSmartControllerFilterSelectedSpace({
        clusterId: space.clusterId,
        id: space.id,
        name: space.name,
      })
    );

    let path = "";

    breadcrumb?.forEach((bi) => {
      path += `${path}/${bi.id}`;
    });

    navigate(AppRoute.CONTROLLERS + path);
  };

  const onAcTemperatureIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    })
      .unwrap()
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONS_0031
        ) {
          setIsTemperature(true);
          setShowAcControllerErrorModal(true);
        }
      });
  };

  const onAcTemperatureDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    })
      .unwrap()
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONS_0031
        ) {
          setIsTemperature(true);
          setShowAcControllerErrorModal(true);
        }
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      triggerCheckDevicesWithAbnormalPowerConsumption({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
      })
        .unwrap()
        .then((res: CheckDevicesWithAbnormalPowerConsumptionResponseDTO) => {
          setIsAbnormalPowerConsumers(res.abnormalPowerConsumersDetected);
          if (!isAbnormalPowerConsumers) {
            setShowAbnormalEnergyUsageModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 60000);

    return () => clearTimeout(timer);
  }, [
    triggerCheckDevicesWithAbnormalPowerConsumption,
    billingSpaceFilter.spaceCluster,
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
  ]);

  return (
    <div className="position-relative">
      <div className="mb-3">
        <SmartControllersFilter
          updateFilters={updateSmartControllersFilters}
          isLoading={
            (isFetchingSpace && isFirstTime) ||
            isLoadingSwitchSmartPlugState ||
            isLoadingSwitchAcControllerState ||
            isLoadingSwitchSpaceState ||
            isLoadingUpdateAcTemperature
          }
        />
      </div>
      {isAbnormalPowerConsumers && showInstruction && (
        <div className="mb-3">
          <AppBannerWithIcon
            content="Abnormal Energy Usage of Turned Off Devices Detected..."
            bannerVariant="brown"
            icon="live_help"
            iconVariant="#CD9368"
            button={
              <Row className="align-items-center g-4">
                <Col>
                  <AppButton
                    text={"View"}
                    variant={"transparent"}
                    onClick={() => setShowAbnormalEnergyUsageModal(true)}
                    size="medium"
                    className="px-0"
                  />
                </Col>
                <Col>
                  <div
                    className="instruction-close bg-primary text-white cursor-pointer"
                    onClick={() => setShowInstruction(false)}
                  >
                    x
                  </div>
                </Col>
              </Row>
            }
          />
        </div>
      )}
      {space && (
        <div className="hierarchy-overflow bg-white rounded-3 p-2 p-md-4 mt-4">
          <SpacePathViewBreadcrumb
            selectedSpace={{
              clusterId: smartControllersFiltersStore.selectedSpace.clusterId,
              spaceId: smartControllersFiltersStore.selectedSpace.id,
            }}
            sendSelectedSpace={sendSelectedSpace}
          />
          {space.map((space) => {
            return (
              <ControllerSpaceContainer
                key={space.id}
                mainSpace={space}
                expandable={true}
                isShowIcon={true}
                size={16}
                updateCurrentState={updateCurrentState}
                collapsed={false}
                onSelectSpace={selectSpaceHandler}
                updateControllerStatus={updateControllerStatus}
                updateSpaceStatus={updateSpaceStatus}
                onAcTemperatureDecrease={onAcTemperatureDecrease}
                onAcTemperatureIncrease={onAcTemperatureIncrease}
              />
            );
          })}
        </div>
      )}
      <AcControllerErrorModal
        show={showAcControllerErrorModal}
        status={deviceState}
        isTemperature={isTemperature}
        onClose={() => {
          setShowAcControllerErrorModal(false);
        }}
        onConfirm={() => {
          setShowAcControllerErrorModal(false);
        }}
      />
      <AbnormalEnergyUsageModal
        show={showAbnormalEnergyUsageModal && isAbnormalPowerConsumers}
        onClose={() => setShowAbnormalEnergyUsageModal(false)}
        setUpdateCurrentState={
          setUpdateCurrentStateDevicesWithAbnormalPowerConsumption
        }
      />
      <SpinnerModal
        show={
          (isFetchingSpace && isFirstTime) ||
          isLoadingSwitchSmartPlugState ||
          isLoadingSwitchAcControllerState ||
          isLoadingSwitchSpaceState ||
          isLoadingUpdateAcTemperature
        }
      />
    </div>
  );
};

export default Controller;
