import { configureStore } from "@reduxjs/toolkit";
import { rootApi } from "./api/apiManager";
import { rtkQueryErrorLogger } from "./api/rtkQueryErrorLogger";
import listenerMiddleware from "./api/websocket-middleware";
import authReducer from "./features/auth/auth-slice";
import billingSpaceFilterReducer from "./features/billing-space-filter/billing-space-filter-slice";
import filterReducer from "./features/filter/filter-slice";
import generationFilterReducer from "./features/generation-filter/generation-filter-slice";
import notificationReducer from "./features/notification/notification-slice";
import scheduleFilterReducer from "./features/schedule-filter/schedule-filter-slice";
import smartControllersFilterReducer from "./features/smart-controllers-filter/smart-controllers-filter-slice";
import sourceManagementFilterReducer from "./features/source-management-filter/source-management-filter.slice";
import usageFilterReducer from "./features/usage-filter/usage-filter-slice";
import usageGuideFilterReducer from "./features/usage-guide-filter/usage-guide-filter-slice";
import userReducer from "./features/user/user-slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    filter: filterReducer,
    billingSpaceFilter: billingSpaceFilterReducer,
    smartControllersFilter: smartControllersFilterReducer,
    scheduleFilter: scheduleFilterReducer,
    usageGuideFilter: usageGuideFilterReducer,
    usageFilter: usageFilterReducer,
    notifications: notificationReducer,
    sourceManagementFilter: sourceManagementFilterReducer,
    generationFilter: generationFilterReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    //getDefaultMiddleware().concat([rootApi.middleware, rtkQueryErrorLogger]),
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat([rootApi.middleware, rtkQueryErrorLogger]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
