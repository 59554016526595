import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLazyUnLinkPowerConsumerListQuery } from "../../../../../../redux/api/controller/controllerAPI";
import { useGetSpacesQuery } from "../../../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import LinkDeviceCard from "../../../../../../shared/components/link-device-card/link-device-card";
import { EConnectionStatus } from "../../../../../../shared/oversight-core/enums/connection-status";
import { EDeviceTypes } from "../../../../../../shared/oversight-core/enums/device-types";
import { ESmartControllerType } from "../../../../../../shared/oversight-core/enums/smart-controller-type";
import { ISpaceView } from "../../../../../../shared/oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../../../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceClusterSelectedPowerConsumerViews } from "../../../../../../shared/oversight-core/interfaces/space-cluster-selected-power-consumer-views";
import ActiveInactiveIndicator from "../../../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import Pagination from "../../../../../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../../../../../shared/oversight-core/ui-elements/search/search";
import SpaceSelectorDropdown from "../../../../../../shared/oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../../../../../shared/oversight-core/utils/getDeviceType";

interface IProps extends ModelContainerProps {
  show: boolean;
  controllerName: string;
  serialNumber: string;
  sendSelectedDevice: (device: ISpaceClusterSelectedPowerConsumerViews) => void;
  deviceConnectionState: EConnectionStatus;
  serialKeyDeviceType: ESmartControllerType;
}

const ITEM_PER_PAGE = 3;
const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const DeviceLinkModal = (props: IProps) => {
  const {
    show,
    controllerName,
    serialNumber,
    deviceConnectionState,
    serialKeyDeviceType,
    sendSelectedDevice,
    ...rest
  } = props;

  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);

  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    searchValue: "",
    pageNumber: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...deviceTypes[0],
  });
  const [selectedDevice, setSelectedDevice] = useState<
    ISpaceClusterSelectedPowerConsumerViews | undefined
  >(undefined);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    id: billingSpaceFilter.spaceCluster.rootSpace.id,
    name: billingSpaceFilter.spaceCluster.label,
    clusterId: billingSpaceFilter.spaceCluster.id,
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [filteredDevice, setFilteredDevice] = useState<
    ISpaceClusterSelectedPowerConsumerViews[]
  >([]);
  const [showFilterElements, setShowFilterElements] = useState(false);
  const [allSpaceTotalElements, setAllSpacesTotalElements] = useState(0);

  const { data: spacesResponse } = useGetSpacesQuery();
  const [
    triggerUnlinkedPowerConsumerList,
    { isFetching: isLoadingUnlinkDeviceList },
  ] = useLazyUnLinkPowerConsumerListQuery();

  useEffect(() => {
    if (serialKeyDeviceType === ESmartControllerType.AC_CONTROLLER) {
      setSelectedDeviceType(
        deviceTypes.find((dt) => dt.value === "Air Conditioner") ||
          deviceTypes[8]
      );
    }
  }, [serialKeyDeviceType]);

  useEffect(() => {
    if (spacesResponse) {
      let spaceClusterView: ISpaceView[] = [];
      for (const spaceCluster of spacesResponse.spaceClusters) {
        spaceClusterView.push({
          ...spaceCluster.rootSpace,
          tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
          accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
          accountNumberLabel: spaceCluster.serviceProviderAccount.label,
          clusterId: spaceCluster.id,
        });
      }
      spaceClusterView = spaceClusterView.sort((a, b) =>
        a.name.trim().localeCompare(b.name.trim())
      );
      setSpaceClusters([...spaceClusterView]);
    } else {
      setSpaceClusters([]);
    }
  }, [spacesResponse]);

  useEffect(() => {
    if (!selectedDeviceType) {
      if (selectedSpace.clusterId && selectedSpace.id) {
        triggerUnlinkedPowerConsumerList({
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.id,
          pageNumber: filters.pageNumber,
          searchField: "name",
          searchValue: filters.searchValue,
          groupByType: true,
          ascending: true,
          pageSize: ITEM_PER_PAGE,
          smartDeviceType: serialKeyDeviceType,
        })
          .unwrap()
          .then((res) => {
            setFilteredDevice(res.deviceList.elements);
            setTotalElements(res.deviceList.totalElements);
          })
          .catch(() => {
            setFilteredDevice([]);
            setTotalElements(0);
          });
      }
    } else {
      if (selectedSpace.clusterId && selectedSpace.id) {
        triggerUnlinkedPowerConsumerList({
          searchField: "name",
          searchValue: filters.searchValue,
          type: selectedDeviceType.value,
          pageNumber: filters.pageNumber,
          pageSize: ITEM_PER_PAGE,
          spaceClusterId: selectedSpace.clusterId,
          subRootSpaceId: selectedSpace.id,
          ascending: true,
          smartDeviceType: serialKeyDeviceType,
        })
          .unwrap()
          .then((res) => {
            setFilteredDevice(res.deviceList.elements);
            setTotalElements(res.deviceList.totalElements);
            if (
              selectedSpace.id ===
                billingSpaceFilter.spaceCluster.rootSpace.id &&
              selectedSpace.clusterId === billingSpaceFilter.spaceCluster.id
            ) {
              setAllSpacesTotalElements(res.deviceList.totalElements);
            }
          })
          .catch(() => {
            setFilteredDevice([]);
            setTotalElements(0);
            setAllSpacesTotalElements(0);
          });
      }
    }
  }, [
    selectedSpace.clusterId,
    selectedSpace.id,
    filters,
    selectedDeviceType,
    show,
  ]);

  useEffect(() => {
    if (show) {
      if (serialKeyDeviceType === ESmartControllerType.AC_CONTROLLER) {
        setSelectedDeviceType(
          deviceTypes.find((dt) => dt.value === "Air Conditioner") ||
            deviceTypes[8]
        );
      } else {
        setSelectedDeviceType({ ...deviceTypes[0] });
      }
      setSelectedDevice(undefined);
      setSearchInput("");
      setSelectedSpace({
        id: billingSpaceFilter.spaceCluster.rootSpace.id,
        name: billingSpaceFilter.spaceCluster.label,
        clusterId: billingSpaceFilter.spaceCluster.id,
      });
    }
  }, [show, serialKeyDeviceType, billingSpaceFilter]);

  useEffect(() => {
    setShowFilterElements(
      allSpaceTotalElements > 0 ||
        searchInput !== "" ||
        selectedDeviceType.value !== undefined
    );
  }, [allSpaceTotalElements]);

  return (
    <ModalContainer
      {...rest}
      title={"Link Controller To"}
      show={show}
      size="modal-lg"
      confirmButtonText="Link"
      cancelButtonText="Cancel"
      disabled={filteredDevice.length < 1}
      className="mt-0"
      onConfirm={() => {
        if (selectedDevice) {
          sendSelectedDevice(selectedDevice);
          if (rest.onConfirm) {
            rest.onConfirm();
          }
        }
      }}
    >
      <div>
        <Row>
          {(controllerName || serialNumber) && (
            <Col className="col-auto pe-0 mt-1">
              <MaterialIcon
                icon={
                  serialKeyDeviceType === ESmartControllerType.SMART_PLUG
                    ? `smart_outlet`
                    : `missing_controller`
                }
                color="#30858A"
                className="bg-activeBg-4 rounded-1 p-2"
              />
            </Col>
          )}
          <Col>
            <Row>
              <Col className="font-size-16 font-weight-500 text-dark">
                {controllerName}
              </Col>
            </Row>
            <Row className="align-items-center g-1">
              <Col
                xs="auto"
                className="font-size-12 font-weight-400 text-light"
              >
                {serialNumber}
              </Col>
              {serialNumber && (
                <Col>
                  <ActiveInactiveIndicator
                    isActive={
                      deviceConnectionState === EConnectionStatus.CONNECTED
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {showFilterElements && (
          <>
            <Row className="align-items-end justify-content-between mt-2">
              <Col xs={12} sm={5} className="mt-3 mt-sm-0">
                <Search
                  placeholder="Search by Device Name"
                  onSearch={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      searchValue: searchInput,
                      pageNumber: 0,
                    }));
                  }}
                  handleSearchChange={(input) => {
                    setSearchInput(input);
                    if (!input) {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        searchValue: input,
                        pageNumber: 0,
                      }));
                    }
                  }}
                  value={searchInput}
                />
              </Col>
              {serialKeyDeviceType !== ESmartControllerType.AC_CONTROLLER && (
                <Col xs={12} sm={5} className="mt-3 mt-sm-3 mt-md-0">
                  <AppSelect
                    label="Device Type"
                    options={[...deviceTypes]}
                    selectedValue={selectedDeviceType}
                    onChangeOption={(selectedDevice) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        pageNumber: 0,
                      }));
                      setSelectedDeviceType(selectedDevice);
                    }}
                    fontSize="12px"
                    fontWeight="500"
                    fontColor="#69768b"
                    menuHeight="250px"
                    labelFontSize="font-size-14"
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-2">
              <Col>
                <SpaceSelectorDropdown
                  dropdownOptions={[
                    ...spaceClusters
                      .filter(
                        (space) =>
                          space.clusterId === billingSpaceFilter.spaceCluster.id
                      )
                      .map((space) => {
                        return {
                          clusterId: space.clusterId,
                          id: space.id,
                          name: space.name,
                          childSpaces: space.childSpaces,
                        };
                      }),
                  ]}
                  disabled={false}
                  selectedSubSpace={selectedSpace.name}
                  updateSelectedSubSpaceId={(
                    selectedSubSpaceId: string,
                    selectedSubSpaceName: string,
                    clusterId?: string
                  ) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      pageNumber: 0,
                    }));
                    setSelectedSpace({
                      id: selectedSubSpaceId,
                      name: selectedSubSpaceName,
                      clusterId: clusterId || "",
                    });
                  }}
                  label="Space"
                  className="font-weight-500 text-light"
                  maxHeight={totalElements >= 1 ? "230px" : "180px"}
                />
              </Col>
            </Row>
          </>
        )}
        <Row className={`${totalElements < 1 && `py-5`} mt-2 mx-0`}>
          {!isLoadingUnlinkDeviceList ? (
            <>
              {filteredDevice.length > 0 ? (
                filteredDevice.map((device, index) => (
                  <div key={index} className="mt-2">
                    <LinkDeviceCard
                      device={device}
                      onChange={(device) => setSelectedDevice(device)}
                      selectedDevice={selectedDevice}
                      setExcludedSemiAutoScheduleList={Array.isArray}
                    />
                  </div>
                ))
              ) : (
                <div className="container-dash">
                  <Row className="py-5">
                    <Col className="text-center text-light font-size-12">
                      There are no available devices.
                    </Col>
                  </Row>
                </div>
              )}
            </>
          ) : (
            <SpinnerModal show={isLoadingUnlinkDeviceList} />
          )}
        </Row>
        <Pagination
          itemsPerPage={ITEM_PER_PAGE}
          length={totalElements}
          currentPage={filters.pageNumber + 1}
          updateCurrentPage={(pn) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              pageNumber: pn - 1,
            }));
          }}
        />
      </div>
    </ModalContainer>
  );
};

export default DeviceLinkModal;
