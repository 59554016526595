import { Html, Line } from "@react-three/drei";
import { Col, Container, Row } from "react-bootstrap";
import { Vector2, Vector3 } from "three";
import MaterialIcon, {
  MtIcon,
} from "../../../../oversight-core/ui-elements/material-icon/material-icon";

interface IProps {
  startPoint?:
    | number
    | Vector3
    | Vector2
    | [number, number, number]
    | [number, number];
  endPoint?:
    | number
    | Vector3
    | Vector2
    | [number, number, number]
    | [number, number];
  icon: MtIcon;
  title: string;
  value: string;
  rotation?: number[];
  distanceFactor?: number;
  width?: number;
}

const Label = (props: IProps) => {
  const {
    startPoint = [0, 0, 0],
    endPoint = [0, 2, 0],
    rotation = [0, 0, 0],
    distanceFactor = 2,
    width = 120,
  } = props;
  return (
    <>
      <Line
        points={[startPoint, endPoint]} // start and end points of the line
        color="black" // line color
        lineWidth={2} // line width
        rotation={[rotation[0], rotation[1], rotation[2]]}
      />
      <Html position={endPoint as Vector3} distanceFactor={distanceFactor}>
        <div
          style={{
            height: 100,
            width: width,
          }}
        >
          <div
            style={{
              transform: `translateY(-40px) translateX(${-width / 2}px)`,
              background: "rgba(255, 255, 255, 0.2)",
              borderRadius: "8px",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(5px)",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              color: "#333",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            <Container fluid>
              <Row>
                <Col xs="auto" className="pe-1">
                  <MaterialIcon icon={props.icon} size={18} />{" "}
                </Col>
                <Col xs={"auto"} className="ps-0">
                  {props.title}
                </Col>
              </Row>
              <Row>
                <Col
                  className="text-start"
                  style={{ fontWeight: "600", fontSize: "20px" }}
                >
                  {props.value}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Html>
    </>
  );
};

export default Label;
