import { Col, Row } from "react-bootstrap";
import { EEnergySourceType } from "../../enums/energy-source-type";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import styles from "./energy-source-info-card.module.scss";

interface IProps {
  value: number;
  icon: MtIcon;
  text: string;
  sourceType?: EEnergySourceType;
}

const EnergySourceInfoCard = (props: IProps) => {
  const { value, icon, text, sourceType } = props;

  const changeBgColor = () => {
    switch (sourceType) {
      case EEnergySourceType.SOLAR_PV:
        return "greenContainer";
      case EEnergySourceType.WIND_TURBINE:
        return "blueContainer";
      default:
        return "whiteContainer";
    }
  };

  return (
    <Row
      className={`${styles.energySourceInfoContainer} ${
        styles[`${changeBgColor()}`]
      } py-1 align-items-center`}
    >
      <Col className="col-auto pe-0">
        <MaterialIcon
          icon={icon}
          color={sourceType ? "#FFFFFF" : "#1C1B1F"}
          size={24}
        />
      </Col>
      <Col className="ps-2">
        <Row>
          <Col className="font-size-14 font-weight-600 text-dark">
            {value} kW
          </Col>
        </Row>
        <Row>
          <Col
            className={`${
              sourceType ? "" : "text-light"
            } font-size-12 font-weight-500`}
          >
            {text}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EnergySourceInfoCard;
