import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { IPowerConsumerTypeAvailabilityViews } from "../../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-device-availability-response-dto";
import { ELimitationType } from "../../../../../../shared/oversight-core/enums/limitation-type";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import styles from "./usage-guide-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceType: string;
  limitationType: ELimitationType;
  usageGuideTimeList: IPowerConsumerTypeAvailabilityViews[];
}

const UsageGuideModal = (props: IProps) => {
  const { limitationType, deviceType, usageGuideTimeList, show, ...rest } =
    props;

  return (
    <ModalContainer
      title={
        <Row className="align-items-center">
          <Col className="col-auto font-size-22 font-weight-500 text-dark-2 pe-0">
            {limitationType === ELimitationType.ONLY_GIVE_WARNING
              ? "Reduction of Usage is Recommended"
              : "Usage is Limited "}
          </Col>
          {limitationType && (
            <Col className="col-auto">
              <div className={styles.limitationWrapper}>
                <MaterialIcon
                  icon={
                    limitationType === ELimitationType.ONLY_GIVE_WARNING
                      ? "error"
                      : "release_alert"
                  }
                  color={
                    limitationType === ELimitationType.ONLY_GIVE_WARNING
                      ? "#E8C304"
                      : "#EC7575"
                  }
                  size={18}
                />
              </div>
            </Col>
          )}
        </Row>
      }
      {...rest}
      show={show}
      size={`${
        limitationType === ELimitationType.ONLY_GIVE_WARNING
          ? `modal-lg`
          : `modal-md`
      }`}
      isButtonsVisible={false}
    >
      <>
        <Row>
          {limitationType === ELimitationType.EXCLUDE_FROM_SCHEDULE ? (
            <Col>
              Usage of {deviceType} device type is blocked for semi-automated
              scheduling within following hours
            </Col>
          ) : (
            <Col>
              Usage of {deviceType} device type is recommended to be reduced for
              semi-automated scheduling within following hours
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          {usageGuideTimeList
            .filter((powerConsumerTypeAvailabilityView) =>
              Object.hasOwn(
                powerConsumerTypeAvailabilityView.powerConsumerTypeAvailability,
                deviceType
              )
            )
            .map((powerConsumerTypeAvailabilityView) => {
              const guide =
                powerConsumerTypeAvailabilityView.powerConsumerTypeAvailability[
                  deviceType
                ].filter((g) => g[limitationType]);

              {
                return guide.map((g) => {
                  {
                    return g[limitationType].dateTimeRanges.map(
                      (usageGuideTime, index2) => {
                        return (
                          <Col
                            className="col-auto font-size-10 font-weight-500 text-gray mt-2"
                            key={index2}
                          >
                            <div
                              className={`p-2 ${
                                limitationType ===
                                ELimitationType.EXCLUDE_FROM_SCHEDULE
                                  ? styles.timeExclude
                                  : styles.timeWarning
                              }`}
                            >
                              <Row className="flex-column">
                                <Col className="col-auto">
                                  {`${moment(usageGuideTime.fromDate).format(
                                    "YYYY.MM.DD"
                                  )} ${
                                    g[limitationType].expiryDate
                                      ? "- " +
                                        moment(
                                          g[limitationType].expiryDate
                                        ).format("YYYY.MM.DD")
                                      : ""
                                  }`}
                                </Col>
                                <Col className="col-auto">
                                  {moment(usageGuideTime.fromDate).format(
                                    "HH:mm"
                                  )}
                                  {" - "}
                                  {moment(usageGuideTime.toDate).format(
                                    "HH:mm"
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        );
                      }
                    );
                  }
                });
              }
            })}
        </Row>
      </>
    </ModalContainer>
  );
};

export default UsageGuideModal;
