import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useRemoveDeviceMutation } from "../../../../../redux/api/device/deviceAPI";
import { useRemoveSpaceMutation } from "../../../../../redux/api/space/spaceAPI";
import {
  selectBillingSpaceFilter,
  setIsSpaceClusterListChanged,
} from "../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  setIsSpaceListChanged,
  setSelectedSpace,
} from "../../../../../redux/features/filter/filter-slice";
import { setScheduleFiltersSpaceAndSpaceDetails } from "../../../../../redux/features/schedule-filter/schedule-filter-slice";
import DeviceListCard from "../../../../../shared/components/device-list-card/device-list-card";
import { EConnectionStatus } from "../../../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../../../shared/oversight-core/enums/device-status";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import InfoModal from "../../../../../shared/oversight-core/shared-components/info-modal/info-modal";
import AppDropDown from "../../../../../shared/oversight-core/ui-elements/app-dropdown/app-drop-down";
import BulkAppToggle from "../../../../../shared/oversight-core/ui-elements/bulk-app-toggle/bulk-app-toggle";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import IconButton from "../../../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import AddUpdateDeviceModal from "../models/add-update-device-modal/add-update-device-model";
import AddUpdateSpaceModal from "../models/add-update-space-modal/add-update-space-modal";
import DeviceBulkConfirmationModal from "../models/device-bulk-confirmation-modal/device-bulk-confirmation-modal";
import RemoveSpaceModal from "../models/remove-space-modal/remove-space-modal";

interface IProps {
  mainSpace: ISpaceView;
  expandable: boolean;
  isShowIcon: boolean;
  size: number;
  updateCurrentState: () => void;
  collapsed: boolean;
  onSelectSpace: (space: ISpaceView) => void;
  isPowerConsumerUpdated: boolean;
  setIsPowerConsumerUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  updateDeviceStatus: (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus
  ) => void;
  updateSpaceStatus: (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => void;
  isUpdateDeviceStateLoading: boolean;
  onAcTemperatureIncrease: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  id: string;
}

const SpaceContainer = (props: IProps) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(props.collapsed);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [showAddUpdateDeviceModal, setShowAddUpdateDeviceModal] =
    useState(false);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [showRemoveSpaceModal, setShowRemoveSpaceModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeviceBulkConfirmationModal, setShowDeviceBulkConfirmationModal] =
    useState(false);
  const [bulkDeviceState, setBulkDeviceState] = useState<EDeviceStatus>(
    EDeviceStatus.OFF
  );
  const [
    removeSpace,
    { isSuccess: removeSpaceSuccess, isLoading: isLoadingRemoveSpace },
  ] = useRemoveSpaceMutation();
  const [
    removeDevice,
    { isSuccess: removeDeviceSuccess, isLoading: isLoadingRemoveDevice },
  ] = useRemoveDeviceMutation();
  const [editSpace, setEditSpace] = useState(false);
  const [spaceCreationType, setSpaceCreationType] = useState<
    "space" | "spaceCluster"
  >("space");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (removeDeviceSuccess) {
      showSuccessMessage("Device Deleted Successfully");
      props.updateCurrentState();
    }

    const segments = pathname.split("/");
    const lastSegment = segments.pop();

    if (removeSpaceSuccess && props.mainSpace.id === lastSegment) {
      navigate(-1);
    }
  }, [removeSpaceSuccess, removeDeviceSuccess]);

  const deleteDevice = (
    clusterId: string,
    spaceId: string,
    deviceId: string,
    keepInProgramme: boolean,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    removeDevice({
      clusterId,
      spaceId,
      deviceId,
      keepInProgramme,
      excludedSemiAutomatedSchedules,
    })
      .unwrap()
      .catch(() => {
        showErrorMessage("Removing Device Unsuccessful");
      });
  };

  const closeAddUpdateSpaceModal = () => {
    setShowAddUpdateSpaceModal(false);
  };

  const closeAddUpdateDeviceModal = () => {
    setShowAddUpdateDeviceModal(false);
  };

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const checkDeviceConnectionState = (space: ISpaceView): boolean => {
    const smartControllerWithSpaceCluster = space.smartDevices.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED
    );

    if (smartControllerWithSpaceCluster) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  return (
    <div className="space-container py-3 px-2 my-3">
      <Container fluid>
        <div
          onClick={() => props.onSelectSpace(props.mainSpace)}
          style={{ cursor: "pointer" }}
        >
          <Row>
            <Col className="pc-header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {props.isShowIcon && (
                  <MaterialIcon icon={"apartment"} className="me-1 d-inline" />
                )}

                <label
                  className="me-4"
                  style={{
                    color: props.isShowIcon ? `#383941` : `#69768B`,
                    fontSize: props.size,
                  }}
                >
                  {props.mainSpace.name}
                </label>
                <div
                  className={`${
                    !checkDeviceConnectionState(props.mainSpace)
                      ? `opacity-50`
                      : ``
                  } me-4`}
                >
                  <BulkAppToggle
                    status={props.mainSpace.powerState}
                    onSwitch={(powerState: EDeviceStatus) => {
                      if (checkDeviceConnectionState(props.mainSpace)) {
                        setBulkDeviceState(powerState);
                        setShowDeviceBulkConfirmationModal(true);
                      }
                    }}
                    isDisable={!checkDeviceConnectionState(props.mainSpace)}
                  />
                </div>
                <AppDropDown
                  id={props.id}
                  items={[
                    {
                      text: "Add Subspace",
                      onClick: () => {
                        setSpaceCreationType("space");
                        setEditSpace(false);
                        setShowAddUpdateSpaceModal(true);
                      },
                    },
                    {
                      text: "Edit Space",
                      onClick: () => {
                        if (props.mainSpace.accountNumber) {
                          setSpaceCreationType("spaceCluster");
                        } else {
                          setSpaceCreationType("space");
                        }
                        setEditSpace(true);
                        setShowAddUpdateSpaceModal(true);
                      },
                    },
                    {
                      text: "Remove Space",
                      onClick: () => {
                        setShowRemoveSpaceModal(true);
                      },
                    },
                  ]}
                />
              </div>
              <div className="d-flex align-items-center">
                <AppButton
                  text="Add Device"
                  size="small"
                  className="me-2 d-none d-sm-block"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    ReactGA.event({
                      category: "Click",
                      action: "Add Device Button Click",
                    });
                    setShowAddUpdateDeviceModal(true);
                  }}
                />
                <IconButton
                  icon="add"
                  className="d-block d-sm-none"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setShowAddUpdateDeviceModal(true);
                  }}
                />
                {props.expandable && (
                  <MaterialIcon
                    icon="expand_more"
                    className="cursor-pointer dArrow"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                      setCollapsed(!collapsed);
                    }}
                    style={
                      collapsed
                        ? { transform: "rotate(0deg)" }
                        : { transform: "rotate(180deg)" }
                    }
                  />
                )}
              </div>
            </Col>
          </Row>
          <div
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
            style={{ cursor: "default" }}
          >
            <Row className={`${collapsed && "d-none"} mt-3`}>
              {props.mainSpace.powerConsumers.map((d) => (
                <Col
                  key={props.mainSpace.clusterId + props.mainSpace.id + d.id}
                  className="py-2 col-12 col-lg-6 col-xxl-4 px-2"
                >
                  <DeviceListCard
                    deleteDevice={deleteDevice}
                    updateCurrentState={props.updateCurrentState}
                    device={{
                      ...d,
                      clusterId: props.mainSpace.clusterId,
                      spaceId: props.mainSpace.id,
                    }}
                    mainSpaceClusterId={props.mainSpace.clusterId}
                    mainSpaceId={props.mainSpace.id}
                    smartDevices={props.mainSpace.smartDevices
                      .filter(
                        (smartDevice) =>
                          smartDevice.linkedPowerConsumer?.id === d.id
                      )
                      .map((sd) => {
                        return {
                          ...sd,
                          clusterId: props.mainSpace.clusterId,
                          spaceId: props.mainSpace.id,
                        };
                      })}
                    isPowerConsumerUpdated={props.isPowerConsumerUpdated}
                    setIsPowerConsumerUpdated={props.setIsPowerConsumerUpdated}
                    updateDeviceStatus={props.updateDeviceStatus}
                    isUpdateDeviceStateLoading={
                      props.isUpdateDeviceStateLoading
                    }
                    onAcTemperatureDecrease={props.onAcTemperatureDecrease}
                    onAcTemperatureIncrease={props.onAcTemperatureIncrease}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <Row className={`${collapsed && "d-none"}`}>
          <Col>
            {props.mainSpace.childSpaces &&
              props.mainSpace.childSpaces.length > 0 && (
                <div>
                  {props.mainSpace.childSpaces.map((ss, index) => (
                    <SpaceContainer
                      key={index}
                      mainSpace={{
                        ...ss,
                        clusterId: props.mainSpace.clusterId,
                      }}
                      expandable={true}
                      isShowIcon={false}
                      size={14}
                      updateCurrentState={props.updateCurrentState}
                      collapsed={true}
                      onSelectSpace={selectSpaceHandler}
                      isPowerConsumerUpdated={props.isPowerConsumerUpdated}
                      setIsPowerConsumerUpdated={
                        props.setIsPowerConsumerUpdated
                      }
                      updateSpaceStatus={props.updateSpaceStatus}
                      updateDeviceStatus={props.updateDeviceStatus}
                      isUpdateDeviceStateLoading={
                        props.isUpdateDeviceStateLoading
                      }
                      onAcTemperatureDecrease={props.onAcTemperatureDecrease}
                      onAcTemperatureIncrease={props.onAcTemperatureIncrease}
                      id={`space-dropdown-id-${props.mainSpace.clusterId}-${ss.id}`}
                    />
                  ))}
                </div>
              )}
          </Col>
        </Row>
      </Container>
      <AddUpdateDeviceModal
        show={showAddUpdateDeviceModal}
        onClose={() => setShowAddUpdateDeviceModal(false)}
        onCancel={() => setShowAddUpdateDeviceModal(false)}
        spaceClusterId={props.mainSpace.clusterId}
        spaceId={props.mainSpace.id}
        closeAddUpdateDeviceModal={closeAddUpdateDeviceModal}
        updateCurrentState={props.updateCurrentState}
      />
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceClusterId={props.mainSpace.clusterId}
        parentSpaceId={props.mainSpace.id}
        spaceClusterDetails={props.mainSpace}
        isEditMode={editSpace}
        spaceCreationType={spaceCreationType}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        updateCurrentState={props.updateCurrentState}
        closeAddUpdateSpaceModal={closeAddUpdateSpaceModal}
      />
      <RemoveSpaceModal
        show={showRemoveSpaceModal}
        onClose={() => setShowRemoveSpaceModal(false)}
        onCancel={() => setShowRemoveSpaceModal(false)}
        spaceName={props.mainSpace.name}
        onConfirm={() => {
          if (props.mainSpace.childSpaces.length === 0) {
            removeSpace({
              clusterId: props.mainSpace.clusterId,
              spaceId: props.mainSpace.id,
            })
              .unwrap()
              .then(() => {
                const message = props.mainSpace.accountNumber
                  ? "Billing Space Deleted Successfully"
                  : "Space Deleted Successfully";

                showSuccessMessage(message);
                props.updateCurrentState();
                dispatch(setIsSpaceListChanged());
                dispatch(
                  setScheduleFiltersSpaceAndSpaceDetails({
                    selectedSpace: {
                      ...billingSpaceFilter.spaceCluster.rootSpace,
                      clusterId: billingSpaceFilter.spaceCluster.id,
                    },
                    selectedSpaceDetails: {
                      spaceClusterId: billingSpaceFilter.spaceCluster.id,
                      spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
                    },
                  })
                );
                if (props.mainSpace.accountNumber) {
                  dispatch(setIsSpaceClusterListChanged());
                }
              })
              .catch(() => {
                showErrorMessage("Removing Space Unsuccessful");
              });
          } else {
            setShowInfoModal(true);
          }
          setShowRemoveSpaceModal(false);
        }}
      />
      <InfoModal
        show={showInfoModal}
        message="This space contains child spaces hence can&rsquo;t be deleted. Child spaces of this space should be deleted before deleting this space."
        hideCancel={true}
        onConfirm={() => {
          setShowInfoModal(false);
        }}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        onClose={() => {
          setShowInfoModal(false);
        }}
      />
      <DeviceBulkConfirmationModal
        show={showDeviceBulkConfirmationModal}
        onClose={() => setShowDeviceBulkConfirmationModal(false)}
        onCancel={() => setShowDeviceBulkConfirmationModal(false)}
        onConfirm={() => {
          if (checkDeviceConnectionState(props.mainSpace))
            props.updateSpaceStatus(
              props.mainSpace.clusterId,
              props.mainSpace.id,
              bulkDeviceState
            );
          setShowDeviceBulkConfirmationModal(false);
        }}
        spaceState={bulkDeviceState}
        spaceName={props.mainSpace.name}
      />
      <SpinnerModal show={isLoadingRemoveSpace || isLoadingRemoveDevice} />
    </div>
  );
};

export default SpaceContainer;
