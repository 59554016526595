import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AppButton from "../buttons/app-button/app-button";
import styles from "./search.module.scss";
interface IProps {
  handleSearchChange: (searchText: string) => void;
  onSearch: () => void;
  placeholder: string;
  value: string;
  isWithOptions?: boolean;
}

const Search = (props: IProps) => {
  const { handleSearchChange, onSearch, placeholder, isWithOptions, value } =
    props;
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (!value && !firstTime) {
      onSearch();
      return;
    }

    setFirstTime(false);
  }, [value]);

  return (
    <div
      className={`${
        !isWithOptions
          ? styles[`search-wrapper`]
          : styles[`search-wrapper-style-two`]
      }`}
    >
      <Row className={`align-items-center`}>
        <Col className="pe-0">
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearchChange(e.target.value);
            }}
            className={`${styles[`search-input`]} ps-2 w-100`}
          />
        </Col>
        <Col className="col-auto px-0">
          <AppButton
            text="Search"
            iconName="search"
            iconOnly
            size="medium"
            onClick={() => {
              onSearch();
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Search;
