import ListNotificationsRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/list-notifications-request-dto";
import CountUnreadNotificationsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/count-unread-notifications-response-dto";
import ListNotificationsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/list-notifications-response-dto";
import { NotificationView } from "../../../shared/oversight-core/interfaces/notification-view";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import {
  addNotifications,
  setUnreadNotificationCount,
} from "../../features/notification/notification-slice";
import { rootApi } from "../apiManager";

export const notificationAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      ListNotificationsResponseDTO,
      ListNotificationsRequestDTO
    >({
      transformErrorResponse,
      query(data: ListNotificationsRequestDTO) {
        return {
          url: `notifications/list`,
          method: "GET",
          params: {
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            sortBy: data.sortBy,
            ascending: data.ascending,
          },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;
        dispatch(
          addNotifications({
            isFirstTime: args.isFirstTime ?? false,
            notifications: res.data.notifications.elements,
            hasMore:
              (args.pageNumber ?? 0) + 1 !== res.data.notifications.totalPages,
          })
        );
      },
    }),
    getUnreadNotificationsCount: builder.query<
      CountUnreadNotificationsResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `notifications/unread/count`,
          method: "GET",
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;
        dispatch(setUnreadNotificationCount(res.data.unreadNotificationsCount));
      },
    }),
    markNotificationAsRead: builder.mutation<
      NotificationView,
      {
        notificationId: string;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `notifications/${data.notificationId}/mark-as-read`,
          method: "PUT",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useLazyGetUnreadNotificationsCountQuery,
} = notificationAPI;
