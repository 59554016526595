import { ISpaceClusterShallowHierarchyView } from "../interfaces/space-hierarchy";

export const devicePath = (pathObject: ISpaceClusterShallowHierarchyView) => {
  let path = ``;
  pathObject &&
    pathObject.ancestorSpaces
      .slice()
      .reverse()
      .forEach((hierarchy, hierarchyIndex) => {
        path +=
          pathObject.ancestorSpaces.length - 1 === hierarchyIndex
            ? `${hierarchy.name}`
            : `${hierarchy.name} > `;
      });
  return path;
};
