import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { IExtendedShallowSemiAutomatedScheduleView } from "../../oversight-core/interfaces/shallow-semi-automated-schedule-view";
import AppSwitch from "../../oversight-core/ui-elements/app-switch/app-switch";

interface IProps {
  schedules: IExtendedShallowSemiAutomatedScheduleView;
  updateScheduleState: (scheduleId: string, scheduleStatus: boolean) => void;
}

const ScheduleListCard = (props: IProps) => {
  return (
    <>
      <Row className={`align-items-center justify-content-between my-2`}>
        <Col>
          <Row>
            <Col className="font-weight-500 font-size-14 text-light">
              {props.schedules.title}
            </Col>
          </Row>
          <Row>
            <Col className="font-weight-400 font-size-11 text-light">
              {props.schedules.expireDate
                ? `Expire Date : ${moment(props.schedules.expireDate).format(
                    "YYYY-MM-DD"
                  )}`
                : ``}
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <AppSwitch
            isOn={props.schedules.isSelected}
            size="sm"
            onSwitch={(value: boolean) => {
              props.updateScheduleState(props.schedules.id, value);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ScheduleListCard;
