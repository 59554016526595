import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useSwitchPowerConsumerMutation,
  useSwitchSpaceMutation,
  useUpdateAcTemperatureMutation,
} from "../../../../../redux/api/controller/controllerAPI";
import {
  useLazyCheckDevicesWithAbnormalPowerConsumptionQuery,
  useLazyGetSpaceQuery,
} from "../../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectFilter,
  setFilter,
  setSelectedFilterSpace,
  setSelectedSpace,
} from "../../../../../redux/features/filter/filter-slice";
import SpaceDeviceFilters from "../../../../../shared/components/space-device-filters/space-device-filters";
import SpacePathViewBreadcrumb from "../../../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import CheckDevicesWithAbnormalPowerConsumptionResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/check-devices-with-abnormal-power-consumption-response-dto";
import SubSpaceClusterViewResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/sub-space-cluster-view-response-dto";
import SwitchPowerConsumerPowerStateByIdResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/switch-power-consumer-power-state-by-id-response-dto";
import { EDeviceStatus } from "../../../../../shared/oversight-core/enums/device-status";
import { OvstErrorCode } from "../../../../../shared/oversight-core/enums/ovst-error-codes";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceShallowHierarchyView } from "../../../../../shared/oversight-core/interfaces/space-shallow-hierarchy-view";
import AppBannerWithIcon from "../../../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { showSwitchErrorMessage } from "../../../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showWarningMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import AbnormalEnergyUsageModal from "../models/abnormal-energy-usage-modal/abnormal-energy-usage-modal";
import { SpaceBreadCrumbPath } from "../space-breadcrumb/space-breadcrumb";
import SpaceContainer from "../space-container/space-container";

export const initialBreadcrumbPath: SpaceBreadCrumbPath = {
  clusterId: "",
  id: "",
  name: "All Billing Spaces",
};

const SpaceCluster = () => {
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);
  const [space, setSpace] = useState<ISpaceView[]>([]);
  const [spaceId, setSpaceId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [isPowerConsumerUpdated, setIsPowerConsumerUpdated] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showAbnormalEnergyUsageModal, setShowAbnormalEnergyUsageModal] =
    useState(false);
  const [isAbnormalPowerConsumers, setIsAbnormalPowerConsumers] =
    useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
    setUpdateCurrentStateDevicesWithAbnormalPowerConsumption,
  ] = useState(false);

  const [
    switchPowerConsumerState,
    { isLoading: isLoadingSwitchPowerConsumerState },
  ] = useSwitchPowerConsumerMutation();
  const [switchSpaceState, { isLoading: isLoadingSwitchSpaceState }] =
    useSwitchSpaceMutation();
  const [updateAcTemperature, { isLoading: isLoadingUpdateAcTemperature }] =
    useUpdateAcTemperatureMutation();
  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceQuery();
  const [triggerCheckDevicesWithAbnormalPowerConsumption] =
    useLazyCheckDevicesWithAbnormalPowerConsumptionQuery();

  useEffect(() => {
    if (filtersStore.selectedSpace.clusterId && filtersStore.selectedSpace.id) {
      setClusterId(filtersStore.selectedSpace.clusterId);
      setSpaceId(filtersStore.selectedSpace.id);
    }
  }, [filtersStore.selectedSpace]);

  useEffect(() => {
    if (clusterId && spaceId) {
      triggerGetSpace({ clusterId, spaceId })
        .unwrap()
        .then((res) => {
          onFetchSpaceSuccess(res);
        })
        .catch((error) => {
          if (
            error.status === 404 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0011
          ) {
            dispatch(
              setSelectedSpace({
                clusterId: billingSpaceFilter.spaceCluster.id,
                id: billingSpaceFilter.spaceCluster.rootSpace.id,
                name: billingSpaceFilter.spaceCluster.rootSpace.name,
              })
            );
          }
        });
    }
  }, [triggerGetSpace, clusterId, spaceId, isPowerConsumerUpdated]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clusterId && spaceId) {
        triggerGetSpace({ clusterId, spaceId })
          .unwrap()
          .then((res) => {
            onFetchSpaceSuccess(res);
          })
          .catch((error) => {
            console.log(error, "error");
            if (
              error.status === 404 &&
              error.ovstErrorCode === OvstErrorCode.OVST_0011
            ) {
              dispatch(
                setSelectedSpace({
                  clusterId: billingSpaceFilter.spaceCluster.id,
                  id: billingSpaceFilter.spaceCluster.rootSpace.id,
                  name: billingSpaceFilter.spaceCluster.rootSpace.name,
                })
              );
            }
          });
        setIsFirstTime(false);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerGetSpace, clusterId, spaceId, isPowerConsumerUpdated]);

  const updateCurrentState = () => {
    triggerGetSpace({ clusterId, spaceId });
  };

  useEffect(() => {
    if (
      filtersStore.selectedSpace.id !==
      getSplitPath()[getSplitPath().length - 1]
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  }, [filtersStore]);

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    badgeValue: number,
    isClear: boolean
  ) => {
    const currentClusterId = filtersStore.selectedSpace.clusterId;
    const currentSpaceId = filtersStore.selectedSpace.id;
    const currentSearchText = filtersStore.searchText;

    dispatch(
      setSelectedFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        badgeValue: badgeValue,
        isClear: isClear,
      })
    );
    if (
      !selectedSpace.clusterId ||
      selectedSpace.clusterId !== currentClusterId ||
      selectedSpace.id !== currentSpaceId ||
      selectedDeviceType ||
      selectedGroupBy !== "Space" ||
      currentSearchText !== searchText
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  };

  const updateDeviceStatus = (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus
  ) => {
    switchPowerConsumerState({
      spaceClusterId,
      spaceId,
      powerConsumerId,
      switchingPowerState,
    })
      .unwrap()
      .then((res: SwitchPowerConsumerPowerStateByIdResponseDTO) => {
        updateCurrentState();
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const updateSpaceStatus = (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => {
    switchSpaceState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      switchingPowerState: status,
      forceTurnOn: true,
    })
      .unwrap()
      .then((res) => {
        updateCurrentState();
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);

        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage} ${res.failedTypes}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
        }
      });
  };

  const sendSelectedSpace = (
    space: ISpaceView,
    breadcrumb?: ISpaceShallowHierarchyView[]
  ) => {
    dispatch(
      setSelectedSpace({
        clusterId: space.clusterId,
        id: space.id,
        name: space.name,
      })
    );

    let path = "";

    breadcrumb?.forEach((bi) => {
      path += `${path}/${bi.id}`;
    });

    navigate(AppRoute.SPACE_CLUSTERS + path);
  };

  useEffect(() => {
    if (!isFetchingSpace) setIsPowerConsumerUpdated(false);
  }, [isFetchingSpace]);

  const onAcTemperatureIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };
  const onAcTemperatureDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  const onFetchSpaceSuccess = (response: SubSpaceClusterViewResponseDTO) => {
    setSpace([
      {
        ...response.subSpaceCluster.rootSpace,
        clusterId: response.subSpaceCluster.id,
        accountNumber:
          response.subSpaceCluster.actualRootSpace.id ===
          response.subSpaceCluster.rootSpace.id
            ? response.subSpaceCluster.serviceProviderAccount.accountNumber
            : "",
        accountNumberLabel:
          response.subSpaceCluster.actualRootSpace.id ===
          response.subSpaceCluster.rootSpace.id
            ? response.subSpaceCluster.serviceProviderAccount.label
            : "",
      },
    ]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      triggerCheckDevicesWithAbnormalPowerConsumption({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
      })
        .unwrap()
        .then((res: CheckDevicesWithAbnormalPowerConsumptionResponseDTO) => {
          setIsAbnormalPowerConsumers(res.abnormalPowerConsumersDetected);
          if (!isAbnormalPowerConsumers) {
            setShowAbnormalEnergyUsageModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 60000);

    return () => clearTimeout(timer);
  }, [
    triggerCheckDevicesWithAbnormalPowerConsumption,
    billingSpaceFilter.spaceCluster,
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
  ]);

  return (
    <div className="position-relative">
      <div className="mb-3">
        <SpaceDeviceFilters
          updateFilters={updateFilters}
          updateCurrentState={updateCurrentState}
        />
      </div>
      {isAbnormalPowerConsumers && showInstruction && (
        <div className="mb-3">
          <AppBannerWithIcon
            content="Abnormal Energy Usage of Turned Off Devices Detected..."
            bannerVariant="brown"
            icon="live_help"
            iconVariant="#CD9368"
            button={
              <Row className="align-items-center g-4">
                <Col>
                  <AppButton
                    text={"View"}
                    variant={"transparent"}
                    onClick={() => setShowAbnormalEnergyUsageModal(true)}
                    size="medium"
                    className="px-0"
                  />
                </Col>
                <Col>
                  <div
                    className="instruction-close bg-primary text-white cursor-pointer"
                    onClick={() => setShowInstruction(false)}
                  >
                    x
                  </div>
                </Col>
              </Row>
            }
          />
        </div>
      )}
      {space && (
        <div className="hierarchy-overflow bg-white rounded-3 p-2 p-md-4">
          <SpacePathViewBreadcrumb
            selectedSpace={{
              clusterId: filtersStore.selectedSpace.clusterId,
              spaceId: filtersStore.selectedSpace.id,
            }}
            sendSelectedSpace={sendSelectedSpace}
          />
          {space.map((sp) => {
            return (
              <SpaceContainer
                key={sp.id}
                mainSpace={sp}
                expandable={true}
                isShowIcon={true}
                size={16}
                updateCurrentState={updateCurrentState}
                collapsed={false}
                onSelectSpace={selectSpaceHandler}
                isPowerConsumerUpdated={isPowerConsumerUpdated}
                setIsPowerConsumerUpdated={setIsPowerConsumerUpdated}
                updateSpaceStatus={updateSpaceStatus}
                updateDeviceStatus={updateDeviceStatus}
                isUpdateDeviceStateLoading={isLoadingSwitchPowerConsumerState}
                onAcTemperatureDecrease={onAcTemperatureDecrease}
                onAcTemperatureIncrease={onAcTemperatureIncrease}
                id={`space-dropdown-id-${sp.clusterId}-${sp.id}`}
              />
            );
          })}
        </div>
      )}
      <AbnormalEnergyUsageModal
        show={showAbnormalEnergyUsageModal && isAbnormalPowerConsumers}
        onClose={() => setShowAbnormalEnergyUsageModal(false)}
        setUpdateCurrentState={
          setUpdateCurrentStateDevicesWithAbnormalPowerConsumption
        }
      />
      <SpinnerModal
        show={
          (isFetchingSpace && isFirstTime) ||
          isLoadingSwitchSpaceState ||
          isLoadingSwitchPowerConsumerState ||
          isLoadingUpdateAcTemperature
        }
      />
    </div>
  );
};

export default SpaceCluster;
