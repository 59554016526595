import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import PowerEnergySource from "../../oversight-core/ui-elements/power-energy-source/power-energy-source";
interface IProps {
  previousEnergySources: string[];
  currentEnergySources: string[];
}

const DualPowerSourceInfo = (props: IProps) => {
  const { previousEnergySources, currentEnergySources } = props;

  return (
    <Row className="row-cols-auto align-items-center gx-2">
      <Col>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <div className="text-light font-size-12 font-weight-500">
              Last power source(s)
            </div>
          }
        >
          <div className={` user-select-none`}>
            <PowerEnergySource energySourceType={previousEnergySources} />
          </div>
        </Tooltip>
      </Col>
      <Col>
        <MaterialIcon icon="keyboard_double_arrow_right" color="#29CC39" />
      </Col>
      <Col>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <div className="text-light font-size-12 font-weight-500">
              Current power source(s)
            </div>
          }
        >
          <div className={` user-select-none`}>
            <PowerEnergySource energySourceType={currentEnergySources} />
          </div>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default DualPowerSourceInfo;
