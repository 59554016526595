import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DeviceUnlinkModal from "../../../screens/dashboard/space-clusters/components/models/device-unlink-modal/device-unlink-modal";
import { ISpaceClusterSelectedPowerConsumerViews } from "../../oversight-core/interfaces/space-cluster-selected-power-consumer-views";
import MaterialIcon, {
  MtIcon,
} from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import styles from "./link-device-card.module.scss";

interface IProps {
  device: ISpaceClusterSelectedPowerConsumerViews;
  onChange?: (Device: ISpaceClusterSelectedPowerConsumerViews) => void;
  selectedDevice?: ISpaceClusterSelectedPowerConsumerViews | undefined;
  icon?: MtIcon;
  onUnlink?: () => void;
  controllerName?: string;
  setExcludedSemiAutoScheduleList: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  isPathRevers?: boolean;
  isRegisteredDRP?: boolean;
  setIsRemoveDeviceFromDRP?: React.Dispatch<React.SetStateAction<boolean>>;
  smartPlugId?: string;
}

const LinkDeviceCard = (props: IProps) => {
  const {
    device,
    selectedDevice,
    icon,
    onChange,
    onUnlink,
    controllerName,
    isPathRevers = true,
    setExcludedSemiAutoScheduleList,
    isRegisteredDRP,
    smartPlugId,
  } = props;
  const [path, setPath] = useState("");
  const [showDeviceUnlinkModal, setShowDeviceUnlinkModal] = useState(false);
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);
  const [isRemoveDeviceFromDRP, setIsRemoveDeviceFromDRP] = useState(false);

  useEffect(() => {
    let paths = "";
    const ancestorSpaces = isPathRevers
      ? device.path.ancestorSpaces.slice().reverse()
      : device.path.ancestorSpaces.slice();

    ancestorSpaces.forEach((ancestor, index) => {
      paths +=
        index !== ancestorSpaces.length - 1
          ? `${ancestor.name} > `
          : `${ancestor.name}`;
    });

    setPath(paths);
  }, [device.path, isPathRevers]);

  return (
    <>
      <Row
        className={`${styles["device-card"]} ${
          selectedDevice &&
          selectedDevice.powerConsumer.id === device.powerConsumer.id &&
          styles.selected
        } cursor-pointer p-2 align-items-center justify-content-between`}
        onClick={() => {
          if (onChange) {
            onChange(device);
          }
        }}
      >
        <Col
          className={`${device.powerConsumer.deviceType} p-3 rounded`}
          xs={"auto"}
        >
          <MaterialIcon icon={findIcon(device.powerConsumer.deviceType)} />
        </Col>
        <Col>
          <Row>
            <Col
              className={`${styles["device-name"]} ${
                selectedDevice &&
                selectedDevice.powerConsumer.id === device.powerConsumer.id
                  ? styles.selectedFontColor
                  : ""
              } pe-0`}
              xs={"auto"}
            >
              {device.powerConsumer.name}
            </Col>
            <Col
              className={`${styles["device-energy"]} ${
                selectedDevice &&
                selectedDevice.powerConsumer.id === device.powerConsumer.id
                  ? styles.selectedFontColor
                  : ""
              } ps-2`}
            >
              {device.powerConsumer.powerUsageInWatt}W
            </Col>
          </Row>
          <Row>
            <Col
              className={`${styles["device-path"]} ${
                selectedDevice &&
                selectedDevice.powerConsumer.id === device.powerConsumer.id
                  ? styles.selectedFontColor
                  : ""
              }`}
            >
              {path}
            </Col>
          </Row>
        </Col>
        <Col xs={"auto"} className="icon ">
          <MaterialIcon
            icon={icon}
            onClick={() => {
              setShowDeviceUnlinkModal(true);
            }}
          />
        </Col>
      </Row>
      <DeviceUnlinkModal
        show={showDeviceUnlinkModal}
        onCancel={() => setShowDeviceUnlinkModal(false)}
        onClose={() => setShowDeviceUnlinkModal(false)}
        onConfirm={() => {
          onUnlink && onUnlink();
          setExcludedSemiAutoScheduleList(scheduleIdList);
          props.setIsRemoveDeviceFromDRP &&
            props.setIsRemoveDeviceFromDRP(isRemoveDeviceFromDRP);
          setShowDeviceUnlinkModal(false);
        }}
        deviceName={device.powerConsumer.name}
        controllerName={controllerName || ""}
        spaceClusterId={device.spaceClusterId}
        linkedPowerConsumerId={device.powerConsumer.id}
        setScheduleIdList={setScheduleIdList}
        isRegisteredDRP={isRegisteredDRP || false}
        isRemoveDeviceFromDRP={isRemoveDeviceFromDRP}
        setIsRemoveDeviceFromDRP={setIsRemoveDeviceFromDRP}
        smartPlugId={smartPlugId || ""}
      />
    </>
  );
};

export default LinkDeviceCard;
