import { Col, Row } from "react-bootstrap";
import { EDeviceStatus } from "../../../../../../shared/oversight-core/enums/device-status";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  spaceState: EDeviceStatus;
  spaceName: string;
}

const DeviceBulkConfirmationModal = (props: IProps) => {
  const { show, spaceState, spaceName, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      show={show}
      title={`Turn ${
        spaceState === EDeviceStatus.ON ? `On` : `Off`
      } All Devices`}
      size="modal-md"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            This action will turn{" "}
            <span className="font-weight-500">{`${
              spaceState === EDeviceStatus.ON ? `On` : `Off`
            }`}</span>{" "}
            all devices within {spaceName}. <br />
            Please Confirm to proceed or Cancel to go back.
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default DeviceBulkConfirmationModal;
