export enum EAcProtocolType {
  LG2 = "LG2",
  KELON = "KELON",
  COOLIX = "COOLIX",
  SONY = "SONY",
  DAIKIN = "DAIKIN",
  HAIER_AC = "HAIER_AC",
  WHIRLPOOL_AC = "WHIRLPOOL_AC",
  GREE = "GREE",
  TCL112AC = "TCL112AC",
  TCL96AC = "TCL96AC",
  SAMSUNG = "SAMSUNG",
  PRONTO = "PRONTO",
  PIONEER = "PIONEER",
  OTHER = "OTHER",
}
