import { Canvas } from "@react-three/fiber";
import Scene from "./components/scene/scene";

export interface IProps {
  solar: number;
  wind: number;
  generator: number;
  gridUsage: number;
  battery: number;
  demand: number;
  girdDirection: "Usage" | "Export" | "Nothing";
}

const MiniGrid = (props: IProps) => {
  return (
    <div style={{ width: "100%", overflow: "hidden", aspectRatio: "2.3 / 1" }}>
      <Canvas shadows camera={{ position: [0, 0, 4.5], fov: 50 }}>
        <Scene {...props} />
      </Canvas>
    </div>
  );
};

export default MiniGrid;
