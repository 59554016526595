import RegisteringDeviceRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/add-device-dto";
import IListPowerConsumersRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/list-power-consumers-request-dto";
import RegisteringDevicesResponseDTO from "../../../shared/oversight-core/dtos/request-dtos/registering-devices-dto";
import UpdateDeviceDTO from "../../../shared/oversight-core/dtos/request-dtos/update-device-dto";
import {
  IListPowerConsumersGroupedResponseDTO,
  IListPowerConsumersResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/list-power-consumers-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const deviceAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    addDevice: builder.mutation<
      RegisteringDevicesResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        registeringDevices: RegisteringDeviceRequestDTO[];
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power-consumer/device/register`,
          method: "POST",
          body: {
            registeringDevices: data.registeringDevices,
          },
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    editDevice: builder.mutation<
      RegisteringDevicesResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        registeringDevice: UpdateDeviceDTO;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power-consumer/device/update`,
          method: "PUT",
          body: data.registeringDevice,
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    removeDevice: builder.mutation<
      void,
      {
        clusterId: string;
        spaceId: string;
        deviceId: string;
        keepInProgramme: boolean;
        excludedSemiAutomatedSchedules: string[];
      }
    >({
      transformErrorResponse,
      query({
        clusterId,
        spaceId,
        deviceId,
        keepInProgramme,
        excludedSemiAutomatedSchedules,
      }) {
        return {
          url: `space-cluster/${clusterId}/space/${spaceId}/power-consumer/device/${deviceId}/unregister`,
          method: "DELETE",
          body: { excludedSemiAutomatedSchedules },
          params: {
            keepInProgramme,
          },
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    listPowerConsumers: builder.query<
      IListPowerConsumersResponseDTO & IListPowerConsumersGroupedResponseDTO,
      IListPowerConsumersRequestDTO
    >({
      transformErrorResponse,
      query(data: IListPowerConsumersRequestDTO) {
        return {
          url: `power-consumer/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddDeviceMutation,
  useEditDeviceMutation,
  useRemoveDeviceMutation,
  useLazyListPowerConsumersQuery,
} = deviceAPI;
