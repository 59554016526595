import JoinDemandResponseProgrammeRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/join-demand-response-programme-request-dto";
import UpdateDemandResponseProgrammeRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-demand-response-programme-request-dto";
import CheckIfPowerConsumerRegisteredResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/check-if-power-consumer-registered-response-dto";
import CheckIfSmartPlugRegisteredResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/check-if-smart-plug-registered-response-dto";
import DemandResponseProgrammeJoinStatusCheckResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/demand-response-programme-join-status-check-response-dto";
import ViewConsumerDemandControlActionResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-consumer-demand-control-action-response-dto";
import ViewDemandControlRequestsBySmartPlugResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-demand-control-requests-by-smart-plug-response-dto";
import ViewDemandResponseProgrammeResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-demand-response-programme-response-dto";
import ViewWeeklyDemandControlActionsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-demand-control-actions-response-dto";
import ViewWeeklyUsageGuideResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-usage-guide-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const usageGuideAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewWeeklyUsageGuide: builder.query<
      ViewWeeklyUsageGuideResponseDTO,
      {
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ startDate }) {
        return {
          url: `power-usage-guide/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
    }),
    viewWeeklyDemandControlActions: builder.query<
      ViewWeeklyDemandControlActionsResponseDTO,
      {
        startDate: string;
      }
    >({
      transformErrorResponse,
      query({ startDate }) {
        return {
          url: `demand-control-action/weekly/view`,
          method: "GET",
          params: {
            startDate,
          },
        };
      },
    }),
    viewConsumerDemandControlAction: builder.query<
      ViewConsumerDemandControlActionResponseDTO,
      {
        demandControlRequestId: string;
      }
    >({
      transformErrorResponse,
      query({ demandControlRequestId }) {
        return {
          url: `demand-control-action/${demandControlRequestId}/view`,
          method: "GET",
        };
      },
    }),
    joinDemandResponseProgramme: builder.mutation<
      {
        demandResponseProgrammeId: string;
      },
      JoinDemandResponseProgrammeRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `demand-response-programme/join`,
          method: "POST",
          body: {
            devices: data.devices,
          },
        };
      },
    }),
    viewDemandResponseProgramme: builder.query<
      ViewDemandResponseProgrammeResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `demand-response-programme/view`,
          method: "GET",
        };
      },
    }),
    updateDemandResponseProgramme: builder.mutation<
      {
        demandResponseProgrammeId: string;
      },
      UpdateDemandResponseProgrammeRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `demand-response-programme/update`,
          method: "POST",
          body: {
            devices: data.devices,
          },
        };
      },
    }),
    demandResponseProgrammeJoinStatusCheck: builder.query<
      DemandResponseProgrammeJoinStatusCheckResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `demand-response-programme/join-check`,
          method: "GET",
        };
      },
    }),
    checkIfSmartPlugRegisteredToDRP: builder.query<
      CheckIfSmartPlugRegisteredResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        smartPlugId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, smartPlugId }) {
        return {
          url: `demand-response-programme/space-cluster/${spaceClusterId}/space/${spaceId}/smart-plug/${smartPlugId}/drp-registered`,
          method: "GET",
        };
      },
    }),
    checkIfPowerConsumerRegisteredToDRP: builder.query<
      CheckIfPowerConsumerRegisteredResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        powerConsumerId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, powerConsumerId }) {
        return {
          url: `demand-response-programme/space-cluster/${spaceClusterId}/space/${spaceId}/power-consumer/${powerConsumerId}/drp-registered`,
          method: "GET",
        };
      },
    }),
    viewDemandControlRequestsBySmartPlug: builder.query<
      ViewDemandControlRequestsBySmartPlugResponseDTO,
      {
        smartPlugId: string;
      }
    >({
      transformErrorResponse,
      query({ smartPlugId }) {
        return {
          url: `demand-response-programme/${smartPlugId}/view`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyViewWeeklyUsageGuideQuery,
  useLazyViewWeeklyDemandControlActionsQuery,
  useLazyViewConsumerDemandControlActionQuery,
  useJoinDemandResponseProgrammeMutation,
  useLazyViewDemandResponseProgrammeQuery,
  useUpdateDemandResponseProgrammeMutation,
  useLazyDemandResponseProgrammeJoinStatusCheckQuery,
  useLazyCheckIfSmartPlugRegisteredToDRPQuery,
  useLazyCheckIfPowerConsumerRegisteredToDRPQuery,
  useLazyViewDemandControlRequestsBySmartPlugQuery,
} = usageGuideAPI;
