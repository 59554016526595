import FeedbackRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/feedback-request.-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const authAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    feedback: builder.mutation<void, FeedbackRequestDTO>({
      query(data) {
        return {
          url: "consumer/feedback",
          method: "POST",
          body: data,
        };
      },
      transformErrorResponse,
    }),
  }),

  overrideExisting: false,
});

export const { useFeedbackMutation } = authAPI;
