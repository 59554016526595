import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISourceManagementFilter } from "../../../shared/oversight-core/interfaces/source-management-filter";
import { RootState } from "../../store";

const initialState: ISourceManagementFilter = {
  viewTimeUnit: "",
};

export const sourceManagementFilterSlice = createSlice({
  name: "sourceManagementFilter",
  initialState: { ...initialState },
  reducers: {
    setSourceManagementFilter: (
      state,
      action: PayloadAction<ISourceManagementFilter>
    ) => {
      state.viewTimeUnit = action.payload.viewTimeUnit;
    },
  },
});

export const { setSourceManagementFilter } =
  sourceManagementFilterSlice.actions;

export const selectSourceManagementFilter = (state: RootState) =>
  state.sourceManagementFilter;

export default sourceManagementFilterSlice.reducer;
