import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { EPowerMeasureType } from "../../oversight-core/enums/power-measure-type";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import Helper from "../../oversight-core/utils/helpers";
import { powerConvertor } from "../../oversight-core/utils/power-convertor";
import { formatTime } from "../../oversight-core/utils/time-utils";
import DualPowerSourceInfo from "../dual-power-source-info/dual-power-source-info";
import InfoAndValueItems from "../info-and-value-items/info-and-value-items";
import styles from "./energy-source-card.module.scss";

interface IProps {
  title: string;
  subTitle: string;
  time: string;
  expectedUsage: number;
  expectedGeneration: number;
  exportUsage: number;
  nextSourceSelectionDateTime: string;
  previousEnergySources: string[];
  currentEnergySources: string[];
  demandValueComparison: string;
  generationValueComparison: string;
  isFetching: boolean;
}

const EnergySourceCard = (props: IProps) => {
  const {
    title,
    subTitle,
    time,
    expectedUsage,
    expectedGeneration,
    exportUsage,
    nextSourceSelectionDateTime,
    previousEnergySources,
    currentEnergySources,
    demandValueComparison,
    generationValueComparison,
    isFetching,
  } = props;

  return (
    <div className="container-white h-100 py-4">
      <Row>
        <Col className="text-light font-size-12 font-weight-500">{title}</Col>
      </Row>
      <div className={`${styles.infoContainer} p-3 mt-2`}>
        <Row>
          <Col className="text-light font-size-12 font-weight-500">
            {subTitle}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InfoAndValueItems
              date={moment(time).format("yyyy/MM/DD")}
              time={formatTime(new Date(time))}
              expectedUsage={Number(
                Helper.roundTo2(
                  powerConvertor(expectedUsage, EPowerMeasureType.KILO_WATT)
                )
              )}
              expectedGeneration={Number(
                Helper.roundTo2(
                  powerConvertor(
                    expectedGeneration,
                    EPowerMeasureType.KILO_WATT
                  )
                )
              )}
              exportUsage={Number(
                Helper.roundTo2(
                  powerConvertor(exportUsage, EPowerMeasureType.KILO_WATT)
                )
              )}
              demandValueComparison={demandValueComparison}
              generationValueComparison={generationValueComparison}
              unit="kWh"
              isFetching={isFetching}
              isShowExpoUsage={false}
            />
          </Col>
        </Row>
      </div>
      <Row className="mt-4">
        <Col>
          <DualPowerSourceInfo
            previousEnergySources={previousEnergySources}
            currentEnergySources={currentEnergySources}
          />
        </Col>
      </Row>
      <Row
        className={`${styles.sourceSelectionContainer} align-items-center mt-4 mx-0 py-1`}
      >
        <Col className="col-auto px-2">
          <MaterialIcon
            icon="keyboard_double_arrow_right"
            color="#1C1B1F"
            size={20}
          />
        </Col>
        <Col className="text-light font-size-12 font-weight-400 ps-0">
          Next Source Selection :{" "}
          <span className="text-dark font-size-12 font-weight-400">
            {Number(nextSourceSelectionDateTime) > 0
              ? `${nextSourceSelectionDateTime} min`
              : "In less than one minute"}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default EnergySourceCard;
