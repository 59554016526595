import { EEnergySourceType } from "../../../../../shared/oversight-core/enums/energy-source-type";
import { IUsageBySourceView } from "../../../../../shared/oversight-core/interfaces/usage-by-source-view";
import Helper from "../../../../../shared/oversight-core/utils/helpers";
import Node from "../node/node";
import Path from "../path/path";
import styles from "./live-usage.module.scss";

interface IProps {
  usageView: IUsageBySourceView;
}

const LiveUsage = (props: IProps) => {
  const { usageView } = props;

  return (
    <div className={styles.liveUsageContainer}>
      <Node
        x={50}
        y={20}
        value={`${Helper.roundTo2(usageView.demand / 1000)} kW`}
        title="Pearl Cluster Tower"
        icon="apartment"
      />
      <Node
        x={330}
        y={20}
        value={`${usageView.batteryPercentage}% (50kWh)`}
        title="Battery"
        icon="battery_charging_80"
        powerGeneratorType={EEnergySourceType.BATTERY}
      />
      <Node
        x={0}
        y={155}
        value={`${Helper.roundTo2(
          usageView.isImportingFromGrid
            ? usageView.gridUsage / 1000
            : usageView.isExportingToGrid
            ? usageView.gridExport / 1000
            : 0 / 1000
        )} kW`}
        title="Grid"
        icon="electric_meter"
        powerGeneratorType={EEnergySourceType.GRID}
      />
      <Node
        x={137}
        y={155}
        value={`${Helper.roundTo2(usageView.generator / 1000)} kW`}
        title="Generator"
        icon="charger"
        powerGeneratorType={EEnergySourceType.GENERATOR}
      />
      <Node
        x={282}
        y={155}
        value={`${Helper.roundTo2(usageView.solarPV / 1000)} kW`}
        title="Solar"
        icon="solar_power"
        powerGeneratorType={EEnergySourceType.SOLAR_PV}
      />
      <Node
        x={420}
        y={155}
        value={`${Helper.roundTo2(usageView.windTurbine / 1000)} kW`}
        title="Turbine"
        icon="energy"
        powerGeneratorType={EEnergySourceType.WIND_TURBINE}
      />

      <Path
        x={210}
        y={45}
        d={`m 0 0 l 130 0`}
        direction="up"
        color="#E9E36A"
        dashColor="#A19B12"
        isActive={usageView.isUsingBattery}
        value={Number(Helper.roundTo2(usageView.batteryDischarge / 1000))}
        reactX={22}
        reactY={8}
      />

      <Path
        x={200}
        y={65}
        d={`m 0 0 l 0 40 l ${
          usageView.isChargingBatteryWindTurbine &&
          !usageView.isUsingWindTurbine
            ? `175`
            : `280`
        } 0 l 0 ${
          usageView.isChargingBatteryWindTurbine &&
          !usageView.isUsingWindTurbine
            ? `0`
            : `70`
        }`}
        direction="up"
        color="#2EB6EE"
        dashColor="#20A0D4"
        isActive={usageView.isUsingWindTurbine}
        value={132.1}
        reactX={90}
        reactY={8}
        isShowLineValues={false}
      />
      <Path
        x={430}
        y={65}
        d={`m 0 0 l 0 40 ${
          usageView.isChargingBatteryWindTurbine &&
          !usageView.isUsingWindTurbine &&
          `l 105 0 l 0 70`
        }`}
        direction="up"
        color="#2EB6EE"
        dashColor="#20A0D4"
        isActive={usageView.isChargingBatteryWindTurbine}
        value={132.1}
        reactX={23}
        reactY={7}
        isShowLineValues={false}
      />

      <Path
        x={180}
        y={65}
        d={`m 0 0 l 0 65 l 169 0 ${
          usageView.isUsingSolarPV &&
          !usageView.isChargingBatterySolarPV &&
          `l 0 50`
        }`}
        direction="up"
        color="#2DEC9F"
        dashColor="#0AD181"
        isActive={usageView.isUsingSolarPV}
        value={Number(
          Helper.roundTo2(usageView.demandFromSolarGeneration / 1000)
        )}
        reactX={20}
        reactY={8}
      />
      <Path
        x={349}
        y={65}
        d={`m 0 0 l 0 ${
          usageView.isUsingSolarPV && !usageView.isChargingBatterySolarPV
            ? `62`
            : `90`
        }`}
        direction="up"
        color="#2DEC9F"
        dashColor="#0AD181"
        isActive={usageView.isChargingBatterySolarPV}
        value={Number(Helper.roundTo2(usageView.batteryCharge / 1000))}
        reactX={23}
        reactY={
          usageView.isChargingBatterySolarPV ||
          (!usageView.isUsingSolarPV && !usageView.isChargingBatterySolarPV)
            ? 32
            : 18
        }
      />

      <Path
        x={100}
        y={65}
        d={`m 0 0 l 0 40 l -55 0 l 0 50`}
        direction={
          usageView.isImportingFromGrid
            ? "up"
            : usageView.isExportingToGrid
            ? "down"
            : "up"
        }
        color="#C9C9CC"
        dashColor="#A7A7A7"
        isActive={usageView.isImportingFromGrid || usageView.isExportingToGrid}
        value={0}
        reactX={0}
        reactY={0}
        isShowLineValues={false}
      />
      <Path
        x={125}
        y={65}
        d={`m 0 0 l 0 40 l 40 0 l 0 50`}
        direction="up"
        color="#C9C9CC"
        dashColor="#A7A7A7"
        isActive={usageView.isUsingGenerator}
        value={0}
        reactX={0}
        reactY={0}
        isShowLineValues={false}
      />
    </div>
  );
};

export default LiveUsage;
