import ControllerGraphRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/controller-graph-request-dto";
import ControllerStatisticsRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/controller-statistics-request-dto";
import EnergyConsumptionStatisticsByDateRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/energy-consumption-statistics-by-date-request-dto";
import PowerUsageStatisticsByYearMonthRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/power-usage-statistics-by-year-month-request-dto";
import { IViewDevicesWithHighestPowerConsumptionRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/view-devices-with-highest-power-consumption-request-dto";
import ControllerGraphResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/controller-graph-response-dto";
import ControllerStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/controller-statistics-response-dto";
import EnergyConsumptionStatisticsByDateResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/energy-consumption-statistics-by-date-response-dto";
import PowerUsageStatisticsByYearMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/power-usage-statistics-by-year-month-response-dto";
import { IViewDevicesWithHighestPowerConsumptionResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-devices-with-highest-power-consumption-response-dto";
import ViewEnergyUsageBySourceForYearMonthResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-usage-by-source-for-year-month-response-dto";
import ViewLatestUsageBySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-latest-usage-by-source-response-dto";
import ViewPowerUsageStatsBySpaceClusterResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-power-usage-stats-by-space-cluster-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const usageAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getControllerStatistics: builder.query<
      ControllerStatisticsResponseDTO,
      ControllerStatisticsRequestDTO
    >({
      transformErrorResponse,
      query(data: ControllerStatisticsRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/statistics/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            date: data.date,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    getPowerUsageStatisticsByYearMonth: builder.query<
      PowerUsageStatisticsByYearMonthResponseDTO,
      PowerUsageStatisticsByYearMonthRequestDTO
    >({
      transformErrorResponse,
      query(data: PowerUsageStatisticsByYearMonthRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/stats/by-year-month`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            year: data.year,
            month: data.month,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    getGraph: builder.query<
      ControllerGraphResponseDTO,
      ControllerGraphRequestDTO
    >({
      transformErrorResponse,
      query(data: ControllerGraphRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/power-usage/list`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            from: data.from,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    getEnergyConsumptionStatisticsByDate: builder.query<
      EnergyConsumptionStatisticsByDateResponseDTO,
      EnergyConsumptionStatisticsByDateRequestDTO
    >({
      transformErrorResponse,
      query(data: EnergyConsumptionStatisticsByDateRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/smart-device/energy-consumption/statistics/by-date`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            smartPlugId: data.smartPlugId,
            date: data.date,
            requiredDepth: data.requiredDepth,
            deviceType: data.deviceType,
          },
        };
      },
    }),
    viewDevicesWithHighestPowerConsumption: builder.query<
      IViewDevicesWithHighestPowerConsumptionResponseDTO,
      IViewDevicesWithHighestPowerConsumptionRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewDevicesWithHighestPowerConsumptionRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/device/highest-energy-consumption/view`,
          method: "GET",
          params: {
            spaceId: data.spaceId,
            requiredDepth: data.requiredDepth,
            limit: data.limit,
            from: data.from,
          },
        };
      },
    }),
    viewPredictedPowerUsageForCurrentDay: builder.query<
      ViewPowerUsageStatsBySpaceClusterResponseDTO,
      { spaceClusterId: string; zoneOffset: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId, zoneOffset }) {
        return {
          url: `space-cluster/${spaceClusterId}/power-usage/prediction/today`,
          method: "GET",
          params: {
            zoneOffset: zoneOffset,
          },
        };
      },
    }),
    viewLatestUsageBySource: builder.query<
      ViewLatestUsageBySourceResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/usage-by-source/view`,
          method: "GET",
        };
      },
    }),
    viewEnergyUsageBySourceForYearMonth: builder.query<
      ViewEnergyUsageBySourceForYearMonthResponseDTO,
      { year: number; month: number }
    >({
      transformErrorResponse,
      query({ year, month }) {
        return {
          url: `energy-usage/by-source/year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
  }),
});

export const {
  useLazyGetControllerStatisticsQuery,
  useLazyGetPowerUsageStatisticsByYearMonthQuery,
  useLazyGetGraphQuery,
  useLazyGetEnergyConsumptionStatisticsByDateQuery,
  useLazyViewDevicesWithHighestPowerConsumptionQuery,
  useLazyViewPredictedPowerUsageForCurrentDayQuery,
  useLazyViewLatestUsageBySourceQuery,
  useLazyViewEnergyUsageBySourceForYearMonthQuery,
} = usageAPI;
