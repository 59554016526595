import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import { EConsumptionValueComparison } from "../../oversight-core/enums/consumption-comparison";
import InfoAndValue from "../../oversight-core/ui-elements/info-and-value/info-and-value";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
interface IProps {
  date: string;
  time: string;
  expectedUsage: number;
  expectedGeneration: number;
  exportUsage?: number;
  demandValueComparison?: string;
  generationValueComparison?: string;
  unit?: "kWh" | "kW";
  isFetching: boolean;
  isShowExpoUsage?: boolean;
}

const InfoAndValueItems = (props: IProps) => {
  const {
    time,
    expectedUsage,
    expectedGeneration,
    exportUsage,
    unit,
    demandValueComparison,
    generationValueComparison,
    isFetching,
    date,
    isShowExpoUsage = true,
  } = props;

  const switchIcon = (comparison: EConsumptionValueComparison) => {
    switch (comparison) {
      case EConsumptionValueComparison.HIGH:
        return <MaterialIcon icon="trending_up" color="#59C270" />;
      case EConsumptionValueComparison.LOW:
        return <MaterialIcon icon="trending_down" color="#E75B74" />;
    }
  };

  return (
    <Row className="row-cols-auto justify-content-between">
      <Col>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <div className="text-light font-size-12 font-weight-500">
              Last power source selection date and time
            </div>
          }
        >
          <div className={` user-select-none`}>
            <InfoAndValue
              title={date}
              value={time}
              withContainer={false}
              isFetching={isFetching}
            />
          </div>
        </Tooltip>
      </Col>
      <Col>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <div className="text-light font-size-12 font-weight-500">
              Considered usage prediction in the last power source selection.
            </div>
          }
        >
          <div className={` user-select-none`}>
            <InfoAndValue
              title="Predicted Usage"
              value={
                <Row className="align-items-center">
                  <Col className="col-auto pe-0">
                    {expectedUsage} {unit}
                  </Col>
                  {demandValueComparison && (
                    <Col className="ps-0">
                      {switchIcon(
                        demandValueComparison as EConsumptionValueComparison
                      )}
                    </Col>
                  )}
                </Row>
              }
              withContainer={false}
              isFetching={isFetching}
            />
          </div>
        </Tooltip>
      </Col>
      <Col>
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={
            <div className="text-light font-size-12 font-weight-500">
              Considered generation prediction in the last power source
              selection.
            </div>
          }
        >
          <div className={` user-select-none`}>
            <InfoAndValue
              title="Predicted Generation"
              value={
                <Row className="align-items-center">
                  <Col className="col-auto pe-0">
                    {expectedGeneration} {unit}
                  </Col>
                  {generationValueComparison && (
                    <Col className="ps-0">
                      {switchIcon(
                        generationValueComparison as EConsumptionValueComparison
                      )}
                    </Col>
                  )}
                </Row>
              }
              withContainer={false}
              isFetching={isFetching}
            />
          </div>
        </Tooltip>
      </Col>
      {isShowExpoUsage && (
        <Col>
          <InfoAndValue
            title="Export"
            value={`${exportUsage} ${unit}`}
            withContainer={false}
            isFetching={isFetching}
          />
        </Col>
      )}
    </Row>
  );
};

export default InfoAndValueItems;
