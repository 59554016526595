import { Col, Row } from "react-bootstrap";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import SpinnerModal from "../spinner/spinner";

interface IProps {
  title: string;
  value: number;
  icon: MtIcon;
  isFetching: boolean;
}

const EnergySourceInfo = (props: IProps) => {
  const { title, value, icon, isFetching } = props;

  return (
    <Row className="align-items-center">
      <Col className="col-auto pe-0">
        <MaterialIcon icon={icon} size={26} color="#383941" />
      </Col>
      <Col className="ps-2">
        <Row>
          <Col className="text-light font-size-12 font-weight-400">{title}</Col>
        </Row>
        <Row>
          <Col className="col-auto text-dark font-size-16 font-weight-600">
            {isFetching ? (
              <SpinnerModal show={isFetching} withOverlay={false} size="md" />
            ) : (
              `${value} kWh`
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EnergySourceInfo;
