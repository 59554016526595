import ViewEnergySourceHistoryResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-source-history-response-dto";
import ViewPowerUsageBySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-power-usage-by-source-response-dto";
import { EViewTimeUnit } from "../../../shared/oversight-core/enums/view-time-unit";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const managementAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewEnergySourceHistory: builder.query<
      ViewEnergySourceHistoryResponseDTO,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        ascending: boolean;
      }
    >({
      transformErrorResponse,
      query({ pageNumber, pageSize, sortBy, ascending }) {
        return {
          url: `energy-generation/energy-source-history/view`,
          method: "GET",
          params: { pageNumber, pageSize, sortBy, ascending },
        };
      },
    }),
    viewPowerUsageBySource: builder.query<
      ViewPowerUsageBySourceResponseDTO,
      {
        from: string;
        timeUnit?: EViewTimeUnit;
      }
    >({
      transformErrorResponse,
      query({ from, timeUnit }) {
        return {
          url: `energy-generation/power-usage-by-source/view`,
          method: "GET",
          params: { from, timeUnit },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyViewEnergySourceHistoryQuery,
  useLazyViewPowerUsageBySourceQuery,
} = managementAPI;
