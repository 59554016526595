import { Col, Row } from "react-bootstrap";
import { EEnergySourceType } from "../../../../../shared/oversight-core/enums/energy-source-type";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import EnergySourceInfoCard from "../../../../../shared/oversight-core/ui-elements/energy-source-info-card/energy-source-info-card";
import styles from "./energy-source-info-container.module.scss";

const EnergySourceInfoContainer = () => {
  return (
    <div className={`${styles.energySourceInfoContainer} p-3`}>
      <Row className="row-cols-auto align-items-center justify-content-between g-5">
        <Col className="pe-0">
          <Row>
            <Col className="text-light font-size-12 font-weight-400">
              Export
            </Col>
          </Row>
          <Row className="align-items-center mx-0">
            <Col
              className={`${styles.exportValueContainer} text-dark font-size-14 font-weight-500`}
            >
              1
            </Col>
            <Col className="text-right font-size-16 font-weight-600 ps-1">
              kW
            </Col>
          </Row>
        </Col>
        <Col>
          <EnergySourceInfoCard
            icon="battery_charging_80"
            value={70}
            text="Battery"
          />
        </Col>
        <Col>
          <EnergySourceInfoCard icon="charger" value={0} text="Generator" />
        </Col>
        <Col>
          <EnergySourceInfoCard icon="electric_meter" value={0} text="Grid" />
        </Col>
        <Col>
          <EnergySourceInfoCard
            icon="energy"
            value={0.5}
            text="Turbine"
            sourceType={EEnergySourceType.WIND_TURBINE}
          />
        </Col>
        <Col>
          <EnergySourceInfoCard
            icon="solar_power"
            value={4.5}
            text="solar"
            sourceType={EEnergySourceType.SOLAR_PV}
          />
        </Col>
        <Col className="ps-2">
          <AppButton
            text="Confirm"
            onClick={() => {
              console.log("Confirm");
            }}
            size="medium"
            iconName="check"
          />
        </Col>
      </Row>
    </div>
  );
};

export default EnergySourceInfoContainer;
