import moment from "moment";
import { Col, Row } from "react-bootstrap";
import DualPowerSourceInfo from "../../../../../shared/components/dual-power-source-info/dual-power-source-info";
import InfoAndValueItems from "../../../../../shared/components/info-and-value-items/info-and-value-items";
import { EManagementMode } from "../../../../../shared/oversight-core/enums/management-mode";
import { EPowerMeasureType } from "../../../../../shared/oversight-core/enums/power-measure-type";
import { IEnergySourceView } from "../../../../../shared/oversight-core/interfaces/energy-source-view";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import Helper from "../../../../../shared/oversight-core/utils/helpers";
import { powerConvertor } from "../../../../../shared/oversight-core/utils/power-convertor";
import { formatTime } from "../../../../../shared/oversight-core/utils/time-utils";

interface IProps {
  isCurrentSource: boolean;
  selectedManagementMode: EManagementMode;
  energySourceData: IEnergySourceView;
  isFetching: boolean;
}

const SourceManageCard = (props: IProps) => {
  const {
    isCurrentSource,
    selectedManagementMode,
    energySourceData,
    isFetching,
  } = props;

  return (
    <div className="container-white px-4">
      {isCurrentSource && (
        <Row>
          <Col className="text-dark font-size-14 font-weight-500">
            Current Source
          </Col>
        </Row>
      )}
      <Row className="align-items-center mt-3">
        <Col>
          <Row className="align-items-center">
            <Col className="col-10 col-xxl-4 pe-xxl-0">
              <InfoAndValueItems
                date={moment(energySourceData.dateTime).format("yyyy/MM/DD")}
                time={formatTime(new Date(energySourceData.dateTime))}
                expectedUsage={Number(
                  Helper.roundTo2(
                    powerConvertor(
                      energySourceData.consumedEnergy,
                      EPowerMeasureType.KILO_WATT
                    )
                  )
                )}
                expectedGeneration={Number(
                  Helper.roundTo2(
                    powerConvertor(
                      energySourceData.generatedEnergy,
                      EPowerMeasureType.KILO_WATT
                    )
                  )
                )}
                exportUsage={powerConvertor(
                  energySourceData?.exportedEnergy,
                  EPowerMeasureType.KILO_WATT
                )}
                demandValueComparison={
                  energySourceData?.demandValueComparison || ""
                }
                generationValueComparison={
                  energySourceData?.generationValueComparison || ""
                }
                unit="kWh"
                isFetching={isFetching}
                isShowExpoUsage={false}
              />
            </Col>
            <Col className="col-11 col-xxl-auto ps-xxl-4 mt-2 mt-xxl-0">
              <DualPowerSourceInfo
                currentEnergySources={energySourceData.currentEnergySources}
                previousEnergySources={energySourceData.previousEnergySources}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <MaterialIcon
            icon={
              selectedManagementMode === EManagementMode.SEMI_AUTO
                ? "autoplay"
                : "touch_app"
            }
            color={isCurrentSource ? "#35AB5D" : "#1C1B1F"}
            size={24}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SourceManageCard;
