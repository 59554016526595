/* eslint-disable react/no-unknown-property */
import { useFrame } from "@react-three/fiber";
import { useRef, useState } from "react";
import * as THREE from "three";

const EmissiveMaterial = () => {
  const materialRef = useRef<THREE.MeshStandardMaterial>(null);
  const [intensity, setIntensity] = useState(1);
  const [direction, setDirection] = useState(1);

  useFrame(() => {
    if (materialRef.current) {
      const newIntensity = intensity + 0.1 * direction;
      setIntensity(newIntensity);
      if (newIntensity > 4 || newIntensity < 0) {
        setDirection(-direction);
      }
      materialRef.current.emissiveIntensity = newIntensity;
    }
  });
  return (
    <meshStandardMaterial
      ref={materialRef}
      color="orange"
      emissive="red"
      emissiveIntensity={5}
    />
  );
};

export default EmissiveMaterial;
