import { Col, Row } from "react-bootstrap";
import styles from "./expected-power-value.module.scss";

interface IProps {
  title: string;
  value: number;
  isEnergy?: boolean;
}

const ExpectedPowerValue = (props: IProps) => {
  const { title, value, isEnergy = true } = props;

  return (
    <div className={styles.powerValueBannerContainer}>
      <Row className="align-items-center py-2 px-3">
        <Col className="col-auto text-light font-size-12 font-weight-500 pe-0">
          {title}
          <span className="font-size-10 font-weight-400">{` (Next Hour):`}</span>
        </Col>
        <Col className="col-auto text-dark font-size-16 font-weight-500">
          {value} {isEnergy ? `kWh` : `%`}
        </Col>
      </Row>
    </div>
  );
};

export default ExpectedPowerValue;
