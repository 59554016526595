import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewSemiAutomationSchedulesByPowerConsumerQuery } from "../../../../../../redux/api/schedule/scheduleAPI";
import { useLazyViewDemandControlRequestsBySmartPlugQuery } from "../../../../../../redux/api/usage-guide/usageGuideAPI";
import ScheduleListCard from "../../../../../../shared/components/schedule-list-card/schedule-list-card";
import ViewDemandControlRequestsBySmartPlugResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-demand-control-requests-by-smart-plug-response-dto";
import ViewSemiAutomationSchedulesByPowerConsumerResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedules-by-power-consumer-response-dto";
import { IDemandControlRequestView } from "../../../../../../shared/oversight-core/interfaces/demand-control-request-view";
import { IExtendedShallowSemiAutomatedScheduleView } from "../../../../../../shared/oversight-core/interfaces/shallow-semi-automated-schedule-view";
import AppSwitch from "../../../../../../shared/oversight-core/ui-elements/app-switch/app-switch";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import styles from "./remove-device-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceName: string;
  spaceClusterId: string;
  linkedPowerConsumerId: string;
  setScheduleIdList: React.Dispatch<React.SetStateAction<string[]>>;
  isRegisteredDRP: boolean;
  isRemoveDeviceFromDRP: boolean;
  setIsRemoveDeviceFromDRP: React.Dispatch<React.SetStateAction<boolean>>;
  smartPlugId: string;
}

const RemoveDeviceModal = (props: IProps) => {
  const {
    spaceClusterId,
    linkedPowerConsumerId,
    setScheduleIdList,
    isRegisteredDRP,
    isRemoveDeviceFromDRP,
    setIsRemoveDeviceFromDRP,
    show,
    smartPlugId,
    ...rest
  } = props;

  const [semiAutomationSchedule, setSemiAutomationSchedule] = useState<
    IExtendedShallowSemiAutomatedScheduleView[]
  >([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isSemiAutoSchedules, setIsSemiAutoSchedules] = useState(false);
  const [isRunningSchedule, setIsRunningSchedule] = useState(false);
  const [isRunningDrpEvents, setIsRunningDrpEvents] = useState(false);
  const [drpEvents, setDrpEvents] = useState<IDemandControlRequestView[]>([]);

  const [
    triggerSemiAutomationSchedulesByPowerConsumer,
    { isFetching: isFetchingSemiAutomationSchedulesByPowerConsumer },
  ] = useLazyViewSemiAutomationSchedulesByPowerConsumerQuery();
  const [triggerDemandControlRequestsBySmartPlug] =
    useLazyViewDemandControlRequestsBySmartPlugQuery();

  useEffect(() => {
    if (show) {
      triggerSemiAutomationSchedulesByPowerConsumer({
        spaceClusterId: spaceClusterId,
        linkedPowerConsumerId: linkedPowerConsumerId,
      })
        .unwrap()
        .then((res: ViewSemiAutomationSchedulesByPowerConsumerResponseDTO) => {
          setSemiAutomationSchedule(
            res.shallowSemiAutomatedScheduleViews.map((schedule) => {
              return { ...schedule, isSelected: false };
            })
          );
          setIsSemiAutoSchedules(
            res.shallowSemiAutomatedScheduleViews.length > 0
          );
          setIsRunningSchedule(
            res.shallowSemiAutomatedScheduleViews.some((sh) => sh.isRunning)
          );
        })
        .catch(() => {
          setSemiAutomationSchedule([]);
        });
      setSelectedPage(1);
      setIsRemoveDeviceFromDRP(false);
    }
  }, [
    show,
    spaceClusterId,
    linkedPowerConsumerId,
    triggerSemiAutomationSchedulesByPowerConsumer,
  ]);

  useEffect(() => {
    if (show) {
      triggerDemandControlRequestsBySmartPlug({ smartPlugId: smartPlugId })
        .unwrap()
        .then((res: ViewDemandControlRequestsBySmartPlugResponseDTO) => {
          setIsRunningDrpEvents(
            res.demandControlRequests.some((dc) => dc.isRunning)
          );
          setDrpEvents(res.demandControlRequests);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [show, smartPlugId, triggerDemandControlRequestsBySmartPlug]);

  const updateScheduleState = (scheduleId: string, scheduleStatus: boolean) => {
    setSemiAutomationSchedule((ps) =>
      ps.map((schedule) => {
        if (schedule.id === scheduleId) {
          return { ...schedule, isSelected: scheduleStatus };
        } else {
          return { ...schedule };
        }
      })
    );

    if (scheduleStatus) {
      setScheduleIdList((ps) => [...ps, scheduleId]);
    } else {
      setScheduleIdList((ps) => ps.filter((id) => id != scheduleId));
    }
  };

  useEffect(() => {
    let isAllSelected = true;

    for (const schedule of semiAutomationSchedule) {
      if (!schedule.isSelected) {
        isAllSelected = false;
        break;
      }
    }

    setSelectAll(isAllSelected);
  }, [semiAutomationSchedule]);

  const handleSelectAll = (value: boolean) => {
    setSemiAutomationSchedule((ps) =>
      ps.map((schedule) => {
        return { ...schedule, isSelected: value };
      })
    );

    if (value) {
      const tempScheduleIdList = semiAutomationSchedule.map(
        (schedule) => schedule.id
      );
      setScheduleIdList(tempScheduleIdList);
      return;
    }

    setScheduleIdList([]);
  };

  return (
    <ModalContainer
      {...rest}
      title={"Remove Device?"}
      show={show}
      size="modal-md"
      confirmButtonText={
        selectedPage === 1 && isRegisteredDRP && isSemiAutoSchedules
          ? `Next`
          : `Remove`
      }
      confirmButtonVariant={
        selectedPage === 1 && isRegisteredDRP && isSemiAutoSchedules
          ? `blue`
          : `red`
      }
      cancelButtonText={
        isRegisteredDRP && selectedPage === 2 ? `Back` : `Cancel`
      }
      isSecondButtonVisible={isRegisteredDRP && isSemiAutoSchedules}
      hideConfirm={isRegisteredDRP && isSemiAutoSchedules}
      onConfirm={() =>
        selectedPage === 1 && isRegisteredDRP && isSemiAutoSchedules
          ? setSelectedPage(2)
          : props.onConfirm && props.onConfirm()
      }
      onCancel={() =>
        isRegisteredDRP && selectedPage === 2
          ? setSelectedPage(1)
          : props.onCancel && props.onCancel()
      }
    >
      <>
        {!isSemiAutoSchedules && !isRegisteredDRP && (
          <div>
            <Row>
              <Col className="text-light font-size-14 m-0">
                {props.deviceName} will be removed permanently, Are you sure you
                want to delete this device? This action cannot be undone and all
                associated data will be permanently deleted.
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="font-weight-400 font-size-14 text-light">
                If this device is in manual schedules, the device will be
                removed from them
              </Col>
            </Row>
          </div>
        )}
        {selectedPage === 1 && isSemiAutoSchedules ? (
          <>
            <Row>
              <Col className="text-light font-size-14 m-0">
                {props.deviceName} will be removed permanently, Are you sure you
                want to delete this device? This action cannot be undone and all
                associated data will be permanently deleted.
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="font-weight-400 font-size-14 text-light">
                If this device is in manual schedules, the device will be
                removed from them
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="font-weight-500 font-size-14 text-light">
                Cancel Semi-Automated Schedules?
              </Col>
            </Row>
            <Row>
              <Col className="font-weight-400 font-size-14 text-light">
                <div>
                  All the schedules related to this controller will be cancelled
                  due to the device being removed.
                </div>
                {isRunningSchedule && (
                  <div className="mt-3 font-weight-600">
                    There are active semi-auto schedules on this device. The
                    semi-auto schedules that are currently in effect are{" "}
                    {semiAutomationSchedule
                      .filter((sh) => sh.isRunning)
                      .map((sch) => `"${sch.title}"`)
                      .join(", ")}
                  </div>
                )}
                <div className="mt-3">
                  If you want to keep any schedules running for this controller,
                  please select them below. if you link the controller to
                  another device later, it will be available for the semi-auto
                  schedules.
                </div>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-end my-4">
              <Col className="col-auto font-weight-400 font-size-14 text-light">
                Select All
              </Col>
              <Col className="col-auto">
                <AppSwitch
                  isOn={selectAll}
                  size="sm"
                  onSwitch={(value: boolean) => {
                    handleSelectAll(value);
                  }}
                />
              </Col>
            </Row>
            {isFetchingSemiAutomationSchedulesByPowerConsumer ? (
              <Row>
                <Col>
                  <SpinnerModal
                    show={isFetchingSemiAutomationSchedulesByPowerConsumer}
                    withOverlay={false}
                  />
                </Col>
              </Row>
            ) : (
              <div className={`${styles.overflow}`}>
                {semiAutomationSchedule.map((schedule, index) => {
                  return (
                    <Row key={index}>
                      <Col>
                        <ScheduleListCard
                          schedules={schedule}
                          updateScheduleState={updateScheduleState}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            )}
          </>
        ) : (
          isRegisteredDRP && (
            <>
              {!isSemiAutoSchedules && (
                <div>
                  <Row>
                    <Col className="text-light font-size-14 m-0">
                      {props.deviceName} will be removed permanently, Are you
                      sure you want to delete this device? This action cannot be
                      undone and all associated data will be permanently
                      deleted.
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="font-weight-400 font-size-14 text-light">
                      If this device is in manual schedules, the device will be
                      removed from them
                    </Col>
                  </Row>
                </div>
              )}
              <Row className="mt-3">
                <Col className="font-weight-500 font-size-14 text-light">
                  Cancel Demand Response Program Events?
                </Col>
              </Row>
              <Row>
                <Col className="font-weight-400 font-size-14 text-light">
                  <div>
                    This controller will be unavailable for current DRP events
                    due to the device being unlinked and disabled.
                  </div>
                  <div className="mt-3">
                    Even if you link the controller to another device later, it
                    will not be available for the demand response program.
                  </div>
                  {isRunningDrpEvents && (
                    <div className="mt-3 font-weight-600">
                      There are active DRP events on this device. The DRP events
                      that are currently in effect are{" "}
                      {drpEvents
                        .filter((drpEv) => drpEv.isRunning)
                        .map((drp) => `"${drp.title}"`)
                        .join(", ")}
                    </div>
                  )}
                  <div className="mt-3">
                    If you need the controller available for the demand response
                    program when it is linked again, please confirm it by
                    selecting the toggle below.
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-end my-4">
                <Col className="col-auto">
                  <AppSwitch
                    isOn={isRemoveDeviceFromDRP}
                    size="sm"
                    onSwitch={() => {
                      setIsRemoveDeviceFromDRP((ps) => !ps);
                    }}
                  />
                </Col>
              </Row>
            </>
          )
        )}
      </>
    </ModalContainer>
  );
};

export default RemoveDeviceModal;
