import ViewMonthlyTotalEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/ViewMonthlyTotalEnergyGenerationResponseDTO";
import ViewBatteryBackupInformationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-battery-backup-information-response-dto";
import ViewBatteryInformationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-battery-information-response-dto";
import ViewDailyEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-daily-energy-generation-response-dto";
import ViewLiveEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-live-energy-generation-response-dto";
import { EUnitType } from "../../../shared/oversight-core/enums/unit-type";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const generationAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewLiveEnergyGenerationData: builder.query<
      ViewLiveEnergyGenerationResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/live/view`,
          method: "GET",
        };
      },
    }),
    viewMonthlyTotalEnergyGeneration: builder.query<
      ViewMonthlyTotalEnergyGenerationResponseDTO,
      { year: number; month: number }
    >({
      transformErrorResponse,
      query({ year, month }) {
        return {
          url: `energy-generation/monthly-total-energy-generation/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
    viewBatteryInformation: builder.query<
      ViewBatteryInformationResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/battery-information/view`,
          method: "GET",
        };
      },
    }),
    viewEnergyGeneration: builder.query<
      ViewDailyEnergyGenerationResponseDTO,
      {
        year?: number;
        month?: number;
        day?: number;
        unitType: EUnitType;
      }
    >({
      transformErrorResponse,
      query({ year, month, day, unitType }) {
        return {
          url: `energy-generation/view`,
          method: "GET",
          params: { year, month, day, unitType },
        };
      },
    }),
    viewBatteryBackup: builder.query<
      ViewBatteryBackupInformationResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `energy-generation/battery-backup/view`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyViewLiveEnergyGenerationDataQuery,
  useLazyViewMonthlyTotalEnergyGenerationQuery,
  useLazyViewBatteryInformationQuery,
  useLazyViewEnergyGenerationQuery,
  useLazyViewBatteryBackupQuery,
} = generationAPI;
