import { EEnergySourceType } from "../../../../../shared/oversight-core/enums/energy-source-type";
import EnergySourceInfoCardWithIndicator from "../../../../../shared/oversight-core/ui-elements/energy-source-info-card-with-indicator/energy-source-info-card-with-indicator";
import { MtIcon } from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./node.module.scss";

interface IProps {
  x: number;
  y: number;
  icon: MtIcon;
  value: string;
  title: string;
  active?: boolean;
  powerGeneratorType?: EEnergySourceType;
}
const Node = (props: IProps) => {
  return (
    <div
      className={styles.node}
      style={{ top: props.y + "px", left: props.x + "px" }}
    >
      <EnergySourceInfoCardWithIndicator
        title={props.title}
        value={props.value}
        icon={props.icon}
        powerGeneratorType={props.powerGeneratorType}
        iconSize={24}
        valueFontSize={14}
        titleFontSize={12}
        isFetching={false}
        isDifferentStyle={true}
        isDifferentPowerIndicatorSize={true}
      />
    </div>
  );
};

export default Node;
