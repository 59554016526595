import { Col, Row } from "react-bootstrap";
import { EEnergySourceType } from "../../enums/energy-source-type";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import SpinnerModal from "../spinner/spinner";
import styles from "./energy-source-info-card-with-indicator.module.scss";

interface IProps {
  title: string;
  value: string | JSX.Element;
  icon: MtIcon;
  powerGeneratorType?: EEnergySourceType;
  isFetching: boolean;
  iconSize?: number;
  valueFontSize?: number;
  titleFontSize?: number;
  isDifferentStyle?: boolean;
  isDifferentPowerIndicatorSize?: boolean;
}

const EnergySourceInfoCardWithIndicator = (props: IProps) => {
  const {
    title,
    value,
    icon,
    powerGeneratorType,
    isFetching,
    iconSize = 45,
    valueFontSize = 20,
    titleFontSize = 16,
    isDifferentStyle = false,
    isDifferentPowerIndicatorSize = false,
  } = props;

  const getPowerIndicator = () => {
    switch (powerGeneratorType) {
      case EEnergySourceType.SOLAR_PV:
        return "greenIndicator";
      case EEnergySourceType.WIND_TURBINE:
        return "blueIndicator";
      case EEnergySourceType.GENERATOR:
        return "grayIndicator";
      case EEnergySourceType.BATTERY:
        return "yellowIndicator";
      default:
        return "grayIndicator";
    }
  };

  return (
    <>
      <div
        className={`${styles.infoContainer} ${
          isDifferentStyle ? `p-2` : `p-3`
        }`}
      >
        <Row className="align-items-center">
          <Col>
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <MaterialIcon icon={icon} size={iconSize} color="#383941" />
              </Col>
              <Col className={isDifferentStyle ? `ps-2` : `ps-3`}>
                <Row>
                  <Col
                    className={`col-auto text-dark font-size-${valueFontSize} font-weight-600 ${
                      isDifferentStyle && `lh-sm`
                    }`}
                  >
                    {isFetching ? (
                      <SpinnerModal
                        show={isFetching}
                        withOverlay={false}
                        size="md"
                      />
                    ) : (
                      value
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    className={`text-light font-size-${titleFontSize} font-weight-500 ${
                      isDifferentStyle && `lh-sm`
                    }`}
                  >
                    {title}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className={`${isDifferentStyle && `ps-0`} col-auto`}>
            {powerGeneratorType && (
              <div
                className={`${
                  isDifferentPowerIndicatorSize
                    ? styles.powerIndicatorSecondType
                    : styles.powerIndicator
                } ${styles[`${getPowerIndicator()}`]}`}
              ></div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EnergySourceInfoCardWithIndicator;
