import { Col, Row } from "react-bootstrap";
import MenuItem from "../../oversight-core/shared-components/sidebar/components/menu-item/menu-item";

interface IProps {
  collapsed?: boolean;
  setShowFeedback: () => void;
}

const FeedbackButton = (props: IProps) => {
  return (
    <Row className="d-none">
      <Col>
        <MenuItem
          active={false}
          collapsed={props.collapsed}
          icon="chat"
          onClick={() => props.setShowFeedback()}
          text="Feedback"
        />
      </Col>
    </Row>
  );
};

export default FeedbackButton;
