import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewSemiAutomationScheduleLogByIdQuery } from "../../../../../../redux/api/schedule/scheduleAPI";
import LogItemCard from "../../../../../../shared/components/log-item-card/log-item-card";
import { ViewSemiAutomationScheduleLogByIdResponseDTO } from "../../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-log-by-id-response-dto";
import { EScheduledAction } from "../../../../../../shared/oversight-core/enums/scheduled-action";
import IExtendedSmartDeviceView from "../../../../../../shared/oversight-core/interfaces/extended-smart-device-view";
import { ISemiAutomatedScheduleLogsView } from "../../../../../../shared/oversight-core/interfaces/semi-automated-schedule-logs-view";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import Pagination from "../../../../../../shared/oversight-core/ui-elements/pagination/pagination";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { devicePath } from "../../../../../../shared/oversight-core/utils/device-path";
import styles from "./view-log-item-modal.module.scss";

interface IProps extends ModelContainerProps {
  logId: string;
  show: boolean;
  deviceCount: number;
}

const ITEM_PER_PAGE = 3;

const ViewLogItemModal = (props: IProps) => {
  const { show, logId, deviceCount, ...rest } = props;
  const [scheduleLogSmartDevices, setScheduleLogSmartDevices] = useState<
    IExtendedSmartDeviceView[]
  >([]);
  const [scheduleLogItem, setScheduleLogItem] =
    useState<ISemiAutomatedScheduleLogsView>();
  const [filters, setFilters] = useState({
    pageNumber: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [
    triggerViewSemiAutomationScheduleLogById,
    { isFetching: isFetchingViewSemiAutomationScheduleLogById },
  ] = useLazyViewSemiAutomationScheduleLogByIdQuery();

  useEffect(() => {
    if (show) {
      triggerViewSemiAutomationScheduleLogById({
        logId: logId,
        pageNumber: filters.pageNumber,
        ascending: true,
        pageSize: ITEM_PER_PAGE,
      })
        .unwrap()
        .then((res: ViewSemiAutomationScheduleLogByIdResponseDTO) => {
          const extendedSmartDevices = res.semiAutomatedScheduleLog.smartDevices
            ? res.semiAutomatedScheduleLog.smartDevices.map((sd) => {
                const path = devicePath(
                  res?.semiAutomatedScheduleLog.devicePath[sd.id]
                );
                return { ...sd, path };
              })
            : [];
          setScheduleLogSmartDevices(extendedSmartDevices);
          setScheduleLogItem(res.semiAutomatedScheduleLog);
          setTotalElements(res.semiAutomatedScheduleLog.totalElements);
        })
        .catch(() => {
          setTotalElements(0);
        });
    }
  }, [show, filters, logId]);

  return (
    <ModalContainer
      {...rest}
      title={scheduleLogItem?.scheduleTitle}
      show={show}
      size="modal-lg"
      isButtonsVisible={false}
      className="mt-0"
    >
      {isFetchingViewSemiAutomationScheduleLogById ? (
        <SpinnerModal show={true} />
      ) : (
        <>
          <Row className="align-items-center">
            <Col className="col-auto font-size-12 font-weight-400 text-light">
              {scheduleLogItem
                ? moment(scheduleLogItem.timeStamp).format("yyyy/MM/DD")
                : null}
            </Col>
            <Col className="font-size-12 font-weight-400 text-light">
              {scheduleLogItem
                ? moment(scheduleLogItem.timeStamp).format("HH:mm")
                : null}
            </Col>
          </Row>
          <Row className="justify-content-between align-items-end mt-3">
            <Col className="font-size-12 font-weight-400 text-dark">
              Affected Devices:
            </Col>
            <Col className="col-auto">
              <Row className="justify-content-center">
                <Col
                  className={`${
                    styles.logCountContainer
                  } font-size-12 font-weight-500 py-2 ${
                    deviceCount &&
                    scheduleLogItem?.succeededCount &&
                    scheduleLogItem?.succeededCount < deviceCount
                      ? "text-red"
                      : "text-light"
                  } col-auto`}
                >
                  {scheduleLogItem?.succeededCount} / {deviceCount}
                </Col>
              </Row>
              <Row>
                <Col className="font-size-12 font-weight-400 text-light">
                  Turned &nbsp;
                  {scheduleLogItem?.scheduledAction === EScheduledAction.ON
                    ? "On"
                    : "Off"}
                </Col>
              </Row>
            </Col>
          </Row>
          {scheduleLogItem?.errors &&
            scheduleLogSmartDevices?.map((item, index) => (
              <Row key={index} className="mt-2 ms-0">
                <LogItemCard device={item} errors={scheduleLogItem?.errors} />
              </Row>
            ))}
          <Pagination
            itemsPerPage={ITEM_PER_PAGE}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                pageNumber: pn - 1,
              }));
            }}
          />
        </>
      )}
    </ModalContainer>
  );
};

export default ViewLogItemModal;
