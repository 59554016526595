/* eslint-disable react/no-unknown-property */
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Suspense } from "react";
import { IProps } from "../../mini-grid";
import Content from "../content/content";
import Loader from "../loader/loader";

const Scene = (props: IProps) => {
  return (
    <>
      <PerspectiveCamera
        makeDefault
        position={[-2.2, 1.4 + 0.7, 2.6 + 0.7]}
        aspect={100}
        rotation={[-0.5, -0.6, -0.3]}
      />

      <OrbitControls
        enablePan={false}
        minDistance={3}
        maxDistance={5}
        minAzimuthAngle={-Math.PI / 2}
        maxAzimuthAngle={Math.PI / 4}
        minPolarAngle={Math.PI / 4}
        maxPolarAngle={Math.PI / 2.5}
      />
      {/* <axesHelper /> */}
      <hemisphereLight intensity={1} groundColor="#6e91fa" />
      <directionalLight
        position={[5, 15, 10]}
        intensity={0.8}
        castShadow
        shadow-bias={-0.002}
        shadow-radius={100}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-near={0.5}
        shadow-camera-far={20}
        shadow-camera-left={-5}
        shadow-camera-right={5}
        shadow-camera-top={5}
        shadow-camera-bottom={-5}
      />
      <ambientLight intensity={0.7} />
      <Suspense fallback={<Loader />}>
        <Content {...props} />
      </Suspense>
    </>
  );
};

export default Scene;
