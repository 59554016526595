import { Col, Row } from "react-bootstrap";
import DeviceCard from "../../../../../../shared/components/device-card/device-card";
import AppBannerWithIcon from "../../../../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpacePathView from "../../../../../../shared/oversight-core/ui-elements/space-path-view/space-path-view";
import { IPowerConsumerViewWithSpaceIdAndClusterId } from "../../add-update-schedule/space-hierarchy/space-hierarchy";

interface IProps extends ModelContainerProps {
  show: boolean;
  devices: IPowerConsumerViewWithSpaceIdAndClusterId[];
  path: string;
}

const ScheduleConfirmationModal = (props: IProps) => {
  const { devices, show, path, ...rest } = props;
  return (
    <ModalContainer
      {...rest}
      title={
        <>
          <SpacePathView path={path} isPathIcon={false} />
        </>
      }
      show={show}
      size="modal-lg"
      confirmButtonText="Save"
      cancelButtonText="Cancel"
    >
      <>
        <div>
          <AppBannerWithIcon
            content="Some devices in this automation schedule are already part of another schedule, either the same or in a closely connected time frame. If you want to modify the devices you've already chosen, simply go back and make a different selection. Otherwise, proceed with your current choices."
            iconVariant="#CB343D"
            icon="warning"
            bannerVariant="lightRed"
          />
        </div>
        <div className="mt-4">
          {devices.map((device, index) => (
            <Row
              key={device.clusterId + device.spaceId + device.id + index}
              className="mx-1"
            >
              <Col className="mt-2">
                <DeviceCard device={{ ...device }} showToggler={false} />
              </Col>
            </Row>
          ))}
        </div>
      </>
    </ModalContainer>
  );
};

export default ScheduleConfirmationModal;
