import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectScheduleFilter } from "../../../redux/features/schedule-filter/schedule-filter-slice";
import { IPowerConsumerViewWithSpaceIdAndClusterId } from "../../../screens/dashboard/schedule/components/add-update-schedule/space-hierarchy/space-hierarchy";
import ScheduleConflictModal from "../../../screens/dashboard/schedule/components/modals/schedule-conflict-modal/schedule-conflict-modal";
import UsageGuideModal from "../../../screens/dashboard/schedule/components/modals/usage-guide-modal/usage-guide-modal";
import { IPowerConsumerTypeAvailabilityViews } from "../../oversight-core/dtos/response-dtos/view-semi-automation-schedule-device-availability-response-dto";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ELimitationType } from "../../oversight-core/enums/limitation-type";
import { EManagementMode } from "../../oversight-core/enums/management-mode";
import { ESmartControllerType } from "../../oversight-core/enums/smart-controller-type";
import { IDateTimeRangeView } from "../../oversight-core/interfaces/date-time-range-view";
import AppSwitch from "../../oversight-core/ui-elements/app-switch/app-switch";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import shortenedPath from "../../oversight-core/utils/shortenedPath";
import styles from "./device-card.module.scss";

interface IProps {
  device: IPowerConsumerViewWithSpaceIdAndClusterId;
  updateDeviceStatus?: (
    spaceClusterId: string,
    spaceId: string,
    deviceId: string,
    deviceStatus: boolean
  ) => void;
  limitationType?: ELimitationType;
  showToggler?: boolean;
  conflictedScheduleList?: {
    deviceId: string;
    dateTimeRange: IDateTimeRangeView[];
  }[];
  usageGuideTimeList?: IPowerConsumerTypeAvailabilityViews[];
}

const DeviceCard = (props: IProps) => {
  const scheduleFilterStore = useSelector(selectScheduleFilter);
  const {
    device,
    showToggler = true,
    limitationType,
    conflictedScheduleList = [],
    usageGuideTimeList = [],
  } = props;
  const [showUsageGuideModal, setShowUsageGuideModal] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [showScheduleConflictModal, setShowScheduleConflictModal] =
    useState(false);
  const [path, setPath] = useState("");

  const isSemiAutoMode =
    scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO;

  useEffect(() => {
    if (props.device.isSelected !== undefined) {
      setIsOn(props.device.isSelected);
    }
  }, [props.device.isSelected]);

  useEffect(() => {
    let tempPath = "";
    if (device.path) {
      device.path.ancestorSpaces
        .slice()
        .reverse()
        .forEach((as, index) => {
          tempPath += `${
            (device.path?.ancestorSpaces || []).length - 1 === index
              ? as.name
              : as.name + " > "
          }`;
        });
      setPath(tempPath);
    }
  }, [device.path]);

  return (
    <>
      <Row
        className={`${styles["device-card"]} ${
          styles["overflow-horizontal"]
        } align-items-center justify-content-between p-2 ${
          isSemiAutoMode && limitationType
            ? `${
                limitationType === ELimitationType.ONLY_GIVE_WARNING
                  ? styles.deviceCardGradient
                  : styles.deviceCardOpacity
              }`
            : ``
        }`}
      >
        <Col>
          <Row className="align-items-center">
            <Col className={`${device.deviceType} col-auto p-3 rounded`}>
              <MaterialIcon
                icon={findIcon(device.deviceType as EDeviceTypes)}
              />
            </Col>
            <Col>
              <Row className="align-items-center">
                <Col className={`${styles["device-name"]} col-auto pe-0`}>
                  {device.name}
                </Col>
                <Col className="font-size-12 font-weight-400 text-light">
                  {device.powerUsageInWatt}W
                </Col>
              </Row>
              {showToggler ? (
                <Row className="align-items-center">
                  {device.smartDevices?.length > 0 &&
                    device.smartDevices.map((sd) => {
                      return (
                        <Col
                          key={sd.id}
                          className={`${styles["device-outlet"]} col-auto pe-0`}
                        >
                          <Row>
                            <Col className="pe-0">
                              <MaterialIcon
                                icon={
                                  sd.smartDeviceType ===
                                  ESmartControllerType.SMART_PLUG
                                    ? `smart_outlet`
                                    : `missing_controller`
                                }
                                size={16}
                              />
                            </Col>
                            <Col className="col-auto ps-2">{sd.name}</Col>
                          </Row>
                        </Col>
                      );
                    })}
                </Row>
              ) : (
                <Row>
                  <Col className="font-size-12 text-light">
                    {path.length > 80 ? shortenedPath(path) : path}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
        {showToggler && (
          <Col className="col-auto">
            {device.deviceCategory === EDeviceTypes.AIR_CONDITIONER &&
              isSemiAutoMode &&
              limitationType !== ELimitationType.EXCLUDE_FROM_SCHEDULE && (
                <Row className="justify-content-end mb-1">
                  <Col className="col-auto">
                    <Tooltip
                      overlayClassName="pe-3"
                      placement="top"
                      trigger={["hover"]}
                      overlay={
                        <div className="text-light font-size-12 font-weight-400">
                          An A/C Controller or a Smart Plug can be used to
                          control an A/C device
                        </div>
                      }
                    >
                      <div className={`user-select-none`}>
                        <MaterialIcon
                          className="cursor-mouse"
                          icon="info"
                          color="#69768B"
                          size={16}
                        />
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
              )}
            <Row className="align-items-center">
              {props.device.isScheduled && showToggler && (
                <Col className="col-auto pe-0">
                  <div
                    onClick={() => {
                      if (showToggler) {
                        setShowScheduleConflictModal(true);
                      }
                    }}
                    className={showToggler ? styles.limitationWrapper : ``}
                  >
                    <MaterialIcon icon="warning" color="#CB343D" />
                  </div>
                </Col>
              )}
              {isSemiAutoMode && limitationType && showToggler && (
                <Col
                  className={
                    limitationType === ELimitationType.ONLY_GIVE_WARNING
                      ? "col-auto pe-0"
                      : ""
                  }
                >
                  <div
                    className={styles.limitationWrapper}
                    onClick={() => {
                      setShowUsageGuideModal(true);
                    }}
                  >
                    <MaterialIcon
                      icon={
                        limitationType === ELimitationType.ONLY_GIVE_WARNING
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        limitationType === ELimitationType.ONLY_GIVE_WARNING
                          ? "#E8C304"
                          : "#EC7575"
                      }
                    />
                  </div>
                </Col>
              )}
              {(isSemiAutoMode && limitationType
                ? limitationType === ELimitationType.ONLY_GIVE_WARNING
                : true) && (
                <Col>
                  <AppSwitch
                    isOn={isOn}
                    onSwitch={(value) => {
                      if (props.updateDeviceStatus) {
                        props.updateDeviceStatus(
                          props.device.clusterId,
                          props.device.spaceId,
                          props.device.id,
                          value
                        );
                      }
                      setIsOn(value);
                    }}
                    size="sm"
                  />
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
      <UsageGuideModal
        show={showUsageGuideModal}
        limitationType={limitationType || ELimitationType.ONLY_GIVE_WARNING}
        usageGuideTimeList={usageGuideTimeList}
        onClose={() => {
          setShowUsageGuideModal(false);
        }}
        deviceType={device.deviceCategory}
      />
      <ScheduleConflictModal
        show={showScheduleConflictModal}
        deviceName={device.name}
        conflictedTimeList={
          conflictedScheduleList?.find(
            (conflict) => conflict.deviceId === device.id
          )?.dateTimeRange || []
        }
        onClose={() => {
          setShowScheduleConflictModal(false);
        }}
      />
    </>
  );
};

export default DeviceCard;
