import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import { IAbnormalPowerConsumptionList } from "../../oversight-core/interfaces/abnormal-power-consumption-list";
import AppToggle from "../../oversight-core/ui-elements/app-toggle/app-toggle";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../oversight-core/utils/findIcon";
import shortenedPath from "../../oversight-core/utils/shortenedPath";
import styles from "./abnormal-power-consumption-list-card.module.scss";

interface IProps {
  powerConsumerView: IAbnormalPowerConsumptionList;
  onSwitch: () => void;
}

const AbnormalPowerConsumptionListCard = (props: IProps) => {
  const { powerConsumerView, onSwitch } = props;

  const [path, setPath] = useState("");
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    let paths = "";
    const ancestorSpaces = powerConsumerView.path.ancestorSpaces
      .slice()
      .reverse();

    ancestorSpaces.forEach((ancestor, index) => {
      paths +=
        index !== ancestorSpaces.length - 1
          ? `${ancestor.name} > `
          : `${ancestor.name}`;
    });

    setPath(paths);
  }, [powerConsumerView.path]);

  return (
    <Row
      className={`${styles.deviceCardContainer} align-items-center justify-content-between p-3`}
    >
      <Col className="col-12 col-lg-5">
        <Row className="align-items-center">
          <Col
            className={`${
              powerConsumerView.deviceType
                ? powerConsumerView.deviceType
                : styles.smartController
            } p-3 rounded col-auto`}
          >
            <MaterialIcon
              icon={
                powerConsumerView.deviceType
                  ? findIcon(powerConsumerView.deviceType)
                  : `smart_outlet`
              }
            />
          </Col>
          <Col>
            <Row>
              <Col className="font-size-14 font-weight-500 text-dark">
                {powerConsumerView.deviceType
                  ? powerConsumerView.name
                  : "General Smart Plug"}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="col-auto font-size-12 text-light font-weight-400">
                {path.length > 80 ? shortenedPath(path) : path}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="col-12 col-lg-6 mt-2 mt-lg-0">
        <Row className="align-items-center justify-content-between">
          <Col className="col-6 pe-0">
            {powerConsumerView.smartDevices.map((sd, index) => {
              return (
                <Row key={index}>
                  <Col className="text-light font-weight-500 font-size-14">
                    {sd.name}
                  </Col>
                </Row>
              );
            })}
          </Col>
          <Col className="col-auto col-lg-4">
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <SpinnerModal
                  show={true}
                  withOverlay={false}
                  size="sm"
                  color="red"
                  speedMultiplier={0.6}
                />
              </Col>
              <Col className="col-auto font-size-14 text-dark font-weight-500">
                {powerConsumerView.livePowerUsage}W
              </Col>
            </Row>
          </Col>
          <Col className="col-auto ps-0">
            <AppToggle
              isOn={isOn}
              onSwitch={() => {
                if (
                  powerConsumerView.deviceConnectionState ===
                  EConnectionStatus.CONNECTED
                ) {
                  setIsOn((ps) => !ps);
                  onSwitch();
                }
              }}
              size="sm"
              isDisable={
                powerConsumerView.deviceConnectionState ===
                EConnectionStatus.CONNECTED
                  ? false
                  : true
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AbnormalPowerConsumptionListCard;
