import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex } from "../../../../../../shared/oversight-core/utils/regex";

interface IProps extends ModelContainerProps {
  spaceName: string;
  onConfirm: () => void;
}

interface IFormInput {
  spaceName: string;
}

const defaultFormValues: IFormInput = {
  spaceName: "",
};

const RemoveSpaceModal = (props: IProps) => {
  const { ...rest } = props;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    setValue("spaceName", "");
  }, [props.show]);

  const onSubmit = (data: IFormInput) => {
    data.spaceName;
    if (props.spaceName === data.spaceName) {
      props.onConfirm();
    }
  };

  const spaceName = useRef("");
  spaceName.current = watch("spaceName", "");

  return (
    <ModalContainer
      {...rest}
      title="Remove Space"
      confirmButtonVariant="red"
      confirmButtonText="Remove"
      size="modal-md"
      onConfirm={handleSubmit(onSubmit)}
      disabled={spaceName.current === props.spaceName ? false : true}
    >
      <>
        <p className="text-light font-weight-400 font-size-14">
          <span className="font-weight-500">{props.spaceName}</span> will be
          removed permanently, Are you sure you want to delete this space?
        </p>
        <p className="text-light font-size-14 mt-3">
          All the schedules and DRP events related to the devices and All the
          devices and controllers will also be removed due to the space being
          deleted
        </p>
        <p className="text-light font-size-14 mt-3 mb-0">
          To confirm, type{" "}
          <span className="font-weight-500">&quot;{props.spaceName}&quot;</span>{" "}
          in the text box below
        </p>
        <AppInput
          placeholder=""
          name="spaceName"
          register={register("spaceName", {
            pattern: {
              value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
              message:
                "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
            },
          })}
          errors={errors}
        />
      </>
    </ModalContainer>
  );
};

export default RemoveSpaceModal;
