import Tooltip from "rc-tooltip";
import { Col, Row } from "react-bootstrap";
import { EErrors } from "../../oversight-core/enums/errors";
import IExtendedSmartDeviceView from "../../oversight-core/interfaces/extended-smart-device-view";
import ISchedulerLogErrorView from "../../oversight-core/interfaces/scheduler-log-error-view";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import styles from "./log-item-card.module.scss";

interface IProps {
  device: IExtendedSmartDeviceView;
  errors: ISchedulerLogErrorView[];
}

const LogItemCard = (props: IProps) => {
  const { device, errors } = props;

  const checkSucceedDevice = (key: string) => {
    return errors.some((d) => d.smartDeviceSerialKey === key);
  };

  const getErrorMessage = () => {
    const errorCodes: EErrors[] = errors.map((error) => error.errorCode);

    for (const errorCode of errorCodes) {
      switch (errorCode) {
        case EErrors.OVST_CONN_0001:
          return "Device is not online.";
        case EErrors.OVST_CONN_0002:
          return "Invalid identity.";
        case EErrors.OVST_CONN_0003:
          return "Failure in one of the services. Please try again later.";
        default:
          return "Default Error";
      }
    }
  };

  return (
    <Row
      className={`${styles["log-item-card"]} p-2 align-items-center justify-content-between`}
    >
      <Col>
        <Row className="align-items-center">
          <Col
            className={`col-auto ${device.linkedPowerConsumer.deviceType} p-2 rounded`}
          >
            <MaterialIcon
              icon={findIcon(device.linkedPowerConsumer?.deviceType)}
            />
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col className="text-dark font-size-14 font-weight-500">
                {device.linkedPowerConsumer.name}
              </Col>
            </Row>
            <Row>
              <Col className="text-light font-size-12 font-weight-400">
                {device.path}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="col-auto">
        {checkSucceedDevice(device.serialKey) && errors.length > 0 ? (
          <Tooltip
            placement="top"
            trigger={["hover"]}
            overlayInnerStyle={{
              borderColor: "#F8FA9C",
              backgroundColor: "#F8F9EB",
            }}
            overlay={
              <>
                <div className="text-light font-size-12 font-weight-400">
                  {getErrorMessage()}
                </div>
              </>
            }
          >
            <MaterialIcon icon={"close"} color={"#D84560"} />
          </Tooltip>
        ) : (
          <MaterialIcon icon={"check"} color={"#29CC39"} />
        )}
      </Col>
    </Row>
  );
};

export default LogItemCard;
