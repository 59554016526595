/* eslint-disable react/no-unknown-property */
import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const Loader: React.FC = () => {
  const meshRef = useRef<THREE.Mesh>(null);

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.x += 0.06;
      meshRef.current.rotation.y += 0.06;
    }
  });

  return (
    <group>
      <mesh ref={meshRef}>
        <boxGeometry args={[0.2, 0.2, 0.2]} />
        <meshStandardMaterial
          color="white"
        />
      </mesh>
    </group>
  );
};

export default Loader;
