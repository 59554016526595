import { Col, Row } from "react-bootstrap";
import { EDeviceStatus } from "../../../../../../shared/oversight-core/enums/device-status";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  status?: EDeviceStatus;
  isTemperature: boolean;
}

const AcControllerErrorModal = (props: IProps) => {
  const { show, status, isTemperature, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      show={show}
      title={
        isTemperature
          ? `A/C Device Temperature Control`
          : `A/C Device Power ${status === EDeviceStatus.ON ? `ON` : `OFF`}`
      }
      size="modal-md"
      hideCancel={true}
      confirmButtonText="Okay"
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            Please turn on the Smart Plug first.
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default AcControllerErrorModal;
