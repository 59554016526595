import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDateTypes } from "../../enums/date-types";
import AppDatePicker from "../app-date-picker/app-date-picker";
import styles from "./app-date-range-selector.module.scss";

interface IProps {
  startDate: Date;
  endDate: Date;
  setSelectedDate: (startDate: Date, endDate: Date) => void;
  dateTypes?: string[][];
  selectedDateType: EDateTypes;
  setSelectedDateType: React.Dispatch<React.SetStateAction<EDateTypes>>;
  maxDate?: Date;
}

const defaultDateTypes = [
  [EDateTypes.DAILY, EDateTypes.MONTHLY],
  [EDateTypes.MONTHLY, EDateTypes.CUSTOM],
];

const AppDateRangeSelector = (props: IProps) => {
  const {
    startDate,
    endDate,
    setSelectedDate,
    dateTypes = defaultDateTypes,
    selectedDateType,
    setSelectedDateType,
    maxDate,
  } = props;
  const [dateFormat, setDateFormat] = useState("dd-MMM-yyyy");

  useEffect(() => {
    switch (selectedDateType) {
      case EDateTypes.MONTHLY:
        setDateFormat("MMMM-yyyy");
        break;
      case EDateTypes.YEARLY:
        setDateFormat("yyyy");
        break;
      default:
        setDateFormat("dd-MMM-yyyy");
        break;
    }
  }, [selectedDateType]);

  return (
    <>
      <label className="font-size-14">Time</label>
      {dateTypes.map((dateType, index) => {
        return (
          <Row key={index} className="justify-content-between px-3">
            {dateType.map((type, index) => {
              return (
                <Col
                  key={index}
                  onClick={() => {
                    setSelectedDateType(type as EDateTypes);

                    if (type === EDateTypes.CUSTOM) {
                      setSelectedDate(startDate, startDate);
                    }
                  }}
                  className={`col-6 mt-2 font-weight-500 font-size-12 py-2 cursor-pointer text-center ${
                    selectedDateType === type
                      ? styles.selected
                      : `${styles.button}`
                  }`}
                >
                  {type}
                </Col>
              );
            })}
          </Row>
        );
      })}
      <Row className="mt-2 flex-column">
        <Col>
          <Row className="flex-column">
            {selectedDateType === EDateTypes.CUSTOM && (
              <Col>
                <label className="font-size-14">From</label>
              </Col>
            )}
            <Col>
              <AppDatePicker
                selectedDate={startDate}
                onChange={(date: Date) => {
                  setSelectedDate(date, endDate);
                }}
                monthYearPicker={selectedDateType === EDateTypes.MONTHLY}
                yearPicker={selectedDateType === EDateTypes.YEARLY}
                dateFormat={dateFormat}
                fontSize={12}
                maxDate={maxDate}
              />
            </Col>
          </Row>
        </Col>
        {selectedDateType === EDateTypes.CUSTOM && (
          <Col className="mt-2">
            <Row className="flex-column">
              <Col>
                <label className="font-size-14">To</label>
              </Col>
              <Col>
                <AppDatePicker
                  selectedDate={endDate}
                  onChange={(date: Date) => {
                    setSelectedDate(startDate, date);
                  }}
                  dateFormat={dateFormat}
                  fontSize={12}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AppDateRangeSelector;
