import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./pagination.module.scss";

interface IProps {
  length: number;
  itemsPerPage: number;
  currentPage: number;
  updateCurrentPage: (currentPageNumber: number) => void;
}

const Pagination = (props: IProps) => {
  const { itemsPerPage, length, currentPage, updateCurrentPage } = props;
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    const totalPages =
      length % itemsPerPage === 0
        ? length / itemsPerPage
        : Math.floor(length / itemsPerPage) + 1;
    setTotalNumberOfPages(totalPages);

    if (totalPages <= 5) {
      setFirstPage(1);
      setLastPage(totalPages);
    } else if (currentPage <= 3) {
      setFirstPage(1);
      setLastPage(5);
    } else if (currentPage > totalPages - 3) {
      setFirstPage(totalPages - 4);
      setLastPage(totalPages);
    } else {
      setFirstPage(currentPage - 2);
      setLastPage(currentPage + 2);
    }
  }, [length, itemsPerPage, currentPage]);

  return length > 0 ? (
    <Row className={`align-items-center justify-content-center gap-2 mt-4`}>
      <Col className="col-auto px-0">
        <div
          className={`${styles[`page`]} bg-primary cursor-pointer`}
          onClick={() => {
            if (currentPage - 1 >= 1) {
              updateCurrentPage(currentPage - 1);
            }
          }}
        >
          <MaterialIcon icon="arrow_left" className="text-white" />
        </div>
      </Col>
      {lastPage >= 6 && firstPage !== 1 && (
        <Col
          className={`col-auto cursor-pointer d-flex justify-content-center align-items-center bg-background-2 ${
            styles[`page`]
          } ${totalNumberOfPages > 6 ? "d-none d-sm-flex" : ""}`}
          onClick={() => {
            updateCurrentPage(1);
          }}
        >
          <p
            className={`font-size-14 ${
              currentPage === 1
                ? `text-primary font-weight-600`
                : `text-light font-weight-400`
            } mb-0`}
          >
            {1}
          </p>
        </Col>
      )}
      {lastPage > 6 && firstPage !== 2 && (
        <Col
          className={`col-auto bg-background-2 ${
            styles[`page`]
          } d-none d-sm-block`}
        >
          <p
            className={`font-size-14 text-light d-flex justify-content-center align-items-center font-weight-400 mb-0`}
          >
            ...
          </p>
        </Col>
      )}
      {Array.from(Array(lastPage - (firstPage - 1)).keys()).map((index) => {
        return (
          <Col
            key={index}
            className={`col-auto d-none cursor-pointer bg-background-2 d-sm-flex justify-content-center align-items-center ${
              styles[`page`]
            }`}
            onClick={() => {
              updateCurrentPage(firstPage + index);
            }}
          >
            <p
              className={`font-size-14 ${
                currentPage === firstPage + index
                  ? `text-primary font-weight-600`
                  : `text-light font-weight-400`
              } mb-0`}
            >
              {firstPage + index}
            </p>
          </Col>
        );
      })}
      <Col
        className={`col-auto d-block d-sm-none cursor-pointer bg-background-2 d-flex justify-content-center align-items-center ${
          styles[`page`]
        }`}
      >
        <p className={`font-size-14 text-primary font-weight-600 mb-0`}>
          {currentPage}
        </p>
      </Col>
      {firstPage < totalNumberOfPages - 5 &&
        lastPage !== totalNumberOfPages - 1 && (
          <Col
            className={`col-auto bg-background-2 ${
              styles[`page`]
            } d-none d-sm-block`}
          >
            <p
              className={`font-size-14 text-light d-flex justify-content-center align-items-center font-weight-400 mb-0`}
            >
              ...
            </p>
          </Col>
        )}
      {firstPage <= totalNumberOfPages - 5 &&
        lastPage !== totalNumberOfPages && (
          <Col
            className={`col-auto cursor-pointer bg-background-2 d-flex justify-content-center align-items-center ${
              styles[`page`]
            } ${totalNumberOfPages > 6 ? "d-none d-sm-flex" : ""}`}
            onClick={() => {
              updateCurrentPage(totalNumberOfPages);
            }}
          >
            <p
              className={`font-size-14 ${
                currentPage === totalNumberOfPages
                  ? `text-primary font-weight-600`
                  : `text-light font-weight-400`
              } mb-0`}
            >
              {totalNumberOfPages}
            </p>
          </Col>
        )}
      <Col className="col-auto px-0">
        <div
          className={`${styles[`page`]} bg-primary cursor-pointer`}
          onClick={() => {
            if (currentPage + 1 <= totalNumberOfPages) {
              updateCurrentPage(currentPage + 1);
            }
          }}
        >
          <MaterialIcon icon="arrow_right" className="text-white" />
        </div>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default Pagination;
