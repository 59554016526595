import { Col, Row } from "react-bootstrap";
import { IControllerInfoModalDetails } from "../../../../../../shared/oversight-core/interfaces/controller-info-modal-details";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  controllerInfo: IControllerInfoModalDetails;
}

const ControllerInfoModal = (props: IProps) => {
  const { show, controllerInfo, ...rest } = props;

  return (
    <ModalContainer
      show={show}
      hideCancel={true}
      size="modal-md"
      confirmButtonText="Okay"
      {...rest}
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            {controllerInfo.isToAnotherBillingSpace ? (
              `Smart controller ${controllerInfo.controllerName} is moved to the ${controllerInfo.spaceName} in the ${controllerInfo.billingSpaceName} billing space successfully.`
            ) : (
              <>
                Smart controller{" "}
                <span className="font-weight-600">
                  {controllerInfo.controllerName}
                </span>{" "}
                is successfully moved to the{" "}
                <span className="font-weight-600">
                  {controllerInfo.spaceName}
                </span>{" "}
                space.
              </>
            )}
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default ControllerInfoModal;
