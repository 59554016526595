/* eslint-disable react/no-unknown-property */
import React, { useRef } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

interface AnimatedShaderMaterialProps {
  baseColor1?: THREE.Color | string | number;
  baseColor2?: THREE.Color | string | number;
  emissionStrengthColor1?: number;
  emissionStrengthColor2?: number;
  timeSpeed?: number;
  animationDirection?: number;
  isAnimationOff?: boolean; 
}

const AnimatedShaderMaterial: React.FC<AnimatedShaderMaterialProps> = ({
  baseColor1 = new THREE.Color(0x0000ff), 
  baseColor2 = new THREE.Color(0x3333ff), 
  emissionStrengthColor1 = 1,
  emissionStrengthColor2 = 1,
  timeSpeed = 0.04,
  animationDirection = 1,
  isAnimationOff = false, 
}) => {
  const timeRef = useRef<number>(0);

  const vertexShader = `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `;

  const fragmentShader = `
      varying vec2 vUv;
      uniform float time;
      uniform vec3 baseColor1;
      uniform vec3 baseColor2;
      uniform float emissionStrengthColor1;
      uniform float emissionStrengthColor2;
      uniform float animationDirection;
      uniform bool isAnimationOff; 
      void main() {
        if (isAnimationOff) {
          gl_FragColor = vec4(vec3(1.0), 1.0);
          return;
        }
        float colorSwitch = abs(sin(vUv.x * 10.0 * animationDirection + time));
        vec3 emissionColor1 = baseColor1;
        vec3 emissionColor2 = baseColor2;
        vec3 baseColor = mix(baseColor1, baseColor2, step(0.5, fract(vUv.x * 10.0 * animationDirection + time)));
        vec3 emissionColor = mix(emissionColor1, emissionColor2, step(0.5, fract(vUv.x * 10.0 * animationDirection + time)));
        vec4 baseColorFinal = vec4(baseColor, 1.0);
        vec4 emittedColor1 = vec4(emissionColor1, 1.0) * emissionStrengthColor1;
        vec4 emittedColor2 = vec4(emissionColor2, 1.0) * emissionStrengthColor2;
        vec4 emittedColor = mix(emittedColor1, emittedColor2, step(0.5, fract(vUv.x * 10.0 * animationDirection + time)));
        gl_FragColor = baseColorFinal + emittedColor;
      }
    `;

  const shaderMaterial = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      time: { value: 0.0 },
      baseColor1: { value: new THREE.Color(baseColor1) },
      baseColor2: { value: new THREE.Color(baseColor2) },
      emissionStrengthColor1: { value: emissionStrengthColor1 },
      emissionStrengthColor2: { value: emissionStrengthColor2 },
      animationDirection: { value: animationDirection },
      isAnimationOff: { value: isAnimationOff }, 
    },
  });

  useFrame(() => {
    if (!isAnimationOff) {
      timeRef.current += timeSpeed;
      shaderMaterial.uniforms.time.value = timeRef.current;
    }
  });
  if (isAnimationOff) {
    return (
      <meshStandardMaterial
        attach="material"
        color={new THREE.Color("#aaa")}
        emissive={new THREE.Color(0x000000)}
      />
    );
  } else {
    return <primitive object={shaderMaterial} attach="material" />;
  }
};

export default AnimatedShaderMaterial;
