import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useRemoveSmartControllerMutation } from "../../../../redux/api/controller/controllerAPI";
import { setSmartControllerFilterSelectedSpace } from "../../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import MiniControllerCard from "../../../../shared/components/mini-controller-card/mini-controller-card";
import { EConnectionStatus } from "../../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../../shared/oversight-core/enums/device-status";
import { OvstErrorCode } from "../../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../../shared/oversight-core/enums/smart-controller-type";
import { IControllerInfoModalDetails } from "../../../../shared/oversight-core/interfaces/controller-info-modal-details";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import BulkAppToggle from "../../../../shared/oversight-core/ui-elements/bulk-app-toggle/bulk-app-toggle";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../shared/oversight-core/utils/toast";
import DeviceBulkConfirmationModal from "../../space-clusters/components/models/device-bulk-confirmation-modal/device-bulk-confirmation-modal";
import ControllerInfoModal from "../components/models/controller-info-modal/controller-info-modal";

interface IProps {
  isShowIcon: boolean;
  size: number;
  expandable: boolean;
  collapsed: boolean;
  mainSpace: ISpaceView;
  onSelectSpace: (space: ISpaceView) => void;
  updateCurrentState: () => void;
  updateControllerStatus: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: EDeviceStatus,
    controllerType: ESmartControllerType
  ) => void;
  updateSpaceStatus: (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => void;
  onAcTemperatureIncrease: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
}

const defaultControllerInfoModalDetails: IControllerInfoModalDetails = {
  spaceName: "",
  billingSpaceName: "",
  controllerName: "",
  isToAnotherBillingSpace: false,
};

const ControllerSpaceContainer = (props: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    isShowIcon,
    size,
    expandable,
    collapsed,
    mainSpace,
    onSelectSpace,
    updateCurrentState,
    updateControllerStatus,
    updateSpaceStatus,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [showControllerInfoModal, setShowControllerInfoModal] = useState(false);
  const [controllerInfoModalDetails, setControllerInfoModalDetails] =
    useState<IControllerInfoModalDetails>({
      ...defaultControllerInfoModalDetails,
    });
  const [showDeviceBulkConfirmationModal, setShowDeviceBulkConfirmationModal] =
    useState(false);
  const [bulkDeviceState, setBulkDeviceState] = useState<EDeviceStatus>(
    EDeviceStatus.OFF
  );

  const [removeSmartController, { isLoading: isLoadingRemoveSmartController }] =
    useRemoveSmartControllerMutation();

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSmartControllerFilterSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const checkDeviceConnectionState = (space: ISpaceView): boolean => {
    const smartControllerWithSpaceCluster = space.smartDevices.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED
    );

    if (smartControllerWithSpaceCluster) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  const sendControllerInfoModalDetails = (
    controllerName: string,
    isToAnotherBillingSpace: boolean,
    spaceName: string,
    billingSpaceName?: string
  ) => {
    setControllerInfoModalDetails({
      controllerName,
      isToAnotherBillingSpace,
      spaceName,
      billingSpaceName,
    });

    setShowControllerInfoModal(true);
  };

  const deleteSmartController = (
    spaceClusterId: string,
    spaceId: string,
    smartDeviceId: string
  ) => {
    removeSmartController({
      spaceClusterId,
      spaceId,
      smartDeviceId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Smart Controller Removed Successfully");
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
        ) {
          showErrorMessage(
            "Failure in one of the services. Please try again later."
          );
          return;
        }
        showErrorMessage("Removing Smart Controller Unsuccessful");
      });
  };

  return (
    <div className="controller-space-container position-relative py-3 px-2 my-3">
      <Container fluid>
        <div
          onClick={() => onSelectSpace(mainSpace)}
          style={{ cursor: "pointer" }}
        >
          <Row className="align-items-center">
            <Col className="pc-header">
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  {isShowIcon && (
                    <MaterialIcon icon={"apartment"} className="d-inline" />
                  )}
                </Col>
                <Col
                  style={{
                    color: isShowIcon ? `#383941` : `#69768B`,
                    fontSize: size,
                  }}
                  className="col-auto"
                >
                  {mainSpace.name}
                </Col>
                <Col
                  className={`${
                    !checkDeviceConnectionState(props.mainSpace)
                      ? `opacity-50`
                      : ``
                  } col-auto`}
                >
                  <BulkAppToggle
                    status={mainSpace.powerState}
                    onSwitch={(powerState: EDeviceStatus) => {
                      if (checkDeviceConnectionState(props.mainSpace)) {
                        setBulkDeviceState(powerState);
                        setShowDeviceBulkConfirmationModal(true);
                      }
                    }}
                    isDisable={!checkDeviceConnectionState(mainSpace)}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              {expandable && (
                <MaterialIcon
                  icon="expand_more"
                  className={`cursor-pointer dArrow`}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    setIsCollapsed(!isCollapsed);
                  }}
                  style={
                    isCollapsed
                      ? { transform: "rotate(0deg)" }
                      : { transform: "rotate(180deg)" }
                  }
                />
              )}
            </Col>
          </Row>
          <div
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
            style={{ cursor: "default" }}
          >
            <Row className={`${isCollapsed ? "d-none" : ""} mt-3`}>
              {mainSpace.smartDevices.map((smartDevice) => {
                return (
                  <Col
                    key={smartDevice.id}
                    className="col-12 col-xl-6 col-xxl-4 p-2"
                  >
                    <MiniControllerCard
                      smartDevice={{
                        ...smartDevice,
                        clusterId: mainSpace.clusterId,
                        spaceId: mainSpace.id,
                      }}
                      updateControllerStatus={updateControllerStatus}
                      onAcTemperatureDecrease={props.onAcTemperatureDecrease}
                      onAcTemperatureIncrease={props.onAcTemperatureIncrease}
                      sendControllerInfoModalDetails={
                        sendControllerInfoModalDetails
                      }
                      deleteSmartController={deleteSmartController}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <Row className={`${isCollapsed ? "d-none" : ""}`}>
          <Col>
            {props.mainSpace.childSpaces &&
              props.mainSpace.childSpaces.length > 0 && (
                <div>
                  {props.mainSpace.childSpaces.map((ss, index) => (
                    <ControllerSpaceContainer
                      key={index}
                      mainSpace={{
                        ...ss,
                        clusterId: props.mainSpace.clusterId,
                      }}
                      expandable={true}
                      isShowIcon={false}
                      size={14}
                      collapsed={true}
                      onSelectSpace={selectSpaceHandler}
                      updateCurrentState={updateCurrentState}
                      updateControllerStatus={updateControllerStatus}
                      updateSpaceStatus={updateSpaceStatus}
                      onAcTemperatureDecrease={props.onAcTemperatureDecrease}
                      onAcTemperatureIncrease={props.onAcTemperatureIncrease}
                    />
                  ))}
                </div>
              )}
          </Col>
        </Row>
      </Container>
      <ControllerInfoModal
        show={showControllerInfoModal}
        onClose={() => setShowControllerInfoModal(false)}
        controllerInfo={{ ...controllerInfoModalDetails }}
        onConfirm={() => setShowControllerInfoModal(false)}
      />
      <DeviceBulkConfirmationModal
        show={showDeviceBulkConfirmationModal}
        onClose={() => setShowDeviceBulkConfirmationModal(false)}
        onCancel={() => setShowDeviceBulkConfirmationModal(false)}
        onConfirm={() => {
          if (checkDeviceConnectionState(mainSpace))
            updateSpaceStatus(
              mainSpace.clusterId,
              mainSpace.id,
              bulkDeviceState
            );
          setShowDeviceBulkConfirmationModal(false);
        }}
        spaceState={bulkDeviceState}
        spaceName={props.mainSpace.name}
      />
      <SpinnerModal show={isLoadingRemoveSmartController} />
    </div>
  );
};

export default ControllerSpaceContainer;
