import { Col, Row } from "react-bootstrap";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import SpinnerModal from "../spinner/spinner";
import styles from "./info-and-value.module.scss";

interface IProps {
  title: string;
  value: string | JSX.Element;
  withContainer?: boolean;
  isFetching?: boolean;
  icon?: MtIcon;
}

const InfoAndValue = (props: IProps) => {
  const { title, value, withContainer = true, isFetching, icon } = props;

  return (
    <div className={`${withContainer && `${styles.infoContainer} h-100 p-4`}`}>
      <Row className="align-items-center mb-1">
        {icon && (
          <Col className="col-auto pe-0">
            <MaterialIcon icon={icon} size={18} />
          </Col>
        )}
        <Col
          className={`text-light font-size-12 font-weight-400 ${
            icon && `ps-2`
          }`}
        >
          {title}
        </Col>
      </Row>
      <Row>
        <Col className="col-auto text-dark font-size-20 font-weight-600">
          {isFetching ? (
            <SpinnerModal show={isFetching} withOverlay={false} size="md" />
          ) : (
            value
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InfoAndValue;
