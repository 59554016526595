import { useEffect, useState } from "react";
import { EDateTypes } from "../../../../../shared/oversight-core/enums/date-types";
import FilteringSidePanel from "../../../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppDatePicker from "../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppDateRangeSelector from "../../../../../shared/oversight-core/ui-elements/app-date-range-selector/app-date-range-selector";

interface IProps {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  onFilter: (
    selectedDateType: EDateTypes,
    selectedDate: Date,
    selectedTotalGenerationDate: Date
  ) => void;
  isTotalGenerationFilter: boolean;
  selectedDateType: EDateTypes;
  selectedDate: number;
  selectedTotalGenerationDate: number;
  onClearAll: () => void;
}

const GenerationFilter = (props: IProps) => {
  const {
    showFilter,
    setShowFilter,
    isTotalGenerationFilter,
    onFilter,
    onClearAll,
  } = props;

  const [selectedDateType, setSelectedDateType] = useState(EDateTypes.DAILY);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTotalGenerationDate, setSelectedTotalGenerationDate] =
    useState(new Date());

  useEffect(() => {
    setSelectedDateType(props.selectedDateType);
  }, [props.selectedDateType]);

  useEffect(() => {
    setSelectedDate(new Date(props.selectedDate));
  }, [props.selectedDate]);

  useEffect(() => {
    setSelectedTotalGenerationDate(new Date(props.selectedTotalGenerationDate));
  }, [props.selectedTotalGenerationDate]);

  return (
    <FilteringSidePanel
      isOpen={showFilter}
      onClose={() => {
        setShowFilter(false);
      }}
      onFilter={() => {
        onFilter(selectedDateType, selectedDate, selectedTotalGenerationDate);
      }}
      onClearAll={onClearAll}
    >
      <>
        {isTotalGenerationFilter ? (
          <div>
            <AppDatePicker
              selectedDate={selectedTotalGenerationDate}
              onChange={(date: Date) => {
                setSelectedTotalGenerationDate(date);
              }}
              monthYearPicker={true}
              dateFormat={"MMMM-yyyy"}
              fontSize={12}
              maxDate={
                new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
              }
            />
          </div>
        ) : (
          <div>
            <AppDateRangeSelector
              startDate={selectedDate}
              endDate={new Date()}
              setSelectedDate={(startDate: Date) => {
                setSelectedDate(startDate);
              }}
              dateTypes={[[EDateTypes.DAILY, EDateTypes.MONTHLY]]}
              selectedDateType={selectedDateType}
              setSelectedDateType={setSelectedDateType}
              maxDate={
                selectedDateType === EDateTypes.DAILY
                  ? new Date()
                  : new Date(
                      new Date().getFullYear(),
                      new Date().getMonth() + 1,
                      0
                    )
              }
            />
          </div>
        )}
      </>
    </FilteringSidePanel>
  );
};

export default GenerationFilter;
