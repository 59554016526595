import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EScheduledAction } from "../../../../../shared/oversight-core/enums/scheduled-action";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ViewLogItemModal from "../modals/view-log-item-modal/view-log-item-modal";
import styles from "./schedule-log-item.module.scss";

interface IProps {
  logId: string;
  timeStamp: string;
  scheduleTitle: string;
  scheduledAction: EScheduledAction;
  deviceCount: number;
  succeededCount: number;
  isVentricleLine: boolean;
}
const ScheduleLogItem = (props: IProps) => {
  const {
    timeStamp,
    scheduleTitle,
    scheduledAction,
    deviceCount,
    succeededCount,
    isVentricleLine,
    logId,
  } = props;
  const [showViewLogItemModal, setShowViewLogItemModal] = useState(false);

  const greenCircle =
    scheduledAction === EScheduledAction.ON && succeededCount >= deviceCount;

  const readCircle =
    scheduledAction === EScheduledAction.OFF && succeededCount < deviceCount;

  const indicatorHandle = () => {
    if (greenCircle) {
      return "greenCircle";
    } else if (readCircle) {
      return "redCircle";
    } else return "blueCircle";
  };

  return (
    <>
      <Row
        className={`align-items-center my-3 mx-2 ${
          isVentricleLine ? "" : styles.ventricleLine
        }`}
      >
        <Col className="col-auto pe-0">
          <div
            className={`${styles.circle} ${styles[indicatorHandle()]}`}
          ></div>
        </Col>
        <Col
          className="col-auto font-size-12 font-weight-400 text-light"
          style={{ width: "60px" }}
        >
          {timeStamp}
        </Col>
        <Col className={`py-2 font-size-12 ${styles.logItemContainer} me-3`}>
          <Row className="align-items-center justify-content-between">
            <Col>
              <Row>
                <Col className="font-weight-500 col-2">{scheduleTitle}</Col>
                <Col className="font-weight-400 text-light">
                  <span
                    className={succeededCount < deviceCount ? `text-red` : ""}
                  >
                    {succeededCount !== null && succeededCount}
                    {succeededCount !== null && deviceCount !== null && "/"}
                    {deviceCount !== null && deviceCount}&nbsp;
                  </span>
                  Devices Turned &nbsp;
                  {scheduledAction === EScheduledAction.ON ? "On" : "Off"}
                </Col>
              </Row>
            </Col>
            <Col className="col-auto">
              <MaterialIcon
                icon="arrow_right_alt"
                className="cursor-pointer d-arrow"
                size={16}
                onClick={() => {
                  setShowViewLogItemModal(true);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <ViewLogItemModal
        show={showViewLogItemModal}
        onClose={() => {
          setShowViewLogItemModal(false);
        }}
        logId={logId}
        deviceCount={deviceCount}
      />
    </>
  );
};

export default ScheduleLogItem;
