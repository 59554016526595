import ReactGA from "react-ga4";
import ListSmartControllersRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/list-smart-controllers-request-dto";
import ListUnlinkedDevicesRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/list-unlinked-devices-request-dto";
import ListUnlinkedSmartPlugRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/list-unlinked-smart-plugs-request-dto";
import RegisterAcControllerRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/register-ac-controller-request-dto";
import { default as RegisterControllerRequestDTO } from "../../../shared/oversight-core/dtos/request-dtos/register-controller-request-dto";
import UnlinkAcControllerRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/unlink-ac-controller-request-dto";
import UnlinkSmartPlugRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/unlink-smart-plug-request-dto";
import UpdateAcControllerRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-ac-controller-request-dto";
import UpdateControllerRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-controller-request-dto";
import IViewManufacturedDeviceBySerialKeyRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/view-manufactured-device-by-serial-key-request-dto";
import {
  IListSmartControllersGroupedResponseDTO,
  IListSmartControllersResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/list-smart-controllers-response-dto";
import ListUnlinkedDevicesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/list-unlinked-devices-response-dto";
import ListUnlinkedSmartPlugsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/list-unlinked-smart-plugs-response-dto";
import MoveSmartPlugResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/move-smart-plug-response-dto";
import RegisterAcControllerResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/register-ac-controller-response-dto";
import {
  default as RegisterControllerResponseDTO,
  default as RegisterSmartDevicesResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/register-controller-response-dto";
import SwitchAcControllerPowerStateByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/switch-ac-controller-power-state-by-id-response-dto";
import SwitchPowerConsumerPowerStateByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/switch-power-consumer-power-state-by-id-response-dto";
import SwitchSmartDevicePowerStateByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/switch-smart-device-power-state-by-Id-response-dto";
import SwitchSmartPlugPowerStateByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/switch-smart-plug-power-state-by-id-response-dto";
import UnlinkAcControllerResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/unlink-ac-controller-response-dto";
import UnlinkSmartPlugResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/unlink-smart-plug-response-dto";
import IViewManufacturedDeviceBySerialKeyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-manufactured-device-by-serial-key-response-dto";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const controllerAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    switchSmartController: builder.mutation<
      SwitchSmartPlugPowerStateByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        smartPlugId: string;
        switchingPowerState: EDeviceStatus;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}/power/switch`,
          method: "PUT",
          params: {
            switchingPowerState: data.switchingPowerState,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          ReactGA.event({
            category: "Successful",
            action: "Controller On/Off",
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
    switchAcController: builder.mutation<
      SwitchAcControllerPowerStateByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        acControllerId: string;
        switchingPowerState: EDeviceStatus;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/power/switch`,
          method: "PUT",
          params: {
            switchingPowerState: data.switchingPowerState,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          ReactGA.event({
            category: "Successful",
            action: "Controller On/Off",
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
    switchPowerConsumer: builder.mutation<
      SwitchPowerConsumerPowerStateByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        powerConsumerId: string;
        switchingPowerState: EDeviceStatus;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power-consumer/${data.powerConsumerId}/power/switch`,
          method: "PUT",
          params: {
            switchingPowerState: data.switchingPowerState,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          ReactGA.event({
            category: "Successful",
            action: "Controller On/Off",
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
    switchSpace: builder.mutation<
      SwitchSmartDevicePowerStateByIdResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        switchingPowerState: EDeviceStatus;
        forceTurnOn: boolean;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/power/switch`,
          method: "PUT",
          params: {
            switchingPowerState: data.switchingPowerState,
            forceTurnOn: data.forceTurnOn,
          },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          ReactGA.event({
            category: "Successful",
            action: "Bulk Controller On/Off",
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
    unLinkPowerConsumerList: builder.query<
      ListUnlinkedDevicesResponseDTO,
      ListUnlinkedDevicesRequestDTO
    >({
      transformErrorResponse,
      query(data: ListUnlinkedDevicesRequestDTO) {
        return {
          url: `power-consumer/unlinked/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    unLinkSmartPlugList: builder.query<
      ListUnlinkedSmartPlugsResponseDTO,
      ListUnlinkedSmartPlugRequestDTO
    >({
      transformErrorResponse,
      query(data: ListUnlinkedSmartPlugRequestDTO) {
        return {
          url: `smart-device/unlinked/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    addController: builder.mutation<
      RegisterControllerResponseDTO,
      RegisterControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-device/smart-plug/register`,
          method: "POST",
          body: {
            registeringSmartPlugs: data.registeringSmartPlugs,
          },
        };
      },
    }),
    addAcController: builder.mutation<
      RegisterAcControllerResponseDTO,
      RegisterAcControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-device/ac-controller/register`,
          method: "POST",
          body: {
            registeringAcControllers: data.registeringAcControllers,
          },
        };
      },
    }),
    updateController: builder.mutation<
      RegisterSmartDevicesResponseDTO,
      UpdateControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}`,
          method: "PUT",
          body: {
            name: data.name,
            smartPlugType: data.smartPlugType,
            linkedPowerConsumerId: data.linkedPowerConsumerId,
            excludedSemiAutomatedSchedules: data.excludedSemiAutomatedSchedules,
          },
          params: {
            isLinkOnly: data.isLinkOnly,
            keepInProgramme: data.keepInProgramme,
          },
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    updateAcController: builder.mutation<
      RegisterAcControllerResponseDTO,
      UpdateAcControllerRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}`,
          method: "PUT",
          body: {
            name: data.name,
            linkedAcId: data.linkedAcId,
            excludedSemiAutomatedSchedules: data.excludedSemiAutomatedSchedules,
          },
          params: {
            isLinkOnly: data.isLinkOnly,
          },
        };
      },
    }),
    listSmartControllers: builder.query<
      IListSmartControllersResponseDTO &
        IListSmartControllersGroupedResponseDTO,
      ListSmartControllersRequestDTO
    >({
      transformErrorResponse,
      query(data: ListSmartControllersRequestDTO) {
        return {
          url: `smart-device/list`,
          method: "GET",
          params: { ...data },
        };
      },
    }),
    viewManufacturedDeviceBySerialKey: builder.query<
      IViewManufacturedDeviceBySerialKeyResponseDTO,
      IViewManufacturedDeviceBySerialKeyRequestDTO
    >({
      transformErrorResponse,
      query(data: IViewManufacturedDeviceBySerialKeyRequestDTO) {
        return {
          url: `smart-device/manufactured-device/${data.serialKey}`,
          method: "GET",
        };
      },
    }),
    unlinkedLinkPowerConsumer: builder.mutation<
      UnlinkSmartPlugResponseDTO,
      UnlinkSmartPlugRequestDTO
    >({
      transformErrorResponse,
      query(data: UnlinkSmartPlugRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}/unlink`,
          method: "PUT",
          body: {
            linkedPowerConsumerId: data.linkedPowerConsumerId,
            excludedSemiAutomatedSchedules: data.excludedSemiAutomatedSchedules,
          },
          params: {
            keepInProgramme: data.keepInProgramme,
          },
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    unlinkAcController: builder.mutation<
      UnlinkAcControllerResponseDTO,
      UnlinkAcControllerRequestDTO
    >({
      transformErrorResponse,
      query(data: UnlinkAcControllerRequestDTO) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/unlink`,
          method: "PUT",
          body: {
            linkedAcId: data.linkedAcId,
            excludedSemiAutomatedSchedules: data.excludedSemiAutomatedSchedules,
          },
        };
      },
      invalidatesTags: ["Schedule"],
    }),
    moveController: builder.mutation<
      MoveSmartPlugResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        smartPlugId: string;
        destinationSpaceId: string;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/smart-plug/${data.smartPlugId}/move`,
          method: "PUT",
          params: { destinationSpaceId: data.destinationSpaceId },
        };
      },
    }),
    updateAcTemperature: builder.mutation<
      MoveSmartPlugResponseDTO,
      {
        spaceClusterId: string;
        spaceId: string;
        acControllerId: string;
        updatingTemperature: number;
      }
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `space-cluster/${data.spaceClusterId}/space/${data.spaceId}/ac-controller/${data.acControllerId}/temperature`,
          method: "PUT",
          params: { updatingTemperature: data.updatingTemperature },
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          ReactGA.event({
            category: "Successful",
            action: "AC Temperature",
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
    removeSmartController: builder.mutation<
      void,
      {
        spaceClusterId: string;
        spaceId: string;
        smartDeviceId: string;
      }
    >({
      transformErrorResponse,
      query({ spaceClusterId, spaceId, smartDeviceId }) {
        return {
          url: `space-cluster/${spaceClusterId}/space/${spaceId}/smart-device/${smartDeviceId}/remove`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Schedule"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyUnLinkPowerConsumerListQuery,
  useLazyUnLinkSmartPlugListQuery,
  useSwitchSmartControllerMutation,
  useSwitchAcControllerMutation,
  useSwitchPowerConsumerMutation,
  useSwitchSpaceMutation,
  useAddControllerMutation,
  useAddAcControllerMutation,
  useUpdateControllerMutation,
  useLazyListSmartControllersQuery,
  useUnlinkedLinkPowerConsumerMutation,
  useMoveControllerMutation,
  useLazyViewManufacturedDeviceBySerialKeyQuery,
  useUpdateAcTemperatureMutation,
  useUpdateAcControllerMutation,
  useUnlinkAcControllerMutation,
  useRemoveSmartControllerMutation,
} = controllerAPI;
