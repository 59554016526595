import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  borderColors: string[];
  backgroundColors: string[];
  data: Array<number>;
  yAxesUnit: string;
  labels: Array<string>;
}

const BarChart = (props: IProps) => {
  const labels = props.labels;
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (data: any) {
            return data.formattedValue + " " + props.yAxesUnit;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (value: any) => {
            return value + " " + props.yAxesUnit;
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        data: props.data,
        backgroundColor: props.backgroundColors,
        borderWidth: 1,
        borderColor: props.borderColors,
      },
    ],
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
