import Tooltip from "rc-tooltip";
import { useCallback } from "react";
import { Col, Row } from "react-bootstrap";

import { EConsumptionValueComparison } from "../../oversight-core/enums/consumption-comparison";
import { EUsageDataFrom } from "../../oversight-core/enums/usage-data-from";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import Helper from "../../oversight-core/utils/helpers";
import { switchUsageDataFrom } from "../../oversight-core/utils/switchUsageDataFrom";
import styles from "./usage-comparison-card.module.scss";

interface IProps {
  title: string;
  thisMonthUnits: number;
  lastMonthUnits: number;
  thisMonthTitle: string;
  lastMonthTitle: string;
  consumptionValueComparison?: EConsumptionValueComparison;
  isFetching: boolean;
  usageDataFrom: EUsageDataFrom;
}

const UsageComparisonCard = (props: IProps) => {
  const switchIcon = useCallback(() => {
    switch (props.consumptionValueComparison) {
      case EConsumptionValueComparison.HIGH:
        return <MaterialIcon icon="trending_up" color="#E75B74" />;
      case EConsumptionValueComparison.LOW:
        return <MaterialIcon icon="trending_down" color="#59C270" />;
      default:
        return;
    }
  }, [props.consumptionValueComparison]);

  const switchBackgroundColor = useCallback(() => {
    switch (props.consumptionValueComparison) {
      case EConsumptionValueComparison.HIGH:
        return "#FFFBFB";
      case EConsumptionValueComparison.LOW:
        return "#ECF8EF";
      case EConsumptionValueComparison.EQUAL:
        return "#F7F9FB";
      default:
        return "";
    }
  }, [props.consumptionValueComparison]);

  const usageDataFrom = (
    <Tooltip
      placement="bottom"
      trigger={["hover"]}
      overlay={
        <div className="text-light font-size-12 font-weight-500">
          {switchUsageDataFrom(props.usageDataFrom)}
        </div>
      }
    >
      <div className={` user-select-none`}>
        <MaterialIcon
          className="cursor-mouse"
          icon="info"
          color="#69768B"
          size={13}
        />
      </div>
    </Tooltip>
  );

  const spinnerModal = (
    <Row className="justify-content-start">
      <Col className="col-auto">
        <SpinnerModal withOverlay={false} show={props.isFetching} size="md" />
      </Col>
    </Row>
  );

  return (
    <div className="container-white h-100">
      <Row>
        <Col className="text-light font-size-12 font-weight-500">
          {props.title}
        </Col>
      </Row>
      <div
        className={`${styles.container} ${
          switchBackgroundColor() && `p-2`
        } mt-3`}
        style={{
          backgroundColor: `${switchBackgroundColor()}`,
        }}
      >
        <Row>
          <Col>{switchIcon()}</Col>
        </Row>
        <Row className="mt-1">
          <Col className={styles.title}>{props.thisMonthTitle}</Col>
        </Row>
        {props.isFetching ? (
          <>{spinnerModal}</>
        ) : (
          <>
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <div className={`${styles.units} d-flex align-items-center`}>
                  <label>{Helper.roundTo2(props.thisMonthUnits)} Units</label>
                </div>
              </Col>
              {props.usageDataFrom && (
                <Col className="col-auto ps-1">{usageDataFrom}</Col>
              )}
            </Row>
          </>
        )}
      </div>
      <div className={`${styles.container} mt-4`}>
        <Row>
          <Col className={styles.title}>{props.lastMonthTitle}</Col>
        </Row>
        {props.isFetching ? (
          <>{spinnerModal}</>
        ) : (
          <>
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <div className={`${styles.units} d-flex align-items-center`}>
                  <label>{Helper.roundTo2(props.lastMonthUnits)} Units</label>
                </div>
              </Col>
              {props.usageDataFrom && (
                <Col className="col-auto ps-1">{usageDataFrom}</Col>
              )}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default UsageComparisonCard;
