import { useEffect, useRef, useState } from "react";
import styles from "./path.module.scss";

interface IProps {
  x: number;
  y: number;
  d: string;
  direction: "up" | "down";
  color: "#2EB6EE" | "#2DEC9F" | "#C9C9CC" | "#E9E36A";
  dashColor: "#20A0D4" | "#0AD181" | "#A7A7A7" | "#A19B12";
  isActive?: boolean;
  reactX: number;
  reactY: number;
  value: number;
  isShowLineValues?: boolean;
}

const Path = (props: IProps) => {
  const { isShowLineValues = true } = props;

  const pathRef = useRef<SVGPathElement>(null);
  const [center, setCenter] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength();
      const midpoint = pathRef.current.getPointAtLength(pathLength / 2);
      setCenter({ x: midpoint.x, y: midpoint.y });
    }
  }, [props.d]);

  return (
    <svg
      className={styles.path}
      style={{ top: props.y + "px", left: props.x + "px" }}
    >
      <path
        ref={pathRef}
        d={props.d}
        strokeMiterlimit="10"
        fill="none"
        stroke={props.color}
        transform="translate(2 2)"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeWidth="4"
        strokeDashoffset="10"
      ></path>
      <path
        d={props.d}
        strokeMiterlimit="10"
        fill="none"
        stroke={props.dashColor}
        transform="translate(2 2)"
        strokeWidth="4"
        strokeDasharray="10"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
      >
        <animate
          attributeName="stroke-dashoffset"
          values={
            props.isActive
              ? props.direction === "down"
                ? "100;-100"
                : "-100;100"
              : undefined
          }
          dur="3s"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </path>
      {isShowLineValues && (
        <g
          transform={`translate(${center.x - props.reactX}, ${
            center.y - props.reactY
          })`}
        >
          <rect width="50" height="18" rx="10" fill={props.dashColor} />
          <text
            x="24"
            y="10"
            textAnchor="middle"
            alignmentBaseline="middle"
            fill="white"
            fontSize="11"
            fontFamily="Arial"
          >
            {props.value} kW
          </text>
        </g>
      )}
    </svg>
  );
};

export default Path;
