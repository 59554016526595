import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useLazyListSmartControllersQuery,
  useRemoveSmartControllerMutation,
  useSwitchAcControllerMutation,
  useSwitchSmartControllerMutation,
} from "../../../redux/api/controller/controllerAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import {
  selectSmartControllersFilter,
  setSelectedSmartControllerFilterSpace,
  setSmartControllersFilter,
} from "../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import ControllerCard from "../../../shared/components/controller-card/controller-card";
import SmartControllersFilter from "../../../shared/components/smart-controllers-filter/smart-controllers-filter";
import {
  IListSmartControllersGroupedResponseDTO,
  IListSmartControllersResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/list-smart-controllers-response-dto";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { EGroupBy } from "../../../shared/oversight-core/enums/group-by";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../shared/oversight-core/enums/smart-controller-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IControllerInfoModalDetails } from "../../../shared/oversight-core/interfaces/controller-info-modal-details";
import { IGroupedSmartControllers } from "../../../shared/oversight-core/interfaces/grouped-smart-controllers";
import { ISelectedSpace } from "../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import { ISpaceClusterSmartPlugViews } from "../../../shared/oversight-core/interfaces/space-cluster-smart-plug-views";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import { showSwitchErrorMessage } from "../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../shared/oversight-core/utils/toast";
import ControllerInfoModal from "./components/models/controller-info-modal/controller-info-modal";
import styles from "./controllers.module.scss";

const defaultControllerInfoModalDetails: IControllerInfoModalDetails = {
  spaceName: "",
  billingSpaceName: "",
  controllerName: "",
  isToAnotherBillingSpace: false,
};

const Controllers = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const smartControllersFiltersStore = useSelector(
    selectSmartControllersFilter
  );

  const [smartControllersListGrouped, setSmartControllersListGrouped] =
    useState<IGroupedSmartControllers[]>([]);
  const [smartControllersListNotGrouped, setSmartControllersListNotGrouped] =
    useState<ISpaceClusterSmartPlugViews[]>([]);
  const [groupedCollapsed, setGroupedCollapsed] = useState<boolean[]>([]);
  const [isControllerUpdated, setIsControllerUpdated] = useState(false);
  const [showControllerInfoModal, setShowControllerInfoModal] = useState(false);
  const [controllerInfoModalDetails, setControllerInfoModalDetails] =
    useState<IControllerInfoModalDetails>({
      ...defaultControllerInfoModalDetails,
    });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [
    triggerListSmartControllers,
    { isFetching: isFetchingListSmartControllers },
  ] = useLazyListSmartControllersQuery();
  const [switchSmartPlugState, { isLoading: isLoadingSwitchSmartPlugState }] =
    useSwitchSmartControllerMutation();
  const [
    switchAcControllerState,
    { isLoading: isLoadingSwitchAcControllerState },
  ] = useSwitchAcControllerMutation();
  const [removeSmartController, { isLoading: isLoadingRemoveSmartController }] =
    useRemoveSmartControllerMutation();
  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceCluster }] =
    useLazyGetSpacesQuery();

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    setIsFirstTime(true);
    if (smartControllersFiltersStore.selectedGroupBy === "Space") {
      if (
        smartControllersFiltersStore.selectedSpace.clusterId &&
        smartControllersFiltersStore.selectedSpace.id
      ) {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          type: smartControllersFiltersStore.selectedDeviceType,
          smartDeviceType: smartControllersFiltersStore.selectedSmartDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          spaceClusterId: smartControllersFiltersStore.selectedSpace.clusterId,
          subRootSpaceId: smartControllersFiltersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersResponseDTO) => {
            setSmartControllersListNotGrouped(response.smartDevices.elements);
          })
          .catch(() => {
            setSmartControllersListNotGrouped([]);
          });
      } else {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          type: smartControllersFiltersStore.selectedDeviceType,
          smartDeviceType: smartControllersFiltersStore.selectedSmartDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersResponseDTO) => {
            setSmartControllersListNotGrouped(response.smartDevices.elements);
          })
          .catch(() => {
            setSmartControllersListNotGrouped([]);
          });
      }
    } else {
      if (
        smartControllersFiltersStore.selectedSpace.clusterId &&
        smartControllersFiltersStore.selectedSpace.id
      ) {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          groupBy:
            smartControllersFiltersStore.selectedGroupBy === "Type"
              ? EGroupBy.DEVICE_CATEGORY
              : EGroupBy.STATUS,
          spaceClusterId: smartControllersFiltersStore.selectedSpace.clusterId,
          smartDeviceType: smartControllersFiltersStore.selectedSmartDeviceType,
          subRootSpaceId: smartControllersFiltersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersGroupedResponseDTO) => {
            const sortedPowerState = sortGroupPowerState(
              response.smartDevices.elements
            );
            const sortedType = sortGroupType(response.smartDevices.elements);
            setSmartControllersListGrouped(
              smartControllersFiltersStore.selectedGroupBy === "Type"
                ? sortedType
                : sortedPowerState
            );

            const temp: boolean[] = [];
            response.smartDevices.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setSmartControllersListGrouped([]);
          });
      } else {
        triggerListSmartControllers({
          searchField: "name",
          searchValue: smartControllersFiltersStore.searchText,
          smartDeviceType: smartControllersFiltersStore.selectedSmartDeviceType,
          groupBy:
            smartControllersFiltersStore.selectedGroupBy === "Type"
              ? EGroupBy.DEVICE_CATEGORY
              : EGroupBy.STATUS,
          ascending: true,
        })
          .unwrap()
          .then((response: IListSmartControllersGroupedResponseDTO) => {
            const sortedPowerState = sortGroupPowerState(
              response.smartDevices.elements
            );
            const sortedType = sortGroupType(response.smartDevices.elements);
            setSmartControllersListGrouped(
              smartControllersFiltersStore.selectedGroupBy === "Type"
                ? sortedType
                : sortedPowerState
            );

            const temp: boolean[] = [];
            response.smartDevices.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setSmartControllersListGrouped([]);
          });
      }
    }
  }, [
    triggerListSmartControllers,
    smartControllersFiltersStore,
    isControllerUpdated,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstTime(false);
      if (smartControllersFiltersStore.selectedGroupBy === "Space") {
        if (
          smartControllersFiltersStore.selectedSpace.clusterId &&
          smartControllersFiltersStore.selectedSpace.id
        ) {
          triggerListSmartControllers({
            searchField: "name",
            searchValue: smartControllersFiltersStore.searchText,
            type: smartControllersFiltersStore.selectedDeviceType,
            smartDeviceType:
              smartControllersFiltersStore.selectedSmartDeviceType,
            pageNumber: 0,
            pageSize: 1000,
            spaceClusterId:
              smartControllersFiltersStore.selectedSpace.clusterId,
            subRootSpaceId: smartControllersFiltersStore.selectedSpace.id,
            ascending: true,
          })
            .unwrap()
            .then((response: IListSmartControllersResponseDTO) => {
              setSmartControllersListNotGrouped(response.smartDevices.elements);
            })
            .catch(() => {
              setSmartControllersListNotGrouped([]);
            });
        } else {
          triggerListSmartControllers({
            searchField: "name",
            searchValue: smartControllersFiltersStore.searchText,
            type: smartControllersFiltersStore.selectedDeviceType,
            smartDeviceType:
              smartControllersFiltersStore.selectedSmartDeviceType,
            pageNumber: 0,
            pageSize: 1000,
            ascending: true,
          })
            .unwrap()
            .then((response: IListSmartControllersResponseDTO) => {
              setSmartControllersListNotGrouped(response.smartDevices.elements);
            })
            .catch(() => {
              setSmartControllersListNotGrouped([]);
            });
        }
      } else {
        if (
          smartControllersFiltersStore.selectedSpace.clusterId &&
          smartControllersFiltersStore.selectedSpace.id
        ) {
          triggerListSmartControllers({
            searchField: "name",
            searchValue: smartControllersFiltersStore.searchText,
            groupBy:
              smartControllersFiltersStore.selectedGroupBy === "Type"
                ? EGroupBy.DEVICE_CATEGORY
                : EGroupBy.STATUS,
            spaceClusterId:
              smartControllersFiltersStore.selectedSpace.clusterId,
            subRootSpaceId: smartControllersFiltersStore.selectedSpace.id,
            smartDeviceType:
              smartControllersFiltersStore.selectedSmartDeviceType,
            ascending: true,
          })
            .unwrap()
            .then((response: IListSmartControllersGroupedResponseDTO) => {
              const sortedPowerState = sortGroupPowerState(
                response.smartDevices.elements
              );
              const sortedType = sortGroupType(response.smartDevices.elements);
              setSmartControllersListGrouped(
                smartControllersFiltersStore.selectedGroupBy === "Type"
                  ? sortedType
                  : sortedPowerState
              );

              const temp: boolean[] = [];
              response.smartDevices.elements.forEach(() => {
                temp.push(false);
              });
              setGroupedCollapsed(temp);
            })
            .catch(() => {
              setSmartControllersListGrouped([]);
            });
        } else {
          triggerListSmartControllers({
            searchField: "name",
            searchValue: smartControllersFiltersStore.searchText,
            smartDeviceType:
              smartControllersFiltersStore.selectedSmartDeviceType,
            groupBy:
              smartControllersFiltersStore.selectedGroupBy === "Type"
                ? EGroupBy.DEVICE_CATEGORY
                : EGroupBy.STATUS,
            ascending: true,
          })
            .unwrap()
            .then((response: IListSmartControllersGroupedResponseDTO) => {
              const sortedPowerState = sortGroupPowerState(
                response.smartDevices.elements
              );
              const sortedType = sortGroupType(response.smartDevices.elements);
              setSmartControllersListGrouped(
                smartControllersFiltersStore.selectedGroupBy === "Type"
                  ? sortedType
                  : sortedPowerState
              );
              const temp: boolean[] = [];
              response.smartDevices.elements.forEach(() => {
                temp.push(false);
              });
              setGroupedCollapsed(temp);
            })
            .catch(() => {
              setSmartControllersListGrouped([]);
            });
        }
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [
    triggerListSmartControllers,
    smartControllersFiltersStore,
    isControllerUpdated,
  ]);

  const updateSmartControllersFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    selectedSmartDeviceType: string,
    selectedSearchTextType: string,
    badgeValue: number,
    isClear: boolean
  ) => {
    dispatch(
      setSelectedSmartControllerFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );
    dispatch(
      setSmartControllersFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        selectedSmartDeviceType: selectedSmartDeviceType,
        selectedSearchTextType: selectedSearchTextType,
        badgeValue: badgeValue,
        isClear: isClear,
      })
    );
  };

  useEffect(() => {
    if (
      !smartControllersFiltersStore.selectedSpace.clusterId ||
      smartControllersFiltersStore.selectedGroupBy === "Type" ||
      smartControllersFiltersStore.selectedGroupBy === "Status" ||
      smartControllersFiltersStore.selectedDeviceType ||
      smartControllersFiltersStore.selectedSmartDeviceType ||
      (smartControllersFiltersStore.selectedSpace.clusterId &&
        smartControllersFiltersStore.searchText)
    ) {
      return;
    }

    if (smartControllersFiltersStore.selectedSpace.id) {
      navigate(pathname + "/" + smartControllersFiltersStore.selectedSpace.id);
    }
  }, [smartControllersFiltersStore]);

  const updateControllerStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: EDeviceStatus,
    controllerType: ESmartControllerType
  ) => {
    (controllerType === ESmartControllerType.SMART_PLUG
      ? switchSmartPlugState({
          spaceClusterId: clusterId,
          spaceId: spaceId,
          smartPlugId: smartDeviceId,
          switchingPowerState: status,
        })
      : switchAcControllerState({
          spaceClusterId: clusterId,
          spaceId: spaceId,
          acControllerId: smartDeviceId,
          switchingPowerState: status,
        })
    )
      .unwrap()
      .then((res) => {
        setIsControllerUpdated((ps) => !ps);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const sendControllerInfoModalDetails = (
    controllerName: string,
    isToAnotherBillingSpace: boolean,
    spaceName: string,
    billingSpaceName?: string
  ) => {
    setControllerInfoModalDetails({
      controllerName,
      isToAnotherBillingSpace,
      spaceName,
      billingSpaceName,
    });
    setShowControllerInfoModal(true);
  };

  const deleteSmartController = (
    spaceClusterId: string,
    spaceId: string,
    smartDeviceId: string
  ) => {
    removeSmartController({
      spaceClusterId,
      spaceId,
      smartDeviceId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Smart Controller Removed Successfully");
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
        ) {
          showErrorMessage(
            "Failure in one of the services. Please try again later."
          );
          return;
        }
        showErrorMessage("Removing Smart Controller Unsuccessful");
      });
  };

  const sortGroupType = (
    data: IGroupedSmartControllers[]
  ): IGroupedSmartControllers[] => {
    const sortedData = [...data];
    const disabledIndex = sortedData.findIndex(
      (item) => item.group === "DISABLED"
    );

    if (disabledIndex !== -1) {
      const disabledItem = sortedData.splice(disabledIndex, 1)[0];
      sortedData.push(disabledItem);
    }

    return sortedData;
  };

  const sortGroupPowerState = (
    data: IGroupedSmartControllers[]
  ): IGroupedSmartControllers[] => {
    const order = ["ON", "OFF", "UNKNOWN", "DISABLE"];
    const sortedData = [...data];

    sortedData.sort((a, b) => {
      const indexA = order.indexOf(a.group);
      const indexB = order.indexOf(b.group);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedData;
  };

  return (
    <div className="position-relative">
      {spaceClusters && spaceClusters.length > 0 ? (
        <>
          <SmartControllersFilter
            updateFilters={updateSmartControllersFilters}
            isLoading={isFetchingListSmartControllers && isFirstTime}
          />
          <div className="filtered-devices-list">
            {(smartControllersFiltersStore.selectedGroupBy === "Type" ||
              smartControllersFiltersStore.selectedGroupBy === "Status") && (
              <>
                {smartControllersListGrouped.length !== 0 ? (
                  <>
                    {smartControllersListGrouped.map(
                      (elements: IGroupedSmartControllers, index) => {
                        return (
                          <Row
                            key={index}
                            className="container-white mx-1 mt-4"
                          >
                            <Col>
                              <Row
                                className="cursor-pointer"
                                onClick={() => {
                                  setGroupedCollapsed((ps) => {
                                    const temp = [...ps];
                                    temp[index] = !temp[index];
                                    return [...temp];
                                  });
                                }}
                              >
                                {smartControllersFiltersStore.selectedGroupBy ===
                                  "Type" && (
                                  <Col
                                    className={`p-1 ${elements.group} rounded col-auto`}
                                  >
                                    {elements.group !== "DISABLED" && (
                                      <MaterialIcon
                                        icon={findIcon(
                                          elements.group as EDeviceTypes
                                        )}
                                        size={19}
                                      />
                                    )}
                                  </Col>
                                )}
                                <Col className="text-dark font-size-16 font-weight-500">{`${
                                  elements.group
                                }${
                                  smartControllersFiltersStore.selectedGroupBy ===
                                  "Status"
                                    ? " State"
                                    : ""
                                }${
                                  smartControllersFiltersStore.selectedGroupBy ===
                                    "Type" && elements.group !== "DISABLED"
                                    ? "s"
                                    : ""
                                }`}</Col>
                                <Col className="col-auto">
                                  <MaterialIcon
                                    icon="expand_more"
                                    className={`cursor-pointer dArrow`}
                                    style={
                                      groupedCollapsed[index]
                                        ? { transform: "rotate(-90deg)" }
                                        : { transform: "rotate(0deg)" }
                                    }
                                  />
                                </Col>
                              </Row>
                              {elements.spaceClusterSmartDevices.map(
                                (spaceClusterSmartController) => {
                                  return (
                                    <Row
                                      key={
                                        spaceClusterSmartController.smartDevice
                                          .id
                                      }
                                      className={`${
                                        groupedCollapsed[index] ? "d-none" : ""
                                      } mt-2`}
                                    >
                                      <Col className="px-0">
                                        <ControllerCard
                                          smartDevice={{
                                            ...spaceClusterSmartController.smartDevice,
                                            clusterId:
                                              spaceClusterSmartController.spaceClusterId,
                                            spaceId:
                                              spaceClusterSmartController.spaceId,
                                          }}
                                          path={
                                            spaceClusterSmartController.path
                                          }
                                          updateControllerStatus={
                                            updateControllerStatus
                                          }
                                          setIsControllerUpdated={
                                            setIsControllerUpdated
                                          }
                                          sendControllerInfoModalDetails={
                                            sendControllerInfoModalDetails
                                          }
                                          deleteSmartController={
                                            deleteSmartController
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    {!isFetchingListSmartControllers && (
                      <Row className="container-white mx-1 mt-4">
                        <div className="container-dash mt-4">
                          <Col className="text-center text-light font-size-12">
                            There are no devices available for the selected
                            filters.
                          </Col>
                        </div>
                      </Row>
                    )}
                  </>
                )}
              </>
            )}
            {smartControllersFiltersStore.selectedGroupBy !== "Type" &&
              smartControllersFiltersStore.selectedGroupBy !== "Status" &&
              (smartControllersFiltersStore.selectedDeviceType ||
                smartControllersFiltersStore.selectedSmartDeviceType ||
                smartControllersFiltersStore.searchText) && (
                <Row className="container-white mx-1 mt-4">
                  {smartControllersListNotGrouped.length !== 0 ? (
                    <Col>
                      {smartControllersFiltersStore.selectedDeviceType && (
                        <Row className="align-items-center cursor-pointer mx-1">
                          <Col
                            className={`p-1 ${smartControllersFiltersStore.selectedDeviceType} rounded col-auto`}
                          >
                            <MaterialIcon
                              icon={findIcon(
                                smartControllersFiltersStore.selectedDeviceType as EDeviceTypes
                              )}
                              size={19}
                            />
                          </Col>
                          <Col className="text-dark font-size-16 font-weight-500">{`${smartControllersFiltersStore.selectedDeviceType}s`}</Col>
                        </Row>
                      )}
                      {smartControllersFiltersStore.selectedSmartDeviceType && (
                        <Row className="align-items-center cursor-pointer mt-2 mx-1">
                          <Col
                            className={`p-1 ${styles.smartControllerIcon} rounded col-auto`}
                          >
                            <MaterialIcon
                              icon={
                                smartControllersFiltersStore.selectedSmartDeviceType ===
                                ESmartControllerType.SMART_PLUG
                                  ? `smart_outlet`
                                  : `missing_controller`
                              }
                              color="#7E9ABB"
                              size={19}
                            />
                          </Col>
                          <Col className="text-dark font-size-16 font-weight-500">{`${
                            smartControllersFiltersStore.selectedSmartDeviceType ===
                            ESmartControllerType.SMART_PLUG
                              ? `Smart Plugs`
                              : `AC Controllers`
                          }`}</Col>
                        </Row>
                      )}
                      {smartControllersListNotGrouped.map(
                        (
                          spaceClusterSmartController: ISpaceClusterSmartPlugViews
                        ) => {
                          return (
                            <Row
                              key={spaceClusterSmartController.smartDevice.id}
                              className="mt-3"
                            >
                              <Col>
                                <ControllerCard
                                  smartDevice={{
                                    ...spaceClusterSmartController.smartDevice,
                                    clusterId:
                                      spaceClusterSmartController.spaceClusterId,
                                    spaceId:
                                      spaceClusterSmartController.spaceId,
                                  }}
                                  path={spaceClusterSmartController.path}
                                  updateControllerStatus={
                                    updateControllerStatus
                                  }
                                  setIsControllerUpdated={
                                    setIsControllerUpdated
                                  }
                                  sendControllerInfoModalDetails={
                                    sendControllerInfoModalDetails
                                  }
                                  deleteSmartController={deleteSmartController}
                                />
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </Col>
                  ) : (
                    <>
                      {!isFetchingListSmartControllers && (
                        <div className="container-dash mt-4">
                          <Col className="text-center text-light font-size-12">
                            There are no controllers available for the selected
                            filters.
                          </Col>
                        </div>
                      )}
                    </>
                  )}
                </Row>
              )}
            <ControllerInfoModal
              show={showControllerInfoModal}
              onClose={() => setShowControllerInfoModal(false)}
              controllerInfo={controllerInfoModalDetails}
              onConfirm={() => setShowControllerInfoModal(false)}
            />
          </div>
        </>
      ) : (
        <>
          {!isFetchingSpaceCluster && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <SpinnerModal
        show={
          isFetchingSpaceCluster ||
          isLoadingSwitchSmartPlugState ||
          (isFetchingListSmartControllers && isFirstTime) ||
          isLoadingSwitchAcControllerState ||
          isLoadingRemoveSmartController
        }
      />
    </div>
  );
};

export default Controllers;
