import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLazyViewCurrentEnergySourceDataQuery } from "../../../redux/api/dashboard/dashboardAPI";
import {
  useLazyViewEnergySourceHistoryQuery,
  useLazyViewPowerUsageBySourceQuery,
} from "../../../redux/api/management/managementAPI";
import {
  selectSourceManagementFilter,
  setSourceManagementFilter,
} from "../../../redux/features/source-management-filter/source-management-filter.slice";
import ViewCurrentEnergySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-current-energy-source-response-dto";
import ViewEnergySourceHistoryResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-source-history-response-dto";
import ViewPowerUsageBySourceResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-power-usage-by-source-response-dto";
import { EManagementMode } from "../../../shared/oversight-core/enums/management-mode";
import { EViewTimeUnit } from "../../../shared/oversight-core/enums/view-time-unit";
import { IEnergySourceView } from "../../../shared/oversight-core/interfaces/energy-source-view";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import InfoAndValue from "../../../shared/oversight-core/ui-elements/info-and-value/info-and-value";
import Pagination from "../../../shared/oversight-core/ui-elements/pagination/pagination";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import EnergySourceInfoContainer from "./components/energy-source-info-container/energy-source-info-container";
import ManagementFilter from "./components/management-filter/management-filter";
import SourceManageCard from "./components/source-manage-card/source-manage-card";

const managementModes: Option[] = [
  {
    value: EManagementMode.SEMI_AUTO,
    label: "Automated",
  },
  {
    value: EManagementMode.MANUAL,
    label: "Manual",
    data: { isDisabled: true },
  },
];

const ITEM_PER_PAGE = 3;

interface ChartData {
  solarData: number[];
  windTurbineData: number[];
  generatorData: number[];
  gridDate: number[];
  timeStamps: string[];
}

const defaultChartDataValues = {
  solarData: [],
  windTurbineData: [],
  generatorData: [],
  gridDate: [],
  timeStamps: [],
};

const defaultEnergySourceData = {
  dateTime: "",
  consumedEnergy: 0,
  generatedEnergy: 0,
  exportedEnergy: 0,
  previousEnergySources: [],
  currentEnergySources: [],
  nextSourceSelectionDateTime: "",
  demandValueComparison: "",
  generationValueComparison: "",
};

const SourceManagement = () => {
  const sourceManagementFilter = useSelector(selectSourceManagementFilter);
  const dispatch = useDispatch();

  const [selectedManagementMode, setSelectedManagementMode] = useState({
    ...managementModes[0],
  });
  const [filters, setFilters] = useState({
    pageNumber: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [energySourceData, setEnergySourceData] = useState<IEnergySourceView[]>(
    []
  );
  const [chartData, setChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [currentEnergySourceData, setCurrentEnergySourceData] =
    useState<IEnergySourceView>({ ...defaultEnergySourceData });
  const [showFilter, setShowFilter] = useState(false);

  const [
    triggerViewEnergySourceHistory,
    { isFetching: isFetchingViewEnergySourceHistory },
  ] = useLazyViewEnergySourceHistoryQuery();
  const [
    triggerViewPowerUsageBySource,
    { isFetching: isFetchingViewPowerUsageBySource },
  ] = useLazyViewPowerUsageBySourceQuery();
  const [
    triggerViewCurrentEnergySourceData,
    { isFetching: isFetchingViewCurrentEnergySourceData },
  ] = useLazyViewCurrentEnergySourceDataQuery();

  useEffect(() => {
    triggerViewEnergySourceHistory({
      pageNumber: filters.pageNumber,
      pageSize: ITEM_PER_PAGE,
      ascending: true,
    })
      .unwrap()
      .then((res: ViewEnergySourceHistoryResponseDTO) => {
        setEnergySourceData(res.page.elements);
        setTotalElements(res.page.totalElements);
      })
      .catch(() => {
        setEnergySourceData([]);
        setTotalElements(0);
      });
  }, [triggerViewEnergySourceHistory, filters]);

  useEffect(() => {
    triggerViewPowerUsageBySource({
      from: moment().toISOString(),
      timeUnit: sourceManagementFilter.viewTimeUnit as EViewTimeUnit,
    })
      .unwrap()
      .then((res: ViewPowerUsageBySourceResponseDTO) => {
        const solarPowerByMinutes = res.solarPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).minute() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.MINUTE
                  ? 5
                  : 1) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const solarPowerByHours = res.solarPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).hours() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY
                  ? 6
                  : 2) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const gridPowerByMinutes = res.gridPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).minute() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.MINUTE
                  ? 5
                  : 1) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const gridPowerByHours = res.gridPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).hours() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY
                  ? 6
                  : 2) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const generatorPowerByMinutes = res.generatorPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).minute() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.MINUTE
                  ? 5
                  : 1) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const generatorPowerByHours = res.generatorPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).hours() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY
                  ? 6
                  : 2) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const windPowerByMinutes = res.windTurbinePowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).minute() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.MINUTE
                  ? 5
                  : 1) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const windPowerByHours = res.windTurbinePowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).hours() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY
                  ? 6
                  : 2) ===
              0
          )
          .map((item) => item.powerInWatt / 1000);

        const timeStampsByMinutes = res.solarPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).minute() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.MINUTE
                  ? 5
                  : 1) ===
              0
          )
          .map((item) => moment(item.timeStamp).format("HH:mm"));

        const timeStampsByHours = res.solarPowerUsage
          .filter(
            (item) =>
              moment(item.timeStamp).hours() %
                (sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY
                  ? 6
                  : 2) ===
              0
          )
          .map((item) => moment(item.timeStamp).format("HH:mm"));

        const timeStamps =
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY ||
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.HOUR
            ? timeStampsByHours
            : timeStampsByMinutes;

        const solarPower =
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY ||
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.HOUR
            ? solarPowerByHours
            : solarPowerByMinutes;

        const gridPower =
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY ||
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.HOUR
            ? gridPowerByHours
            : gridPowerByMinutes;

        const generatorPower =
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY ||
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.HOUR
            ? generatorPowerByHours
            : generatorPowerByMinutes;

        const windPower =
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY ||
          sourceManagementFilter.viewTimeUnit === EViewTimeUnit.HOUR
            ? windPowerByHours
            : windPowerByMinutes;

        setChartData({
          solarData: solarPower,
          windTurbineData: windPower,
          generatorData: generatorPower,
          gridDate: gridPower,
          timeStamps: timeStamps,
        });
      })
      .catch(() => {
        setChartData({ ...defaultChartDataValues });
      });
  }, [triggerViewPowerUsageBySource, sourceManagementFilter.viewTimeUnit]);

  useEffect(() => {
    triggerViewCurrentEnergySourceData()
      .unwrap()
      .then((res: ViewCurrentEnergySourceResponseDTO) => {
        setCurrentEnergySourceData(res.energySourceView);
      })
      .catch(() => {
        setCurrentEnergySourceData({ ...defaultEnergySourceData });
      });
  }, [triggerViewCurrentEnergySourceData]);

  const onFilter = (viewTimeUnit: string) => {
    dispatch(
      setSourceManagementFilter({
        viewTimeUnit: viewTimeUnit,
      })
    );
    setShowFilter(false);
  };

  return (
    <>
      <div className="container-white position-relative">
        <Row className="align-items-center">
          <Col className="font-weight-500 font-size-14 text-dark">
            Power Usage by Source
          </Col>
          <Col className="col-auto">
            <ButtonWithBadge
              text="Filter"
              icon="filter_alt"
              onClick={() => {
                setShowFilter(true);
              }}
              badgeValue={0}
            />
          </Col>
        </Row>
        <AreaChart
          borderColor1="#2DEC9F"
          borderColor2="#2EB5ED"
          borderColor3="#7C0F0F"
          borderColor4="#A19B12"
          backgroundColor1="#29CC4D17"
          backgroundColor2="#DFFDFB4D"
          backgroundColor3="#D644442E"
          backgroundColor4="#CCBC2917"
          label1="Solar Power"
          label2="Wind Power"
          label3="Generator"
          label4="Grid Power"
          yAxesUnit="kW"
          labels={chartData.timeStamps}
          data1={chartData.solarData}
          data2={chartData.windTurbineData}
          data3={chartData.generatorData}
          data4={chartData.gridDate}
          hasForthDataSet={true}
          isChartDataAvailable={true}
          xAxisLabel={
            sourceManagementFilter.viewTimeUnit === EViewTimeUnit.HOUR ||
            sourceManagementFilter.viewTimeUnit === EViewTimeUnit.DAY
              ? "Hours"
              : "Minutes"
          }
        />
        <SpinnerModal show={isFetchingViewPowerUsageBySource} />
      </div>
      <Row className="my-4 align-items-center justify-content-center">
        <Col className="col-auto col-sm">
          <Row className="align-items-center">
            <Col className="col-auto font-weight-600 font-size-14 text-dark">
              Source Selection
            </Col>
            <Col className="col-auto">
              <AppSelect
                icon={
                  selectedManagementMode.value === EManagementMode.SEMI_AUTO
                    ? "autoplay"
                    : "touch_app"
                }
                selectedValue={selectedManagementMode}
                options={[...managementModes]}
                onChangeOption={(selectedOption) => {
                  setSelectedManagementMode(selectedOption);
                }}
                fontSize="12px"
                labelFontSize="font-size-14"
                fontWeight="500"
                fontColor="#69768b"
                isOptionsDisabled={(option) => option?.data?.isDisabled}
              />
            </Col>
          </Row>
        </Col>
        {selectedManagementMode.value === EManagementMode.MANUAL && (
          <Col className="col-auto mt-4 mt-sm-0">
            <Row className="align-items-center gap-4 gap-sm-2">
              <Col xs={"auto"}>
                <InfoAndValue
                  title="02/12/2023"
                  value={"06.47"}
                  withContainer={false}
                />
              </Col>
              <Col xs={"auto"}>
                <InfoAndValue
                  title="Current Usage"
                  value={"3.5 kW"}
                  withContainer={false}
                />
              </Col>
              <Col xs={"auto"}>
                <InfoAndValue
                  title="Current Generation"
                  value={"5 kW"}
                  withContainer={false}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {selectedManagementMode.value === EManagementMode.MANUAL && (
        <Row className="justify-content-center mb-4">
          <Col className="col-auto">
            <EnergySourceInfoContainer />
          </Col>
        </Row>
      )}
      <Row className="mt-2 mb-4">
        <Col>
          <SourceManageCard
            isCurrentSource={true}
            selectedManagementMode={
              selectedManagementMode.value as EManagementMode
            }
            energySourceData={{
              ...currentEnergySourceData,
              demandValueComparison: "",
              generationValueComparison: "",
            }}
            isFetching={isFetchingViewCurrentEnergySourceData}
          />
        </Col>
      </Row>
      {energySourceData?.map((es, index) => (
        <Row key={index} className="mt-2">
          <Col>
            <SourceManageCard
              isCurrentSource={false}
              selectedManagementMode={
                selectedManagementMode.value as EManagementMode
              }
              energySourceData={es}
              isFetching={isFetchingViewEnergySourceHistory}
            />
          </Col>
        </Row>
      ))}
      <Pagination
        itemsPerPage={ITEM_PER_PAGE}
        length={totalElements}
        currentPage={filters.pageNumber + 1}
        updateCurrentPage={(pn) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            pageNumber: pn - 1,
          }));
        }}
      />
      <ManagementFilter
        showFilter={showFilter}
        setShowFilter={(value: boolean) => {
          setShowFilter(value);
        }}
        onFilter={onFilter}
      />
    </>
  );
};

export default SourceManagement;
