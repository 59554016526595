import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { IDateTimeRangeView } from "../../../../../../shared/oversight-core/interfaces/date-time-range-view";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import styles from "./schedule-conflict-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceName: string;
  conflictedTimeList: IDateTimeRangeView[];
}

const ScheduleConflictModal = (props: IProps) => {
  const { show, conflictedTimeList, deviceName, ...rest } = props;

  return (
    <ModalContainer
      title="Conflicting Schedules Found"
      {...rest}
      show={show}
      size="modal-md"
      isButtonsVisible={false}
    >
      <>
        <Row>
          <Col>
            {deviceName} has already been scheduled within following hours
          </Col>
        </Row>
        <Row className="mt-2">
          {conflictedTimeList?.map((conflict, index) => {
            return (
              <Col
                className="col-auto font-size-12 font-weight-500 text-gray"
                key={index}
              >
                <div className={`p-2 ${styles.conflict}`}>
                  <Row className="flex-column align-items-center">
                    <Col className="col-auto">
                      {moment(conflict.fromDate).format("YYYY-MM-DD")}
                    </Col>
                    <Col className="col-auto">
                      {moment(conflict.fromDate).format("HH:mm")}
                      {" - "}
                      {moment(conflict.toDate).format("HH:mm")}
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row>
      </>
    </ModalContainer>
  );
};

export default ScheduleConflictModal;
