import { useNavigate } from "react-router-dom";
import {
  useRemoveManualScheduleMutation,
  useRemoveSemiAutomationScheduleMutation,
} from "../../../../../../redux/api/schedule/scheduleAPI";
import { EManagementMode } from "../../../../../../shared/oversight-core/enums/management-mode";
import { AppRoute } from "../../../../../../shared/oversight-core/interfaces/app-routes";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IProps extends ModelContainerProps {
  show: boolean;
  spaceClusterId: string;
  subRootSpaceId: string;
  deleteScheduleData: {
    scheduleId: string;
    scheduleTitle: string;
  };
  onSuccess?: () => void;
  selectedManagementMode: EManagementMode;
}

const ScheduleDeleteModal = (props: IProps) => {
  const { show, ...rest } = props;
  const navigate = useNavigate();

  const [
    removeSemiAutomationScheduleMutation,
    { isLoading: isLoadingSemiAutomationRemoveSpace },
  ] = useRemoveSemiAutomationScheduleMutation();

  const [
    removeManualScheduleMutation,
    { isLoading: isLoadingManualRemoveSpace },
  ] = useRemoveManualScheduleMutation();

  const handleOnConfirm = () => {
    (props.selectedManagementMode === EManagementMode.SEMI_AUTO
      ? removeSemiAutomationScheduleMutation
      : removeManualScheduleMutation)({
      spaceClusterId: props.spaceClusterId,
      subRootSpaceId: props.subRootSpaceId,
      scheduleId: props.deleteScheduleData.scheduleId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(
          props.selectedManagementMode === EManagementMode.SEMI_AUTO
            ? "Semi Automation Schedule Deleted Successfully"
            : "Manual Schedule Deleted Successfully"
        );
        props.onClose && props.onClose();
        props.onSuccess && props.onSuccess();
        navigate(AppRoute.SCHEDULE);
      })
      .catch(() => {
        showErrorMessage(
          props.selectedManagementMode === EManagementMode.SEMI_AUTO
            ? "Semi Automation Schedule Deleting Unsuccessful"
            : "Manual Schedule Deleting Unsuccessful"
        );
        props.onClose && props.onClose();
      });
  };

  return (
    <ModalContainer
      {...rest}
      title={"Delete Schedule"}
      show={show}
      size="modal-md"
      confirmButtonText="Delete"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
      onConfirm={handleOnConfirm}
      isLoading={
        isLoadingSemiAutomationRemoveSpace || isLoadingManualRemoveSpace
      }
    >
      <>
        <p className="text-light font-weight-400 font-size-14">
          <span className="font-weight-500">
            {props.deleteScheduleData.scheduleTitle} will be removed
            permanently,
          </span>{" "}
          Are you sure you want to delete this schedule? This action cannot be
          undone and all associated data will be permanently deleted
        </p>
        <p className="text-light font-weight-400 font-size-14">
          If you&rsquo;ve added recurring events for this event, those will also
          be removed.
        </p>
        <p className="text-light font-weight-400 font-size-14">
          if you just need to Remove the specific time slot, please use the edit
        </p>
      </>
    </ModalContainer>
  );
};

export default ScheduleDeleteModal;
