import { Option } from "../../oversight-core/ui-elements/app-select/app-select";
import { ERepetitionMode } from "../enums/repetition-mode";

export const findRepetition = (
  repetition: ERepetitionMode,
  options: Option[]
) => {
  switch (repetition) {
    case ERepetitionMode.DO_NOT_REPEAT:
      return options[0];
    case ERepetitionMode.DAILY:
      return options[1];
    case ERepetitionMode.WEEKLY:
      return options[2];
    case ERepetitionMode.MONTHLY:
      return options[3];
    case ERepetitionMode.YEARLY:
      return options[4];
    default:
      return options[0];
  }
};
