import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLazyViewSemiAutomationScheduleLogsByDateQuery } from "../../../../../redux/api/schedule/scheduleAPI";
import { selectScheduleFilter } from "../../../../../redux/features/schedule-filter/schedule-filter-slice";
import ViewSemiAutomationScheduleLogsByDateResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-logs-by-date-response-dto";
import ISemiAutomatedScheduleLogsShallowView from "../../../../../shared/oversight-core/interfaces/semi-automated-schedule-logs-shallow-view";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import ScheduleLogItem from "../schedule-log-item/schedule-log-item";

interface IProps {
  showScheduleLogView: boolean;
  selectedDate: Date;
}
const ScheduleLogViewContainer = (props: IProps) => {
  const { showScheduleLogView, selectedDate } = props;
  const scheduleFilterStore = useSelector(selectScheduleFilter);
  const [scheduleLogs, setScheduleLogs] = useState<
    ISemiAutomatedScheduleLogsShallowView[]
  >([]);
  const [collapsed, setCollapsed] = useState<{
    [key: string]: boolean;
  }>({});

  const [
    triggerViewSemiAutomationScheduleLogsByDate,
    { isFetching: isFetchingViewSemiAutomationScheduleLogsByDate },
  ] = useLazyViewSemiAutomationScheduleLogsByDateQuery();

  useEffect(() => {
    if (showScheduleLogView) {
      const toDate = moment(new Date(selectedDate))
        .add(1, "weeks")
        .toISOString();
      const fromDate = moment(new Date(selectedDate)).toISOString();
      triggerViewSemiAutomationScheduleLogsByDate({
        spaceClusterId: scheduleFilterStore.selectedSpace.clusterId,
        from: fromDate,
        to: toDate,
      })
        .unwrap()
        .then((res: ViewSemiAutomationScheduleLogsByDateResponseDTO) => {
          setScheduleLogs(
            res.semiAutomatedScheduleLogs.map((scheduleLog) => {
              return { ...scheduleLog };
            })
          );
        })
        .catch(() => {
          setScheduleLogs([]);
        });
    }
  }, [
    scheduleFilterStore.selectedSpace.clusterId,
    showScheduleLogView,
    selectedDate,
  ]);

  const scheduleLogViewContainerCollapse = (dateKey: string) => {
    setCollapsed((prevState) => ({
      ...prevState,
      [dateKey]: !prevState[dateKey],
    }));
  };

  const logsByDate: {
    [date: string]: ISemiAutomatedScheduleLogsShallowView[];
  } = {};

  scheduleLogs.forEach((log) => {
    const dateKey = moment(log.timeStamp).format("DD/MM/YYYY");
    if (!logsByDate[dateKey]) {
      logsByDate[dateKey] = [];
    }
    logsByDate[dateKey].push(log);
  });

  const sortedDates = Object.keys(logsByDate).sort(
    (a, b) =>
      moment(b, "DD/MM/YYYY").valueOf() - moment(a, "DD/MM/YYYY").valueOf()
  );

  return (
    <>
      {isFetchingViewSemiAutomationScheduleLogsByDate ? (
        <SpinnerModal show={isFetchingViewSemiAutomationScheduleLogsByDate} />
      ) : (
        <>
          {sortedDates.length > 0 ? (
            sortedDates.map((date, index) => (
              <div
                key={index}
                className="schedule-log-view-container py-4 my-3 mx-0"
              >
                <Row className="align-items-center">
                  <Col className="font-size-14 font-weight-500 col-auto ms-4 pe-0">
                    {moment(date, "DD/MM/YYYY").format("DD dddd")}
                  </Col>
                  <Col className="col-auto">
                    <MaterialIcon
                      icon="expand_more"
                      className="cursor-pointer dArrow"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        scheduleLogViewContainerCollapse(date);
                      }}
                      style={
                        collapsed[date]
                          ? { transform: "rotate(180deg)" }
                          : { transform: "rotate(0deg)" }
                      }
                    />
                  </Col>
                </Row>
                {collapsed[date] && (
                  <>
                    {logsByDate[date].map((log, index) => (
                      <Row key={log.id}>
                        <Col>
                          <ScheduleLogItem
                            logId={log.id}
                            timeStamp={moment(log.timeStamp).format("HH:mm")}
                            scheduleTitle={log.scheduleTitle}
                            scheduledAction={log.scheduledAction}
                            deviceCount={log.deviceCount}
                            succeededCount={log.succeededCount}
                            isVentricleLine={
                              index === logsByDate[date].length - 1
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  This space does not have any schedule logs.
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ScheduleLogViewContainer;
