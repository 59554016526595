import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useLazyGetSpaceHierarchyQuery,
  useLazyGetSpaceQuery,
} from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import { selectFilter } from "../../../redux/features/filter/filter-slice";
import { ISpaceView } from "../../oversight-core/interfaces/entities/space";
import { ISpaceShallowHierarchyView } from "../../oversight-core/interfaces/space-shallow-hierarchy-view";
import AppDropDown from "../../oversight-core/ui-elements/app-dropdown/app-drop-down";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import FilterSearch from "../filter-search/filter-search";
import styles from "./space-path-view-breadcrumb.module.scss";

interface IProps {
  selectedSpace: { clusterId: string; spaceId: string };
  sendSelectedSpace: (
    space: ISpaceView,
    breadcrumb?: ISpaceShallowHierarchyView[]
  ) => void;
}

const SpacePathViewBreadcrumb = (props: IProps) => {
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const filtersStore = useSelector(selectFilter);
  const { selectedSpace, sendSelectedSpace } = props;
  const [breadCrumbPieces, setBreadCrumbPieces] = useState<
    ISpaceShallowHierarchyView[]
  >([]);

  const [triggerGetSpace, { data: spaceData }] = useLazyGetSpaceQuery();
  const [triggerGetHierarchy] = useLazyGetSpaceHierarchyQuery();

  useEffect(() => {
    if (selectedSpace.clusterId && selectedSpace.spaceId) {
      triggerGetHierarchy({
        clusterId: selectedSpace.clusterId,
        spaceId: selectedSpace.spaceId,
      })
        .unwrap()
        .then((response) => {
          setBreadCrumbPieces(response.ancestorSpaces);
          triggerGetSpace({
            clusterId: selectedSpace.clusterId,
            spaceId: selectedSpace.spaceId,
          });
        })
        .catch(() => {
          setBreadCrumbPieces([]);
        });
    }
  }, [
    selectedSpace.clusterId,
    selectedSpace.spaceId,
    billingSpaceFilter.isSpaceClusterListChanged,
    filtersStore.isSpaceListChanged,
    filtersStore.isClear,
  ]);

  const getSpace = (id: string) => {
    triggerGetSpace({
      clusterId: selectedSpace.clusterId,
      spaceId: id,
    })
      .unwrap()
      .then((res) => {
        triggerGetHierarchy({
          clusterId: selectedSpace.clusterId,
          spaceId: id,
        })
          .unwrap()
          .then((response) => {
            sendSelectedSpace(
              {
                ...res.subSpaceCluster.rootSpace,
                clusterId: selectedSpace.clusterId,
              },
              response.ancestorSpaces
            );
          });
      });
  };

  return (
    <div className={`${styles.spacePathViewBreadcrumbContainer} p-2`}>
      <Row className="align-items-center justify-content-between">
        <Col className="col-auto">
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <MaterialIcon icon="apartment" color="#69768B" />
            </Col>
            <Col
              className={`${styles.greaterThan} col-auto ps-2 pe-0`}
            >{`>`}</Col>
            {breadCrumbPieces.map((breadCrumbPiece, index) => {
              return (
                <Col key={breadCrumbPiece.id} className="col-auto pe-2">
                  <Row className="align-items-center">
                    <Col
                      className={`${styles.breadCrumbPath} ${
                        selectedSpace.spaceId === breadCrumbPiece.id &&
                        `${styles.selectedBreadCrumbPath} py-1 px-3 mx-3`
                      } col-auto cursor-pointer`}
                      onClick={() => {
                        getSpace(breadCrumbPiece.id);
                      }}
                    >
                      {`${breadCrumbPiece.name}`}
                    </Col>
                    <Col className={`${styles.greaterThan} col-auto px-0`}>
                      {index === breadCrumbPieces.length - 1 ? "" : ">"}
                    </Col>
                  </Row>
                </Col>
              );
            })}
            {!spaceData ||
            spaceData.subSpaceCluster.rootSpace.childSpaces.length === 0 ? (
              <></>
            ) : (
              <Col className="ps-0">
                <AppDropDown
                  items={spaceData.subSpaceCluster.rootSpace.childSpaces.map(
                    (cs) => {
                      return {
                        text: cs.name,
                        onClick: () => {
                          sendSelectedSpace({
                            ...cs,
                            clusterId: selectedSpace.clusterId,
                          });
                        },
                      };
                    }
                  )}
                  icon="more_horiz"
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col className="col-auto">
          <FilterSearch
            selectedSpace={selectedSpace}
            sendSelectedSpace={sendSelectedSpace}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SpacePathViewBreadcrumb;
