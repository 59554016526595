import ModalContainer, {
  ModelContainerProps,
} from "../../ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  message: string | JSX.Element;
}

const InfoModal = (props: IProps) => {
  const { ...rest } = props;
  return (
    <ModalContainer {...rest} confirmButtonText="Okay" size="modal-md">
      <p style={{ color: "#69768B" }} className="font-size-14 m-0">
        {props.message}
      </p>
    </ModalContainer>
  );
};

export default InfoModal;
