import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
}

const GeneralControllerLinkConfirmModal = (props: IProps) => {
  const { show, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      show={show}
      size="modal-md"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      title="Convert to specific"
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            Selected Generic type Smart Controller will be converted to Specific
            type and will be moved to the space of the linking device if
            necessary.
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default GeneralControllerLinkConfirmModal;
