import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EManagementMode } from "../../../shared/oversight-core/enums/management-mode";
import { ETimeGridYAxisType } from "../../../shared/oversight-core/enums/time-grid-y-axis-type";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import IScheduleFilters from "../../../shared/oversight-core/interfaces/schedule-filters";
import { RootState } from "../../store";

const defaultSelectedSpace: ISpaceView = {
  id: "",
  name: "",
  parentSpaceId: "",
  powerConsumers: [],
  childSpaces: [],
  clusterId: "",
  tariffCode: "",
  accountNumber: "",
  accountNumberLabel: "",
  smartDevices: [],
  powerState: EDeviceStatus.OFF,
};

const defaultScheduleFilters: IScheduleFilters = {
  selectedSpace: { ...defaultSelectedSpace },
  selectedDate: moment().valueOf(),
  selectedSpaceDetails: { spaceClusterId: "", spaceId: "" },
  badgeCount: 0,
  managementMode: { value: EManagementMode.SEMI_AUTO, label: "Semi-Auto" },
  timeGridYAxis: ETimeGridYAxisType.DATE,
};

interface IScheduleFiltersSpaceAndSpaceDetails {
  selectedSpace: ISpaceView;
  selectedSpaceDetails: { spaceClusterId: string; spaceId: string };
}

export const scheduleFilterSlice = createSlice({
  name: "scheduleFilter",
  initialState: { ...defaultScheduleFilters },
  reducers: {
    setScheduleFilter: (state, action: PayloadAction<IScheduleFilters>) => {
      state.selectedSpace = action.payload.selectedSpace;
      state.selectedDate = action.payload.selectedDate;
      state.selectedSpaceDetails = action.payload.selectedSpaceDetails;
      state.badgeCount = action.payload.badgeCount;
      state.managementMode = action.payload.managementMode;
      state.timeGridYAxis = action.payload.timeGridYAxis;
    },
    setScheduleFiltersSpaceAndSpaceDetails: (
      state,
      action: PayloadAction<IScheduleFiltersSpaceAndSpaceDetails>
    ) => {
      state.selectedSpace = action.payload.selectedSpace;
      state.selectedSpaceDetails = action.payload.selectedSpaceDetails;
    },
  },
});

export const { setScheduleFilter, setScheduleFiltersSpaceAndSpaceDetails } =
  scheduleFilterSlice.actions;

export const selectScheduleFilter = (state: RootState) => state.scheduleFilter;

export default scheduleFilterSlice.reducer;
