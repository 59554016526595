import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useSwitchPowerConsumerMutation,
  useSwitchSmartControllerMutation,
} from "../../../../../../redux/api/controller/controllerAPI";
import { useLazyViewDevicesWithAbnormalPowerConsumptionQuery } from "../../../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import AbnormalPowerConsumptionListCard from "../../../../../../shared/components/abnormal-power-consumption-list-card/abnormal-power-consumption-list-card";
import SwitchPowerConsumerPowerStateByIdResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/switch-power-consumer-power-state-by-id-response-dto";
import ViewDevicesWithAbnormalPowerConsumptionResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-devices-with-abnormal-power-consumption-response-dto";
import { EDeviceStatus } from "../../../../../../shared/oversight-core/enums/device-status";
import { OvstErrorCode } from "../../../../../../shared/oversight-core/enums/ovst-error-codes";
import { IAbnormalPowerConsumptionList } from "../../../../../../shared/oversight-core/interfaces/abnormal-power-consumption-list";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import Pagination from "../../../../../../shared/oversight-core/ui-elements/pagination/pagination";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { showSwitchErrorMessage } from "../../../../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showWarningMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IProps extends ModelContainerProps {
  show: boolean;
  setUpdateCurrentState?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ITEM_PER_PAGE = 4;

const AbnormalEnergyUsageModal = (props: IProps) => {
  const { show, setUpdateCurrentState, ...rest } = props;

  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);

  const [abnormalPowerConsumptionList, setAbnormalPowerConsumptionList] =
    useState<IAbnormalPowerConsumptionList[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [filters, setFilters] = useState({
    pageNumber: 0,
  });
  const [
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
    setUpdateCurrentStateDevicesWithAbnormalPowerConsumption,
  ] = useState(false);

  const [
    triggerDevicesWithAbnormalPowerConsumption,
    { isFetching: isFetchingDevicesWithAbnormalPowerConsumption },
  ] = useLazyViewDevicesWithAbnormalPowerConsumptionQuery();

  const [switchSmartPlugState] = useSwitchSmartControllerMutation();
  const [switchPowerConsumerState] = useSwitchPowerConsumerMutation();

  useEffect(() => {
    triggerDevicesWithAbnormalPowerConsumption({
      spaceClusterId: billingSpaceFilter.spaceCluster.id,
      spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
      pageNumber: filters.pageNumber,
      pageSize: ITEM_PER_PAGE,
      ascending: true,
    })
      .unwrap()
      .then((res: ViewDevicesWithAbnormalPowerConsumptionResponseDTO) => {
        setAbnormalPowerConsumptionList(res.abnormalPowerConsumers.elements);
        setTotalElements(res.abnormalPowerConsumers.totalElements);

        if (
          res.abnormalPowerConsumers.elements.length === 0 &&
          filters.pageNumber > 0
        ) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            pageNumber: prevFilters.pageNumber - 1,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    triggerDevicesWithAbnormalPowerConsumption,
    billingSpaceFilter.spaceCluster,
    filters,
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
    show,
  ]);

  const updateControllerStatus = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string,
    switchingPowerState: EDeviceStatus
  ) => {
    switchSmartPlugState({
      spaceClusterId,
      spaceId,
      smartPlugId,
      switchingPowerState,
    })
      .unwrap()
      .then((res) => {
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
        setUpdateCurrentState && setUpdateCurrentState((ps) => !ps);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const updateDeviceStatus = (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus
  ) => {
    switchPowerConsumerState({
      spaceClusterId,
      spaceId,
      powerConsumerId,
      switchingPowerState,
    })
      .unwrap()
      .then((res: SwitchPowerConsumerPowerStateByIdResponseDTO) => {
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
        setUpdateCurrentState && setUpdateCurrentState((ps) => !ps);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  return (
    <ModalContainer
      {...rest}
      show={show}
      size="modal-xl"
      title={"Devices With Abnormal Energy "}
      confirmButtonText="Confirm"
      isButtonsVisible={false}
    >
      <>
        <div>
          <Row className="mb-4">
            <Col className="font-weight-400 font-size-14 text-light">
              Even though devices are turned off energy usage data is being
              receiving by the system please check the devices
            </Col>
          </Row>
          {!isFetchingDevicesWithAbnormalPowerConsumption ? (
            <>
              {abnormalPowerConsumptionList?.map((pcv, index) => {
                return (
                  <Row key={index} className="mt-2">
                    <Col>
                      <AbnormalPowerConsumptionListCard
                        powerConsumerView={pcv}
                        onSwitch={() => {
                          pcv.deviceType === null
                            ? updateControllerStatus(
                                pcv.spaceClusterId,
                                pcv.spaceId,
                                pcv.smartDevices[0].id,
                                EDeviceStatus.ON
                              )
                            : updateDeviceStatus(
                                pcv.spaceClusterId,
                                pcv.spaceId,
                                pcv.id,
                                EDeviceStatus.ON
                              );
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : (
            <SpinnerModal
              withOverlay={false}
              show={isFetchingDevicesWithAbnormalPowerConsumption}
            />
          )}
          <Pagination
            itemsPerPage={ITEM_PER_PAGE}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                pageNumber: pn - 1,
              }));
            }}
          />
        </div>
      </>
    </ModalContainer>
  );
};

export default AbnormalEnergyUsageModal;
