import { useState } from "react";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import FilteringSidePanel from "../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppDateRangeSelector from "../../../shared/oversight-core/ui-elements/app-date-range-selector/app-date-range-selector";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import SpaceSelectorDropdown from "../../../shared/oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../../shared/oversight-core/utils/getDeviceType";

interface IProps {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  spaceClusters: ISpaceView[];
  onFilter: () => void;
}

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const EnergyUsageFilter = (props: IProps) => {
  const { showFilter, spaceClusters, setShowFilter } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedDateType, setSelectedDateType] = useState(EDateTypes.DAILY);

  return (
    <FilteringSidePanel
      isOpen={showFilter}
      onClose={() => {
        setShowFilter(false);
      }}
    >
      <>
        <SpaceSelectorDropdown
          dropdownOptions={[
            { clusterId: "", id: "", name: "All Spaces", childSpaces: [] },
            ...spaceClusters.map((space) => {
              return {
                clusterId: space.clusterId,
                id: space.id,
                name: space.name,
                childSpaces: space.childSpaces,
              };
            }),
          ]}
          disabled={false}
          selectedSubSpace={""}
          updateSelectedSubSpaceId={(
            selectedSubSpaceId: string,
            selectedSubSpaceName: string,
            clusterId?: string
          ) => {
            console.log(selectedSubSpaceId, selectedSubSpaceName, clusterId);
          }}
          label="Space"
          fontSize={16}
        />

        <AppSelect
          label="Device Type"
          options={[...deviceTypes]}
          className="mt-4"
          selectedValue={deviceTypes[0]}
          onChangeOption={(selectedDeviceTypeOption) => {
            console.log(selectedDeviceTypeOption);
          }}
          fontSize="12px"
          labelFontSize="font-size-14"
          fontWeight="500"
          fontColor="#69768b"
        />

        <AppSelect
          label="Device"
          options={[]}
          className="mt-4"
          fontSize="12px"
          labelFontSize="font-size-14"
          fontWeight="500"
          fontColor="#69768b"
        />

        <div className="mt-4">
          <AppDateRangeSelector
            startDate={startDate}
            endDate={endDate}
            selectedDateType={selectedDateType}
            setSelectedDateType={setSelectedDateType}
            setSelectedDate={(startDate: Date, endDate: Date) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
        </div>

        <AppSelect
          label="Sort By"
          options={[]}
          className="mt-4"
          fontSize="12px"
          labelFontSize="font-size-14"
          fontWeight="500"
          fontColor="#69768b"
        />
      </>
    </FilteringSidePanel>
  );
};

export default EnergyUsageFilter;
