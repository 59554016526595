import { Col, Row } from "react-bootstrap";
import { EDeviceStatus } from "../../enums/device-status";
import styles from "./bulk-app-toggle.module.scss";
interface IProps {
  status: EDeviceStatus;
  onSwitch?: (deviceStatus: EDeviceStatus) => void;
  isDisable?: boolean;
}

function BulkAppToggle(props: IProps) {
  const { status, isDisable, onSwitch } = props;

  return (
    <Row
      className={`align-items-center p-1 ${styles.bulkAppToggle} ${
        isDisable ? `cursor-disabled` : `cursor-pointer`
      }`}
    >
      <Col
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();

          if (status !== EDeviceStatus.OFF && onSwitch) {
            onSwitch(EDeviceStatus.OFF);
          }
        }}
        className={`${status === EDeviceStatus.OFF ? styles.gray : ""} ${
          styles.button
        } ${
          status === EDeviceStatus.BOTH ? "text-gray" : "text-white"
        } font-size-12 font-weight-500 px-2 py-1`}
      >
        Off
      </Col>
      {status === EDeviceStatus.BOTH && (
        <Col
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`${status === EDeviceStatus.BOTH ? styles.green : ""} ${
              styles.both
            } ${styles.button}`}
          ></div>
        </Col>
      )}
      <Col
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          if (status !== EDeviceStatus.ON && onSwitch) {
            onSwitch(EDeviceStatus.ON);
          }
        }}
        className={`${status === EDeviceStatus.ON ? styles.green : ""} ${
          styles.button
        } ${
          status === EDeviceStatus.BOTH ? "text-gray" : "text-white"
        } font-size-12 font-weight-500 px-2 py-1`}
      >
        On
      </Col>
    </Row>
  );
}

export default BulkAppToggle;
