import { useState } from "react";
import { EViewTimeUnit } from "../../../../../shared/oversight-core/enums/view-time-unit";
import FilteringSidePanel from "../../../../../shared/oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";

interface IProps {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  onFilter: (viewTimeUnit: string) => void;
}

const timeUnits: Option[] = [
  {
    label: "Last 10 minutes",
    value: "",
  },
  {
    label: "Last 60 minutes",
    value: EViewTimeUnit.MINUTE,
  },
  {
    label: "Last 24 hours",
    value: EViewTimeUnit.HOUR,
  },
  {
    label: "Last 7 days",
    value: EViewTimeUnit.DAY,
  },
];

const ManagementFilter = (props: IProps) => {
  const { showFilter, setShowFilter, onFilter } = props;

  const [selectedTimeUnit, setSelectedTimeUnit] = useState({ ...timeUnits[0] });

  return (
    <FilteringSidePanel
      isOpen={showFilter}
      onClose={() => {
        setShowFilter(false);
      }}
      onFilter={() => onFilter(selectedTimeUnit.value as EViewTimeUnit)}
      onClearAll={() => {
        onFilter("");
        setSelectedTimeUnit({ ...timeUnits[0] });
        setShowFilter(false);
      }}
    >
      <AppSelect
        label="Time Range"
        options={[...timeUnits]}
        className="mt-4"
        selectedValue={selectedTimeUnit}
        onChangeOption={(selectOption) => {
          setSelectedTimeUnit(selectOption);
        }}
        fontSize="12px"
        labelFontSize="font-size-14"
        fontWeight="500"
        fontColor="#69768b"
      />
    </FilteringSidePanel>
  );
};

export default ManagementFilter;
