import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import SelectedFiltersView from "../../../shared/components/selected-filters-view/selected-filters-view";
import { EConsumptionViewTypes } from "../../../shared/oversight-core/enums/consumption-view-types";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { EUsageDataFrom } from "../../../shared/oversight-core/enums/usage-data-from";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import AppOptions from "../../../shared/oversight-core/ui-elements/app-options/app-options";
import AppTabs from "../../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import ChartOptionsModal from "../../../shared/oversight-core/ui-elements/chart-options-modal/chart-options-modal";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsView from "../../../shared/oversight-core/ui-elements/stats-view/stats-view";
import AddUpdateBillDetailsModal from "./components/modals/add-update-bill-details-modal/add-update-bill-details-modal";
import EnergyUsageFilter from "./energy-usage-filter";
import styles from "./energy-usage.module.scss";

const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const predictedConsumption = [
  1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
];
const actualConsumption = predictedConsumption.slice().reverse();

const EnergyUsage = () => {
  const [hasActualConsumption] = useState(true);
  const [isChartFiltered] = useState(true);
  const [showChartOptions, setShowChartOptions] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate] = useState(new Date());
  const [showAddUpdateBillDetailsModal, setShowAddUpdateBillDetailsModal] =
    useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);

  const [triggerGetSpaces, { isFetching: loadingSpaces }] =
    useLazyGetSpacesQuery();

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        const tempArray: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        setSpaceClusters(tempArray);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  return (
    <>
      <div className="container-white">
        <Row className="align-items-center justify-content-between">
          <Col className="col-12 col-xxl">
            <Row className="align-items-center justify-content-center justify-content-sm-start g-3">
              <Col className="col-auto text-center text-sm-start">
                <SelectedFiltersView
                  spaceName="Olivera's House"
                  filters={[
                    {
                      icon: EDeviceTypes.MICROWAVE_OVEN,
                      filterName: "New\u00A0Microwave",
                      date: moment(new Date()).format("MMM YYYY"),
                    },
                  ]}
                />
              </Col>
              <Col className="col-auto">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    setShowFilter(true);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-xxl-auto mt-3 mt-xxl-0 d-none d-sm-block">
            <Row className="align-items-center">
              <Col>
                <StatsView
                  labelValue={`${Number(75).toFixed(2)} Units | Rs. ${Number(
                    4500
                  ).toFixed(2)}`}
                  title="Usage Prediction"
                  variant="bg-icon-5"
                  fontStyle="small"
                  columnClass="col-3"
                  fontSize={10}
                />
              </Col>
              {hasActualConsumption && (
                <Col>
                  <StatsView
                    labelValue={`${Number(75).toFixed(2)} Units | Rs. ${Number(
                      4500
                    ).toFixed(2)}`}
                    title="Actual Usage"
                    variant="bg-icon-6"
                    fontStyle="small"
                    columnClass="col-3"
                    fontSize={10}
                  />
                </Col>
              )}
              {hasActualConsumption ? (
                <Col className="col-auto ps-0">
                  <AppButton
                    text="Edit"
                    iconName="drive_file_rename_outline"
                    iconOnly
                    size="medium"
                    onClick={() => {
                      setShowAddUpdateBillDetailsModal(true);
                    }}
                  />
                </Col>
              ) : (
                <Col className="col-12 col-md-auto mt-2 mt-md-0">
                  <AppButton
                    text="Input Usage"
                    size="medium"
                    onClick={() => {
                      setShowAddUpdateBillDetailsModal(true);
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-7 d-block d-sm-none ">
            <StatsView
              labelValue={`${Number(75).toFixed(2)} Units | Rs. ${Number(
                4500
              ).toFixed(2)}`}
              title="Usage Prediction"
              variant="bg-icon-5"
              fontStyle="small"
              columnClass="col-3"
              fontSize={10}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between mt-2">
          {hasActualConsumption && (
            <Col className="col-7 d-block d-sm-none">
              <StatsView
                labelValue={`${Number(75).toFixed(2)} Units | Rs. ${Number(
                  4500
                ).toFixed(2)}`}
                title="Actual Usage"
                variant="bg-icon-6"
                fontStyle="small"
                columnClass="col-3"
                fontSize={10}
              />
            </Col>
          )}
          {hasActualConsumption ? (
            <Col className="col-auto d-block d-sm-none">
              <AppButton
                text="Edit"
                iconName="drive_file_rename_outline"
                iconOnly
                size="medium"
                onClick={() => {
                  setShowAddUpdateBillDetailsModal(true);
                }}
              />
            </Col>
          ) : (
            <Col className="col-12 d-block d-sm-none mt-2">
              <AppButton
                text="Input Usage"
                size="medium"
                onClick={() => {
                  setShowAddUpdateBillDetailsModal(true);
                }}
              />
            </Col>
          )}
        </Row>
      </div>
      <div className="container-white mt-4">
        <Row className="align-items-center gap-0 gap-xl-3">
          <Col>
            <Row className="align-items-center justify-content-center justify-content-sm-start justify-content-lg-end mx-0">
              <Col className="col-auto ps-lg-0">
                <AppOptions
                  isFiltersApplied={isChartFiltered}
                  onClick={() => {
                    setShowChartOptions(true);
                  }}
                />
              </Col>
              <Col className="col-auto ps-1 pe-0 ps-sm-4">
                <ButtonWithBadge
                  text="Multiple View"
                  icon="filter_none"
                  onClick={() => {
                    console.log();
                  }}
                />
              </Col>
              <Col className={`${styles.appButton} col-auto ps-0 ps-sm-4`}>
                <AppTabs
                  buttons={[
                    {
                      buttonName: "Units",
                      selectedColor: "purpleColor",
                      id: 1,
                    },
                    {
                      buttonName: "Bill",
                      selectedColor: "purpleColor",
                      id: 2,
                    },
                  ]}
                  onChange={(id: number) => {
                    setSelectedButton(id);
                  }}
                  selectedTabId={selectedButton}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-lg-auto mt-4 mt-lg-0">
            <Row className="align-items-center justify-content-center justify-content-sm-start g-3">
              <Col className="col-auto text-center text-sm-start">
                <SelectedFiltersView
                  spaceName="Olivera's House"
                  filters={[
                    {
                      icon: EDeviceTypes.MICROWAVE_OVEN,
                      filterName: "New\u00A0Microwave",
                      date: moment(new Date()).format("MMM YYYY"),
                    },
                  ]}
                />
              </Col>
              <Col className="col-auto">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    console.log();
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <AreaChart
              borderColor1={"#EEE741"}
              borderColor2={"#D84560"}
              backgroundColor1={"#F5FFCD4D"}
              backgroundColor2={"#CC292917"}
              label1={"Usage Prediction"}
              label2={"Actual Usage"}
              yAxesUnit={selectedButton === 1 ? "Units" : "Rs."}
              labels={monthsList}
              data1={predictedConsumption}
              data2={actualConsumption}
              hasSecondDataSet={true}
            />
          </Col>
        </Row>
      </div>
      <ChartOptionsModal
        show={showChartOptions}
        onClose={() => {
          setShowChartOptions(false);
        }}
        onCancel={() => {
          setShowChartOptions(false);
        }}
        onConfirm={() => {
          setShowChartOptions(false);
        }}
        updateViewConsumptionAs={(viewConsumptionAs: EConsumptionViewTypes) => {
          console.log(viewConsumptionAs);
        }}
      />
      <AddUpdateBillDetailsModal
        closeAddUpdateBillDetailsDataModal={() => {
          setShowAddUpdateBillDetailsModal(false);
        }}
        onClose={() => setShowAddUpdateBillDetailsModal(false)}
        onCancel={() => setShowAddUpdateBillDetailsModal(false)}
        show={showAddUpdateBillDetailsModal}
        month={selectedDate}
        year={format(selectedDate, "yyyy")}
        rootSpaceId={""}
        rootSpaceName={""}
        actualConsumption={{
          consumedEnergySummary: {
            energyInUnits: 100,
            energyBill: 1000,
          },
          manualScheduleEnergySummary: {
            energyInUnits: 200,
            energyBill: 2000,
          },
          semiAutomatedScheduleEnergySummary: {
            energyInUnits: 200,
            energyBill: 2000,
          },
          consumedUsageDataFrom: EUsageDataFrom.TELEMETRY,
        }}
        updateActualConsumption={() => {
          console.log();
        }}
        isEditMode={true}
        accountNumber={""}
      />
      <EnergyUsageFilter
        showFilter={showFilter}
        setShowFilter={(value: boolean) => {
          setShowFilter(value);
        }}
        spaceClusters={spaceClusters}
        onFilter={() => {
          console.log();
        }}
      />
      <SpinnerModal show={loadingSpaces} />
    </>
  );
};

export default EnergyUsage;
