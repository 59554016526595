import ReactGA from "react-ga4";
import "./App.scss";
import AppRoutes from "./screens/routes";
import "./shared/oversight-core/bootstrap/bootstrap.scss";
ReactGA.initialize("G-TWHCJ9L65L");

function App() {
  return <AppRoutes />;
}

export default App;
