import { skipToken } from "@reduxjs/toolkit/query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery,
  useViewWeeklyManualSchedulesQuery,
  useViewWeeklySemiAutomationSchedulesQuery,
} from "../../../redux/api/schedule/scheduleAPI";
import {
  useLazyGetSpaceQuery,
  useLazyGetSpacesQuery,
} from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectScheduleFilter,
  setScheduleFilter,
  setScheduleFiltersSpaceAndSpaceDetails,
} from "../../../redux/features/schedule-filter/schedule-filter-slice";
import SpacePathViewBreadcrumb from "../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import SubSpaceClusterViewResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/sub-space-cluster-view-response-dto";
import {
  default as IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
  default as ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedule-monthly-energy-for-sub-space-cluster-response-dto";
import ViewWeeklyManualSchedulesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-manual-schedules-response-dto";
import ViewWeeklySemiAutomationSchedulesResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-semi-automation-schedules-response-dto";
import { EManagementMode } from "../../../shared/oversight-core/enums/management-mode";
import { ETimeGridYAxisType } from "../../../shared/oversight-core/enums/time-grid-y-axis-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import { IScheduledShallowView } from "../../../shared/oversight-core/interfaces/scheduled-shallow-view";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../../shared/oversight-core/shared-components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../../shared/oversight-core/shared-components/calendar/useTimeGrid";
import AppBannerWithIcon from "../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import { Option } from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import NavigateWeeks from "../../../shared/oversight-core/ui-elements/navigate-weeks/navigate-weeks";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import StatsViewTypeTwo from "../../../shared/oversight-core/ui-elements/stats-view/stats-view-type-two";
import {
  getMonthRangeToDisplay,
  getWeekRange,
} from "../../../shared/oversight-core/utils/date-utils";
import Helper from "../../../shared/oversight-core/utils/helpers";
import { offsetToString } from "../../../shared/oversight-core/utils/offsetToString";
import { scrollToView } from "../../../shared/oversight-core/utils/scroll-to-view";
import { IAddScheduleProps } from "./components/add-update-schedule/add-update-schedule";
import MiniCalendar from "./components/mini-calendar/mini-calendar";
import CreateModal from "./components/modals/create-modal/create-modal";
import ScheduleDeleteModal from "./components/modals/schedule-delete-modal/schedule-delete-modal";
import ScheduleLogViewContainer from "./components/schedule-log-view-container/schedule-log-view-container";
import ScheduleTabView from "./components/schedule-tab-view/schedule-tab-view";
import styles from "./schedule.module.scss";

type ColumnDataType = Date | string; // x-axis can be Date or Device ID

const navigateToScheduleAddEditPage = (
  selectedDate: Date | undefined,
  event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
  yAxis: ETimeGridYAxisType,
  spaceClusterId: string,
  subRootSpaceId: string,
  managementMode: EManagementMode
) => {
  if (managementMode === EManagementMode.SEMI_AUTO) {
    if (event.data?.id) {
      ReactGA.event({
        category: "Click",
        action: "Semi Auto Schedule Add Click",
      });
    } else {
      ReactGA.event({
        category: "Click",
        action: "Semi Auto Schedule Edit Click",
      });
    }
  }
  const dateToUse =
    yAxis === ETimeGridYAxisType.DATE ? event.columnData : selectedDate;
  const initialIsoTimeList =
    !event.data?.id && dateToUse
      ? [
          {
            fromDate: moment(dateToUse)
              .startOf("day")
              .add(event.startTime, "minute")
              .toISOString(),
            toDate: moment(dateToUse)
              .startOf("day")
              .add(event.endTime, "minute")
              .toISOString(),
          },
        ]
      : undefined;

  const initialDeviceIds =
    !event.data?.id && event.columnData && yAxis === ETimeGridYAxisType.DEVICE
      ? [event.columnData.toString()]
      : event.data
      ? event.data.powerConsumers.map((pc) => pc.id)
      : undefined;

  const addScheduleState: IAddScheduleProps = {
    scheduleId: event.data?.id,
    spaceClusterId,
    subRootSpaceId,
    navigateLocation: [-1],
    initialIsoTimeList,
    initialDeviceIds,
    managementMode,
  };
  const routePath = event.data?.id
    ? AppRoute.EDIT_SCHEDULE
    : AppRoute.ADD_SCHEDULE;
  return {
    routePath,
    addScheduleState,
  };
};

const defaultScheduleMonthlyEnergyForSubSpaceCluster: IViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO =
  {
    spaceClusterEnergy: {
      energyBill: 0,
      energyInUnits: 0,
    },
    spaceClusterAverageWeeklyEnergy: {
      weekdayDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weekendDailyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
      weeklyEnergy: {
        energyBill: 0,
        energyInUnits: 0,
      },
    },
  };

const Schedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scheduleFilterStore = useSelector(selectScheduleFilter);
  const billingSpaceFilterStore = useSelector(selectBillingSpaceFilter);
  const [noDevices, setNoDevices] = useState(false);
  const [noSmartDevices, setNoSmartDevices] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteScheduleData, setDeleteScheduleData] = useState({
    scheduleId: "",
    scheduleTitle: "",
  });
  const [isScheduleDeleted, setIsScheduleDeleted] = useState(false);
  const [deviceHeaders, setDeviceHeaders] = useState<
    { deviceName: string; deviceId: string }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date(scheduleFilterStore.selectedDate)
  );
  const [selectedManagementMode, setSelectedManagementMode] = useState<Option>(
    scheduleFilterStore.managementMode
  );
  const [events, setEvents] = useState<
    DataColumn<ColumnDataType, IScheduledShallowView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), scheduleFilterStore.selectedDate)
  );
  const [
    scheduleMonthlyEnergyForSubSpaceCluster,
    setScheduleMonthlyEnergyForSubSpaceCluster,
  ] =
    useState<ViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterResponseDTO>(
      { ...defaultScheduleMonthlyEnergyForSubSpaceCluster }
    );
  const [loading, setIsLoading] = useState(true);
  const [weeklyManualScheduleEstimation, setWeeklyManualScheduleEstimation] =
    useState<ViewWeeklyManualSchedulesResponseDTO>();
  const [
    weeklySemiAutoScheduleEstimation,
    setWeeklySemiAutoScheduleEstimation,
  ] = useState<ViewWeeklySemiAutomationSchedulesResponseDTO>();
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [showManualInstruction, setShowManualInstruction] = useState(true);
  const [showSemiAutoInstruction, setShowSemiAutoInstruction] = useState(true);
  const [showScheduleLogView, setShowScheduleLogView] = useState(false);
  const [isGoToCurrentTime, setIsGoToCurrentTime] = useState(false);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceCluster }] =
    useLazyGetSpacesQuery();
  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceQuery();
  const [
    triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewSemiAutomationScheduleMonthlyEnergy },
  ] = useLazyViewSemiAutomationScheduleMonthlyEnergyForSubSpaceClusterQuery();
  const [
    triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster,
    { isFetching: isFetchingViewManualScheduleMonthlyEnergy },
  ] = useLazyViewManualScheduleMonthlyEnergyForSubSpaceClusterQuery();

  // Build grid headers. This can be Dates of week or Devices
  const headers: Header<ColumnDataType>[] = [];

  switch (scheduleFilterStore.timeGridYAxis) {
    case ETimeGridYAxisType.DATE:
      for (let cIndex = 0; cIndex < 7; cIndex++) {
        headers.push({
          columnId: moment(weekRange[cIndex]).toDate(),
          title: moment(weekRange[cIndex]).format("DD"),
          value: (
            <div className="d-flex flex-column">
              <div>{moment(weekRange[cIndex]).format("ddd")}</div>
              <div
                className={`${
                  moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? `today-grid-style`
                    : ``
                } px-1`}
              >
                {moment(weekRange[cIndex]).format("DD")}
              </div>
            </div>
          ),
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
    case ETimeGridYAxisType.DEVICE:
      for (let cIndex = 0; cIndex < deviceHeaders.length; cIndex++) {
        headers.push({
          columnId: deviceHeaders[cIndex].deviceId,
          title: deviceHeaders[cIndex].deviceName,
          value: <div>{deviceHeaders[cIndex].deviceName}</div>,
          column: cIndex + 1, // +1 for row header
          header: true,
          row: 0,
        });
      }
      break;
  }

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  useEffect(() => {
    if (
      scheduleFilterStore.selectedSpaceDetails.spaceClusterId &&
      scheduleFilterStore.selectedSpaceDetails.spaceId
    ) {
      triggerGetSpace({
        clusterId: scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
        spaceId: scheduleFilterStore.selectedSpaceDetails.spaceId,
      })
        .unwrap()
        .then((response: SubSpaceClusterViewResponseDTO) => {
          setNoDevices(
            response.subSpaceCluster.rootSpace.powerConsumers.length < 1
          );
          setNoSmartDevices(
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            ).length === 0
          );
          const specificSmartDevices =
            response.subSpaceCluster.rootSpace.smartDevices.filter(
              (smartDevice) => smartDevice.linkedPowerConsumer
            );
          const devices = response.subSpaceCluster.rootSpace.powerConsumers;

          const deviceHeaders: { deviceName: string; deviceId: string }[] = [];

          if (selectedManagementMode.value === EManagementMode.SEMI_AUTO) {
            specificSmartDevices.forEach((smartDevice) => {
              const matchingPowerConsumer =
                response.subSpaceCluster.rootSpace.powerConsumers.find(
                  (powerConsumer) =>
                    powerConsumer.id === smartDevice.linkedPowerConsumer?.id
                );

              if (matchingPowerConsumer) {
                const isDeviceAlreadyExist = deviceHeaders.find(
                  (device) => device.deviceId === matchingPowerConsumer.id
                );
                if (!isDeviceAlreadyExist) {
                  deviceHeaders.push({
                    deviceName: matchingPowerConsumer.name,
                    deviceId: matchingPowerConsumer.id,
                  });
                }
              }
            });
          } else {
            devices.forEach((device) => {
              deviceHeaders.push({
                deviceName: device.name,
                deviceId: device.id,
              });
            });
          }

          setDeviceHeaders(deviceHeaders);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    scheduleFilterStore.timeGridYAxis,
    scheduleFilterStore.selectedSpaceDetails,
    selectedManagementMode.value,
  ]);

  let isCurrentWeek = false;
  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const onCurrentTimeIndicatorRender = (elemId: string) => {
    if (isGoToCurrentTime) {
      scrollToView(elemId);
      setIsGoToCurrentTime(false);
    }
  };

  const isShowTimeBar = isCurrentWeek;

  const { calendarContent } = useTimeGrid<
    ColumnDataType,
    IScheduledShallowView
  >(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        onCurrentTimeIndicatorRender,
        isDeviceView:
          scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE,
        viewEventModalTemplate: (events, onClose) => {
          return (
            <CreateModal<ColumnDataType>
              id=""
              events={events}
              onEditClick={(event) => {
                const { routePath, addScheduleState } =
                  navigateToScheduleAddEditPage(
                    new Date(scheduleFilterStore.selectedDate),
                    event,
                    scheduleFilterStore.timeGridYAxis,
                    scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
                    event.data?.rootSpaceId || "",
                    scheduleFilterStore.managementMode.value
                  );
                navigate(routePath, {
                  state: addScheduleState,
                });
              }}
              onDelete={(event) => {
                onClose();
                setDeleteScheduleData({
                  scheduleId: event.data?.id || "",
                  scheduleTitle: event.data?.title || "",
                });
                setShowDeleteModal(true);
              }}
              onClose={onClose}
            />
          );
        },
        onEventChanged: (event) => {
          const { routePath, addScheduleState } = navigateToScheduleAddEditPage(
            new Date(scheduleFilterStore.selectedDate),
            event,
            scheduleFilterStore.timeGridYAxis,
            scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
            scheduleFilterStore.selectedSpaceDetails.spaceId,
            scheduleFilterStore.managementMode.value
          );
          navigate(routePath, {
            state: addScheduleState,
          });
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<ColumnDataType, IScheduledShallowView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                <Col
                  className={`${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>
              {event.data?.isDraft && (
                <Row>
                  <Col
                    className={`${
                      isGrouped ? `font-size-9` : `font-size-10`
                    } font-weight-400 text-white`}
                  >
                    (Draft)
                  </Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col className="font-size-9 font-weight-500">
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Events
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [
      headers,
      scheduleFilterStore.selectedSpaceDetails,
      events,
      scheduleFilterStore.timeGridYAxis,
      isShowTimeBar,
    ])
  );

  const startDate = weekRange[0];
  const validForRequest =
    scheduleFilterStore.managementMode.value &&
    scheduleFilterStore.selectedSpaceDetails.spaceClusterId &&
    scheduleFilterStore.selectedSpaceDetails.spaceId &&
    ((deviceHeaders.length > 0 &&
      scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE) ||
      scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DATE);
  const {
    data: semiAutomationScheduleResponse,
    isFetching: isFetchingViewWeeklySemiAutomationSchedules,
  } = useViewWeeklySemiAutomationSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId:
            scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
          subRootSpaceId: scheduleFilterStore.selectedSpaceDetails.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
        }
      : skipToken
  );
  const {
    data: manualScheduleResponse,
    isFetching: isFetchingViewWeeklyManualSchedules,
  } = useViewWeeklyManualSchedulesQuery(
    validForRequest
      ? {
          spaceClusterId:
            scheduleFilterStore.selectedSpaceDetails.spaceClusterId,
          subRootSpaceId: scheduleFilterStore.selectedSpaceDetails.spaceId,
          startDate: moment(startDate).startOf("day").toISOString(true),
        }
      : skipToken
  );

  useEffect(() => {
    if (manualScheduleResponse || semiAutomationScheduleResponse) {
      setWeeklyManualScheduleEstimation(manualScheduleResponse);
      setWeeklySemiAutoScheduleEstimation(semiAutomationScheduleResponse);
    }
  }, [manualScheduleResponse, semiAutomationScheduleResponse]);

  useEffect(() => {
    if (
      validForRequest &&
      (scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO
        ? semiAutomationScheduleResponse
        : manualScheduleResponse)
    ) {
      setEvents([]);
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<ColumnDataType, IScheduledShallowView>
      > = {};
      (scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO
        ? semiAutomationScheduleResponse?.shallowSemiAutomatedScheduleViews
        : manualScheduleResponse?.shallowScheduleViews
      )?.forEach((sp: IScheduledShallowView) => {
        switch (scheduleFilterStore.timeGridYAxis) {
          case ETimeGridYAxisType.DATE:
            sp.scheduledPeriods.forEach((s) => {
              const fromDate = moment(new Date(s.fromDate));
              const toDate = moment(new Date(s.toDate));

              const day = fromDate.day();
              const columnId = fromDate.toDate().toISOString();
              const columnData = fromDate.toDate();
              const generated = {
                title: sp.title,
                columnData,
                startTime: fromDate
                  .clone()
                  .diff(fromDate.clone().startOf("day"), "minutes"),
                endTime: toDate
                  .clone()
                  .diff(toDate.clone().startOf("day"), "minutes"),
                date: fromDate.toDate(),
                data: sp,
              };

              if (generated) {
                // need to collect the event since event is generated
                if (!groupByDateOrDevId[day]) {
                  // create a column if not exist
                  groupByDateOrDevId[day] = {
                    columnId,
                    columnData,
                    columnIndex: sundayStartToMondayStart(day),
                    events: [],
                  };
                }
                // then collect the event
                groupByDateOrDevId[day].events.push(generated);
              }
            });
            break;
          case ETimeGridYAxisType.DEVICE:
            sp.scheduledPeriods.forEach((s) => {
              sp.powerConsumers.forEach((device) => {
                const fromDate = moment(new Date(s.fromDate));
                const toDate = moment(new Date(s.toDate));
                const columnIndex = deviceHeaders.findIndex(
                  (deviceHeader) => device.id === deviceHeader.deviceId
                );
                if (columnIndex > -1) {
                  const matchedDeviceHeader = deviceHeaders[columnIndex];
                  const columnId = matchedDeviceHeader.deviceId;
                  const columnData = matchedDeviceHeader.deviceId;
                  const generated = {
                    title: sp.title,
                    columnData,
                    startTime: fromDate
                      .clone()
                      .diff(fromDate.clone().startOf("day"), "minutes"),
                    endTime: toDate
                      .clone()
                      .diff(toDate.clone().startOf("day"), "minutes"),
                    date: fromDate.toDate(),
                    data: sp,
                  };

                  if (
                    generated &&
                    moment(s.fromDate)
                      .startOf("day")
                      .isSame(
                        moment(scheduleFilterStore.selectedDate).startOf("day")
                      )
                  ) {
                    // need to collect the event since event is generated
                    if (!groupByDateOrDevId[matchedDeviceHeader.deviceId]) {
                      // create a column if not exist
                      groupByDateOrDevId[matchedDeviceHeader.deviceId] = {
                        columnId,
                        columnData,
                        columnIndex,
                        events: [],
                      };
                    }
                    // then collect the event
                    groupByDateOrDevId[
                      matchedDeviceHeader.deviceId
                    ].events.push(generated);
                  }
                }
              });
            });
            break;
        }
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [
    semiAutomationScheduleResponse,
    manualScheduleResponse,
    validForRequest,
    scheduleFilterStore.selectedDate,
    startDate,
    isScheduleDeleted,
    scheduleFilterStore.timeGridYAxis,
    scheduleFilterStore.managementMode.value,
    deviceHeaders,
  ]);

  const onFilter = (
    selectedSpace: ISpaceView,
    date: Date,
    managementMode: { value: EManagementMode; label: string },
    timeGridYAxis?: ETimeGridYAxisType
  ) => {
    const filterSelectedSpace = scheduleFilterStore.selectedSpace;

    const isSelectedSpaceEqual =
      filterSelectedSpace.clusterId === selectedSpace.clusterId &&
      filterSelectedSpace.id === selectedSpace.id;

    dispatch(
      setScheduleFilter({
        ...scheduleFilterStore,
        selectedSpace,
        selectedDate: moment(date).valueOf(),
        selectedSpaceDetails: !isSelectedSpaceEqual
          ? {
              spaceClusterId: selectedSpace.clusterId,
              spaceId: selectedSpace.id,
            }
          : scheduleFilterStore.selectedSpaceDetails,
        managementMode: managementMode,
        timeGridYAxis: timeGridYAxis || scheduleFilterStore.timeGridYAxis,
      })
    );
  };

  useEffect(() => {
    if (selectedManagementMode || selectedDate) {
      onFilter(scheduleFilterStore.selectedSpace, selectedDate, {
        value: selectedManagementMode.value as EManagementMode,
        label: selectedManagementMode.label as string,
      });
    }
  }, [selectedManagementMode, selectedDate]);

  useEffect(() => {
    if (
      !showScheduleLogView &&
      billingSpaceFilterStore.spaceCluster.id &&
      billingSpaceFilterStore.spaceCluster.rootSpace.id
    ) {
      (scheduleFilterStore.managementMode.value === EManagementMode.SEMI_AUTO
        ? triggerViewSemiAutomationScheduleMonthlyEnergyForSubSpaceCluster
        : triggerViewManualScheduleMonthlyEnergyForSubSpaceCluster)({
        spaceClusterId: billingSpaceFilterStore.spaceCluster.id,
        subRootSpaceId: billingSpaceFilterStore.spaceCluster.rootSpace.id,
        year: +moment(scheduleFilterStore.selectedDate).format("yy"),
        month: +moment(scheduleFilterStore.selectedDate).format("MM"),
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res) => {
          setScheduleMonthlyEnergyForSubSpaceCluster(res);
          setIsLoading(true);
        })
        .catch(() => {
          setScheduleMonthlyEnergyForSubSpaceCluster({
            ...defaultScheduleMonthlyEnergyForSubSpaceCluster,
          });
        });
    }
  }, [
    billingSpaceFilterStore.spaceCluster.id,
    billingSpaceFilterStore.spaceCluster.rootSpace.id,
    scheduleFilterStore.selectedDate,
    scheduleFilterStore.managementMode.value,
    isScheduleDeleted,
    showScheduleLogView,
  ]);

  useEffect(() => {
    setWeekRange(
      getWeekRange(
        new Date(scheduleFilterStore.selectedDate),
        scheduleFilterStore.selectedDate
      )
    );
  }, [scheduleFilterStore.selectedDate]);

  const sendSelectedSpace = (space: ISpaceView) => {
    dispatch(
      setScheduleFiltersSpaceAndSpaceDetails({
        selectedSpace: space,
        selectedSpaceDetails: {
          spaceClusterId: space.clusterId,
          spaceId: space.id,
        },
      })
    );
  };

  const onClickThisWeekButton = () => {
    const currentDate = new Date();
    const selectedDate = currentDate.getTime();
    const firstDayOfWeek = getWeekRange(currentDate, selectedDate)[0];
    setSelectedDate(firstDayOfWeek);
  };

  const weeklyScheduleEstimation = (
    <StatsViewTypeTwo
      variant="bg-icon-2"
      labelValue={
        scheduleFilterStore.managementMode.value === EManagementMode.MANUAL
          ? Helper.roundTo2(
              weeklyManualScheduleEstimation?.manualScheduleEnergy?.unit || 0
            ) + ""
          : Helper.roundTo2(
              weeklySemiAutoScheduleEstimation?.semiAutomatedScheduleEnergy
                ?.unit || 0
            ) + ""
      }
      title="ESTIMATED UNITS"
      isFetching={
        isFetchingViewWeeklyManualSchedules ||
        isFetchingViewWeeklySemiAutomationSchedules
      }
    />
  );

  const weeklySchedulePercentage = (
    <StatsViewTypeTwo
      variant="bg-icon-2"
      labelValue={
        scheduleFilterStore.managementMode.value === EManagementMode.MANUAL
          ? Helper.roundTo2(
              weeklyManualScheduleEstimation?.manualSchedulePercentage || 0
            ) + "%"
          : Helper.roundTo2(
              weeklySemiAutoScheduleEstimation?.semiAutomatedSchedulePercentage ||
                0
            ) + "%"
      }
      title="OF TOTAL UNITS"
      isFetching={
        isFetchingViewWeeklyManualSchedules ||
        isFetchingViewWeeklySemiAutomationSchedules
      }
      infoText="Percentage compared to the Space Cluster"
    />
  );

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 ? (
        <>
          <div className="container-white">
            <ScheduleTabView
              selectedDate={new Date(scheduleFilterStore.selectedDate)}
              isFetching={
                (isFetchingViewSemiAutomationScheduleMonthlyEnergy &&
                  loading) ||
                (isFetchingViewManualScheduleMonthlyEnergy && loading)
              }
              scheduledSpaceClusterAverageWeeklyEnergy={
                scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterAverageWeeklyEnergy
              }
              spaceClusterEstimatedConsumption={
                scheduleMonthlyEnergyForSubSpaceCluster.spaceClusterEnergy
              }
              selectedManagementMode={selectedManagementMode}
              setSelectedDate={setSelectedDate}
              setSelectedManagementMode={setSelectedManagementMode}
            />
          </div>
          {((selectedManagementMode.value === EManagementMode.MANUAL &&
            showManualInstruction) ||
            (selectedManagementMode.value === EManagementMode.SEMI_AUTO &&
              showSemiAutoInstruction)) && (
            <Row className={`mt-3 mx-0`}>
              <AppBannerWithIcon
                content={
                  selectedManagementMode.value === EManagementMode.MANUAL
                    ? `Manual mode data is only used to create a schedule of device
                  usage, because devices in manual mode do not turn on
                  automatically `
                    : `Once a semi-auto schedule is added, devices will be controlled
                  automatically according to the schedule`
                }
                bannerVariant="yellow"
                icon="live_help"
                iconVariant="#CBCD68"
                button={
                  <div
                    className="instruction-close bg-primary text-white cursor-pointer"
                    onClick={() => {
                      if (
                        selectedManagementMode.value === EManagementMode.MANUAL
                      ) {
                        setShowManualInstruction(false);
                        return;
                      }
                      if (
                        selectedManagementMode.value ===
                        EManagementMode.SEMI_AUTO
                      ) {
                        setShowSemiAutoInstruction(false);
                        return;
                      }
                    }}
                  >
                    x
                  </div>
                }
              />
            </Row>
          )}
          <div
            className={`${
              (selectedManagementMode.value === EManagementMode.MANUAL &&
                showManualInstruction) ||
              (selectedManagementMode.value === EManagementMode.SEMI_AUTO &&
                showSemiAutoInstruction)
                ? `grid-overflow`
                : `grid-overflow-second`
            } container-white position-relative mt-3`}
          >
            <Row>
              <Col>
                <SpacePathViewBreadcrumb
                  selectedSpace={{
                    clusterId: scheduleFilterStore.selectedSpace.clusterId,
                    spaceId: scheduleFilterStore.selectedSpace.id,
                  }}
                  sendSelectedSpace={sendSelectedSpace}
                />
              </Col>
            </Row>
            <Row
              className={`align-items-start ${
                scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE
                  ? `mt-3`
                  : `my-3`
              } ${showScheduleLogView && `mb-3`}`}
            >
              <Col className={`col-12 col-xxl-9`}>
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <AppDatePicker
                      selectedDate={new Date(scheduleFilterStore.selectedDate)}
                      onChange={(date: Date) => {
                        onFilter(scheduleFilterStore.selectedSpace, date, {
                          value: scheduleFilterStore.managementMode.value,
                          label: scheduleFilterStore.managementMode.label,
                        });
                      }}
                      isInput={true}
                      showDate={false}
                    />
                  </Col>
                  <Col className="col-auto">
                    <NavigateWeeks
                      selectedDate={new Date(scheduleFilterStore.selectedDate)}
                      handleDateChange={(date: Date) => {
                        onFilter(scheduleFilterStore.selectedSpace, date, {
                          value: scheduleFilterStore.managementMode.value,
                          label: scheduleFilterStore.managementMode.label,
                        });
                        setIsLoading(false);
                      }}
                    />
                  </Col>
                  <Col className="text-light font-weight-500 font-size-12 col-auto">
                    {getMonthRangeToDisplay(weekRange)}
                  </Col>
                  <Col className="col-6 col-sm-auto mt-3 mt-sm-0 ps-sm-0">
                    <AppButton
                      text="This Week"
                      size="medium"
                      onClick={onClickThisWeekButton}
                      className="font-size-12 font-weight-500"
                      variant="transparentWithBorder"
                    />
                  </Col>
                  {!showScheduleLogView && (
                    <>
                      <Col className={`${styles.nowBtn} col-6 col-sm-auto`}>
                        <AppButton
                          text="Now"
                          size="medium"
                          onClick={() => {
                            setIsGoToCurrentTime(true);
                            onClickThisWeekButton();
                          }}
                          className="font-size-12 font-weight-500"
                          variant="transparentWithBorder"
                        />
                      </Col>
                      <Col
                        className="col-6 col-sm-auto"
                        onClick={() => {
                          onFilter(
                            scheduleFilterStore.selectedSpace,
                            new Date(scheduleFilterStore.selectedDate),
                            {
                              value: scheduleFilterStore.managementMode.value,
                              label: scheduleFilterStore.managementMode.label,
                            },
                            scheduleFilterStore.timeGridYAxis ===
                              ETimeGridYAxisType.DEVICE
                              ? ETimeGridYAxisType.DATE
                              : ETimeGridYAxisType.DEVICE
                          );
                        }}
                      >
                        <Row
                          className={`${styles.miniCalendarToggler} cursor-pointer align-items-center justify-content-center py-2 mx-0`}
                        >
                          <Col className="col-auto">
                            <MaterialIcon icon="swap_horiz" />
                          </Col>
                          <Col className="col-auto text-light font-size-12 font-weight-500">
                            {scheduleFilterStore.timeGridYAxis ===
                            ETimeGridYAxisType.DEVICE
                              ? ETimeGridYAxisType.DATE
                              : ETimeGridYAxisType.DEVICE}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  {scheduleFilterStore.managementMode.value ===
                    EManagementMode.SEMI_AUTO && (
                    <Col
                      className={`${
                        styles[`schedule-log-view-change-btn`]
                      } col-auto `}
                    >
                      <AppButton
                        text=""
                        iconName="playlist_add_check"
                        iconOnly
                        size="medium"
                        onClick={() => {
                          setShowScheduleLogView((ps) => !ps);
                        }}
                        variant={
                          showScheduleLogView
                            ? `light`
                            : `transparentWithBorder`
                        }
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              {!showScheduleLogView && (
                <Col
                  className={`${
                    scheduleFilterStore.timeGridYAxis ===
                      ETimeGridYAxisType.DEVICE && `d-none d-xxl-block`
                  } col-12 col-xxl-3 mt-3 mt-xxl-0`}
                >
                  <Row className="align-items-center justify-content-start justify-content-xxl-end">
                    <Col className="col-12 col-sm col-xxl-6 mt-2 mt-sm-0">
                      {weeklyScheduleEstimation}
                    </Col>
                    <Col className="col-12 col-sm col-xxl-6 mt-2 mt-sm-0">
                      {weeklySchedulePercentage}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {scheduleFilterStore.timeGridYAxis === ETimeGridYAxisType.DEVICE &&
              !showScheduleLogView && (
                <>
                  <Row className="ps-2 mt-2 mt-xxl-0">
                    <Col>
                      <MiniCalendar
                        startDate={weekRange[0]}
                        selectedDate={
                          new Date(scheduleFilterStore.selectedDate)
                        }
                        handleDateChange={(date: Date) => {
                          onFilter(scheduleFilterStore.selectedSpace, date, {
                            value: scheduleFilterStore.managementMode.value,
                            label: scheduleFilterStore.managementMode.label,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    <Col className="col-6 d-block d-xxl-none">
                      {weeklyScheduleEstimation}
                    </Col>
                    <Col className="col-6 d-block d-xxl-none">
                      {weeklySchedulePercentage}
                    </Col>
                  </Row>
                </>
              )}
            <div>
              <Row className="mt-2">
                <Col>
                  {showScheduleLogView &&
                  scheduleFilterStore.managementMode.value ===
                    EManagementMode.SEMI_AUTO ? (
                    <ScheduleLogViewContainer
                      showScheduleLogView={showScheduleLogView}
                      selectedDate={new Date(scheduleFilterStore.selectedDate)}
                    />
                  ) : (
                    <>
                      {scheduleFilterStore.managementMode.value ===
                      EManagementMode.SEMI_AUTO ? (
                        <>
                          {!noSmartDevices || events.length > 0 ? (
                            <div
                              className={`${
                                !noSmartDevices && styles.calendarContent
                              }`}
                            >
                              {calendarContent}
                            </div>
                          ) : (
                            <div className="container-dash mt-4">
                              <Row>
                                <Col className="text-center text-light font-size-12">
                                  This space does not contain any smart devices.
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {!noDevices ? (
                            <div
                              className={`${
                                !noSmartDevices && styles.calendarContent
                              }`}
                            >
                              {calendarContent}
                            </div>
                          ) : (
                            <div className="container-dash mt-4">
                              <Row>
                                <Col className="text-center text-light font-size-12">
                                  This space does not contain any devices.
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <SpinnerModal
              show={
                isFetchingViewSemiAutomationScheduleMonthlyEnergy ||
                isFetchingViewManualScheduleMonthlyEnergy ||
                isFetchingViewWeeklySemiAutomationSchedules ||
                isFetchingViewWeeklyManualSchedules ||
                isFetchingSpace
              }
            />
          </div>
          <ScheduleDeleteModal
            show={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onClose={() => setShowDeleteModal(false)}
            spaceClusterId={
              scheduleFilterStore.selectedSpaceDetails.spaceClusterId
            }
            subRootSpaceId={scheduleFilterStore.selectedSpaceDetails.spaceId}
            deleteScheduleData={deleteScheduleData}
            onSuccess={() => {
              setIsScheduleDeleted((ps) => !ps);
            }}
            selectedManagementMode={scheduleFilterStore.managementMode.value}
          />
        </>
      ) : (
        <>
          {!isFetchingSpaceCluster && (
            <div className="container-dash mt-4">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <SpinnerModal show={isFetchingSpaceCluster} />
    </div>
  );
};

export default Schedule;
