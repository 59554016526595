import { EEnergySourceType } from "../enums/energy-source-type";

export const energySourceIconType = (sourceType: EEnergySourceType) => {
  switch (sourceType) {
    case EEnergySourceType.SOLAR_PV:
      return "solar_power";
    case EEnergySourceType.WIND_TURBINE:
      return "energy";
    case EEnergySourceType.BATTERY:
      return "battery_charging_80";
    case EEnergySourceType.GENERATOR:
      return "charger";
    case EEnergySourceType.GRID:
      return "electric_meter";
  }
};
