import { EAcProtocolType } from "../enums/ac-protocol-type";

const getACProtocolType = (): {
  value: EAcProtocolType;
  label: string;
}[] => {
  const acProtocolType = [
    { value: EAcProtocolType.LG2, label: "LG2" },
    {
      value: EAcProtocolType.KELON,
      label: "KELON",
    },
    {
      value: EAcProtocolType.COOLIX,
      label: "COOLIX",
    },
    { value: EAcProtocolType.SONY, label: "SONY" },
    {
      value: EAcProtocolType.DAIKIN,
      label: "DAIKIN",
    },
    { value: EAcProtocolType.HAIER_AC, label: "HAIER_AC" },
    {
      value: EAcProtocolType.WHIRLPOOL_AC,
      label: "WHIRLPOOL_AC",
    },
    {
      value: EAcProtocolType.GREE,
      label: "GREE",
    },
    {
      value: EAcProtocolType.TCL112AC,
      label: "TCL112AC",
    },
    {
      value: EAcProtocolType.TCL96AC,
      label: "TCL96AC",
    },
    {
      value: EAcProtocolType.SAMSUNG,
      label: "SAMSUNG",
    },
    {
      value: EAcProtocolType.PRONTO,
      label: "PRONTO",
    },
    {
      value: EAcProtocolType.PIONEER,
      label: "PIONEER",
    },
    {
      value: EAcProtocolType.OTHER,
      label: "Other",
    },
  ];
  return acProtocolType;
};

export default getACProtocolType;
