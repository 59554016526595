import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ELimitationType } from "../../../shared/oversight-core/enums/limitation-type";
import { IUsageGuideFilter } from "../../../shared/oversight-core/interfaces/usage-guide-filter";
import { RootState } from "../../store";

const initialState: IUsageGuideFilter = {
  selectedDate: moment().valueOf(),
  effectLevel: ELimitationType.DEFAULT,
};

export const usageGuideFilterSlice = createSlice({
  name: "usageGuideFilter",
  initialState: { ...initialState },
  reducers: {
    setUsageGuideFilter: (state, action: PayloadAction<IUsageGuideFilter>) => {
      state.selectedDate = action.payload.selectedDate;
      state.effectLevel = action.payload.effectLevel;
    },
  },
});

export const { setUsageGuideFilter } = usageGuideFilterSlice.actions;

export const selectUsageGuideFilter = (state: RootState) =>
  state.usageGuideFilter;

export default usageGuideFilterSlice.reducer;
