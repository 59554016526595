import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwitchPowerConsumerMutation } from "../../../redux/api/controller/controllerAPI";
import {
  useLazyListPowerConsumersQuery,
  useRemoveDeviceMutation,
} from "../../../redux/api/device/deviceAPI";
import { useLazyGetSpacesQuery } from "../../../redux/api/space/spaceAPI";
import { useLazyProfileQuery } from "../../../redux/api/user/userAPI";
import {
  selectFilter,
  setFilter,
  setSelectedFilterSpace,
  setSmartControllerIdOfOpenedViewDeviceModal,
} from "../../../redux/features/filter/filter-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import DeviceListCard from "../../../shared/components/device-list-card/device-list-card";
import SpaceDeviceFilters from "../../../shared/components/space-device-filters/space-device-filters";
import {
  IListPowerConsumersGroupedResponseDTO,
  IListPowerConsumersResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/list-power-consumers-response-dto";
import SwitchPowerConsumerPowerStateByIdResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/switch-power-consumer-power-state-by-id-response-dto";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { IGroupedPowerConsumers } from "../../../shared/oversight-core/interfaces/grouped-power-consumer";
import { ISelectedSpace } from "../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceCluster } from "../../../shared/oversight-core/interfaces/space-cluster";
import { ISpaceClusterPowerConsumerViews } from "../../../shared/oversight-core/interfaces/space-cluster-power-consumer-views";
import IconButton from "../../../shared/oversight-core/ui-elements/buttons/icon-button/icon-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../shared/oversight-core/utils/findIcon";
import { showSwitchErrorMessage } from "../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../shared/oversight-core/utils/toast";
import AddUpdateSpaceModal from "./components/models/add-update-space-modal/add-update-space-modal";

const SpaceClusters = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [deviceListGrouped, setDeviceListGrouped] = useState<
    IGroupedPowerConsumers[]
  >([]);
  const [deviceListNotGrouped, setDeviceListNotGrouped] = useState<
    ISpaceClusterPowerConsumerViews[]
  >([]);
  const [groupedCollapsed, setGroupedCollapsed] = useState<boolean[]>([]);
  const [isPowerConsumerUpdated, setIsPowerConsumerUpdated] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceCluster[]>([]);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [
    switchPowerConsumerState,
    { isLoading: isLoadingSwitchPowerConsumerState },
  ] = useSwitchPowerConsumerMutation();

  const [getProfile, { isFetching: isFetchingProfile }] = useLazyProfileQuery();

  const updateCurrentState = async () => {
    if (profile && profile.serviceProvider === null) {
      await getProfile();
    }
  };

  const closeAddUpdateSpaceModal = () => {
    setShowAddUpdateSpaceModal(false);
  };

  const [removeDevice, { isLoading: isLoadingRemoveDevice }] =
    useRemoveDeviceMutation();
  const [
    triggerListPowerConsumers,
    { isFetching: isFetchingListPowerConsumers },
  ] = useLazyListPowerConsumersQuery();
  const [triggerGetSpaceClusters, { isFetching: isFetchingGetSpaceCluster }] =
    useLazyGetSpacesQuery();

  useEffect(() => {
    triggerGetSpaceClusters()
      .unwrap()
      .then((res) => {
        setSpaceClusters(res.spaceClusters);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, [triggerGetSpaceClusters]);

  const deleteDevice = (
    clusterId: string,
    spaceId: string,
    deviceId: string,
    keepInProgramme: boolean,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    removeDevice({
      clusterId,
      spaceId,
      deviceId,
      keepInProgramme,
      excludedSemiAutomatedSchedules,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Removed Successfully");
        setIsPowerConsumerUpdated(true);
      })
      .catch(() => {
        showErrorMessage("Removing Device Unsuccessful");
      });
  };

  useEffect(() => {
    if (filtersStore.selectedGroupBy === "Type") {
      if (
        filtersStore.selectedSpace.clusterId &&
        filtersStore.selectedSpace.id
      ) {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          groupByType: true,
          spaceClusterId: filtersStore.selectedSpace.clusterId,
          subRootSpaceId: filtersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersGroupedResponseDTO) => {
            setDeviceListGrouped(response.deviceList.elements);

            const temp: boolean[] = [];
            response.deviceList.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setDeviceListGrouped([]);
          });
      } else {
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          groupByType: true,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersGroupedResponseDTO) => {
            setDeviceListGrouped(response.deviceList.elements);

            const temp: boolean[] = [];
            response.deviceList.elements.forEach(() => {
              temp.push(false);
            });
            setGroupedCollapsed(temp);
          })
          .catch(() => {
            setDeviceListGrouped([]);
          });
      }
    } else {
      if (
        filtersStore.selectedSpace.clusterId &&
        filtersStore.selectedSpace.id
      ) {
        setIsFirstTime(true);
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          type: filtersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          spaceClusterId: filtersStore.selectedSpace.clusterId,
          subRootSpaceId: filtersStore.selectedSpace.id,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersResponseDTO) => {
            setDeviceListNotGrouped(response.deviceList.elements);
          })
          .catch(() => {
            setDeviceListNotGrouped([]);
          });
      } else {
        setIsFirstTime(true);
        triggerListPowerConsumers({
          searchField: "name",
          searchValue: filtersStore.searchText,
          type: filtersStore.selectedDeviceType,
          pageNumber: 0,
          pageSize: 1000,
          ascending: true,
        })
          .unwrap()
          .then((response: IListPowerConsumersResponseDTO) => {
            setDeviceListNotGrouped(response.deviceList.elements);
          })
          .catch(() => {
            setDeviceListNotGrouped([]);
          });
      }
    }
  }, [triggerListPowerConsumers, filtersStore, isPowerConsumerUpdated]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstTime(false);
      if (filtersStore.selectedGroupBy === "Type") {
        if (
          filtersStore.selectedSpace.clusterId &&
          filtersStore.selectedSpace.id
        ) {
          triggerListPowerConsumers({
            searchField: "name",
            searchValue: filtersStore.searchText,
            groupByType: true,
            spaceClusterId: filtersStore.selectedSpace.clusterId,
            subRootSpaceId: filtersStore.selectedSpace.id,
            ascending: true,
          })
            .unwrap()
            .then((response: IListPowerConsumersGroupedResponseDTO) => {
              setDeviceListGrouped(response.deviceList.elements);

              const temp: boolean[] = [];
              response.deviceList.elements.forEach(() => {
                temp.push(false);
              });
              setGroupedCollapsed(temp);
            })
            .catch(() => {
              setDeviceListGrouped([]);
            });
        } else {
          triggerListPowerConsumers({
            searchField: "name",
            searchValue: filtersStore.searchText,
            groupByType: true,
            ascending: true,
          })
            .unwrap()
            .then((response: IListPowerConsumersGroupedResponseDTO) => {
              setDeviceListGrouped(response.deviceList.elements);

              const temp: boolean[] = [];
              response.deviceList.elements.forEach(() => {
                temp.push(false);
              });
              setGroupedCollapsed(temp);
            })
            .catch(() => {
              setDeviceListGrouped([]);
            });
        }
      } else {
        if (
          filtersStore.selectedSpace.clusterId &&
          filtersStore.selectedSpace.id
        ) {
          triggerListPowerConsumers({
            searchField: "name",
            searchValue: filtersStore.searchText,
            type: filtersStore.selectedDeviceType,
            pageNumber: 0,
            pageSize: 1000,
            spaceClusterId: filtersStore.selectedSpace.clusterId,
            subRootSpaceId: filtersStore.selectedSpace.id,
            ascending: true,
          })
            .unwrap()
            .then((response: IListPowerConsumersResponseDTO) => {
              setDeviceListNotGrouped(response.deviceList.elements);
            })
            .catch(() => {
              setDeviceListNotGrouped([]);
            });
        } else {
          triggerListPowerConsumers({
            searchField: "name",
            searchValue: filtersStore.searchText,
            type: filtersStore.selectedDeviceType,
            pageNumber: 0,
            pageSize: 1000,
            ascending: true,
          })
            .unwrap()
            .then((response: IListPowerConsumersResponseDTO) => {
              setDeviceListNotGrouped(response.deviceList.elements);
            })
            .catch(() => {
              setDeviceListNotGrouped([]);
            });
        }
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [triggerListPowerConsumers, filtersStore, isPowerConsumerUpdated]);

  useEffect(() => {
    if (
      !filtersStore.selectedSpace.clusterId ||
      filtersStore.selectedGroupBy === "Type" ||
      filtersStore.selectedDeviceType ||
      (filtersStore.selectedSpace.clusterId && filtersStore.searchText)
    ) {
      return;
    }

    if (filtersStore.selectedSpace.id) {
      navigate(pathname + "/" + filtersStore.selectedSpace.id);
    }
  }, [filtersStore]);

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    badgeValue: number,
    isClear: boolean
  ) => {
    dispatch(
      setSelectedFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        badgeValue: badgeValue,
        isClear: isClear,
      })
    );
  };

  const updateDeviceStatus = (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    status: EDeviceStatus,
    isFromViewDeviceModal = false
  ) => {
    if (isFromViewDeviceModal) {
      dispatch(setSmartControllerIdOfOpenedViewDeviceModal(powerConsumerId));
    }

    switchPowerConsumerState({
      spaceClusterId,
      spaceId,
      powerConsumerId,
      switchingPowerState: status,
    })
      .unwrap()
      .then((res: SwitchPowerConsumerPowerStateByIdResponseDTO) => {
        updateCurrentState();
        setIsPowerConsumerUpdated(true);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const onViewDeviceModalClose = () => {
    dispatch(setSmartControllerIdOfOpenedViewDeviceModal(""));
  };

  return (
    <div className="position-relative">
      {spaceClusters.length > 0 && (
        <SpaceDeviceFilters
          updateFilters={updateFilters}
          isFetching={isFetchingListPowerConsumers && isFirstTime}
          updateCurrentState={updateCurrentState}
        />
      )}
      <div className="filtered-devices-list">
        <div className="my-4">
          {!filtersStore.selectedDeviceType &&
            !filtersStore.searchText &&
            filtersStore.selectedGroupBy === `Space` &&
            !filtersStore.selectedSpace.clusterId && (
              <IconButton
                icon="add"
                text="Create Billing Space"
                onClick={() => setShowAddUpdateSpaceModal(true)}
                variant="transparentWithBorder"
              />
            )}
        </div>
        {filtersStore.selectedGroupBy === "Type" && (
          <>
            {deviceListGrouped.length !== 0 ? (
              <>
                {deviceListGrouped.map(
                  (elements: IGroupedPowerConsumers, index) => {
                    return (
                      <div key={index} className="container-white mx-1 mt-4">
                        <Row
                          className="cursor-pointer"
                          onClick={() => {
                            setGroupedCollapsed((ps) => {
                              const temp = [...ps];
                              temp[index] = !temp[index];
                              return [...temp];
                            });
                          }}
                        >
                          <Col
                            className={`p-1 ${elements.group} rounded col-auto`}
                          >
                            <MaterialIcon
                              icon={findIcon(elements.group as EDeviceTypes)}
                              size={19}
                            />
                          </Col>
                          <Col className="text-dark font-size-16 font-weight-500">{`${elements.group}s`}</Col>
                          <Col className="col-auto">
                            <MaterialIcon
                              icon="expand_more"
                              className={`cursor-pointer dArrow`}
                              style={
                                groupedCollapsed[index]
                                  ? { transform: "rotate(-90deg)" }
                                  : { transform: "rotate(0deg)" }
                              }
                            />
                          </Col>
                        </Row>
                        {elements.spaceClusterPowerConsumers.map(
                          (spaceClusterPowerConsumer) => {
                            return (
                              <Row
                                key={spaceClusterPowerConsumer.powerConsumer.id}
                                className={`${
                                  groupedCollapsed[index] ? "d-none" : ""
                                } mt-2`}
                              >
                                <Col className="px-0">
                                  <DeviceListCard
                                    deleteDevice={deleteDevice}
                                    updateCurrentState={updateCurrentState}
                                    device={{
                                      ...spaceClusterPowerConsumer.powerConsumer,
                                      clusterId:
                                        spaceClusterPowerConsumer.spaceClusterId,
                                      spaceId:
                                        spaceClusterPowerConsumer.spaceId,
                                    }}
                                    mainSpaceClusterId={
                                      spaceClusterPowerConsumer.spaceClusterId
                                    }
                                    mainSpaceId={
                                      spaceClusterPowerConsumer.spaceId
                                    }
                                    path={spaceClusterPowerConsumer.path}
                                    updateDeviceStatus={updateDeviceStatus}
                                    setIsPowerConsumerUpdated={
                                      setIsPowerConsumerUpdated
                                    }
                                    smartDevices={spaceClusterPowerConsumer.linkedSmartDevices.map(
                                      (lsd) => {
                                        return {
                                          ...lsd,
                                          linkedPowerConsumer: lsd
                                            ? spaceClusterPowerConsumer.powerConsumer
                                            : null,
                                          clusterId:
                                            spaceClusterPowerConsumer.spaceClusterId,
                                          spaceId:
                                            spaceClusterPowerConsumer.spaceId,
                                        };
                                      }
                                    )}
                                    isDirectly={true}
                                    isUpdateDeviceStateLoading={
                                      isLoadingSwitchPowerConsumerState
                                    }
                                    powerConsumerIdOfOpenedViewDeviceModal={
                                      filtersStore.powerConsumerIdOfOpenedViewDeviceModal
                                    }
                                    onViewDeviceModalClose={
                                      onViewDeviceModalClose
                                    }
                                  />
                                </Col>
                              </Row>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <>
                {!isFetchingListPowerConsumers && (
                  <Row className="container-white mx-1 mt-4">
                    <div className="container-dash mt-4">
                      <Col className="text-center text-light font-size-12">
                        There are no devices available for the selected filters.
                      </Col>
                    </div>
                  </Row>
                )}
              </>
            )}
          </>
        )}

        {filtersStore.selectedGroupBy !== "Type" &&
          (filtersStore.selectedDeviceType || filtersStore.searchText) && (
            <Row className="container-white position-relative mx-1 mt-4">
              {deviceListNotGrouped.length !== 0 ? (
                <Col>
                  {filtersStore.selectedDeviceType && (
                    <Row className="align-items-center cursor-pointer">
                      <Col
                        className={`p-1 ${filtersStore.selectedDeviceType} rounded col-auto`}
                      >
                        <MaterialIcon
                          icon={findIcon(
                            filtersStore.selectedDeviceType as EDeviceTypes
                          )}
                          size={19}
                        />
                      </Col>
                      <Col className="text-dark font-size-16 font-weight-500">{`${filtersStore.selectedDeviceType}s`}</Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      {deviceListNotGrouped.map(
                        (
                          spaceClusterPowerConsumer: ISpaceClusterPowerConsumerViews
                        ) => {
                          return (
                            <Row
                              key={spaceClusterPowerConsumer.powerConsumer.id}
                              className="mt-2"
                            >
                              <Col className="px-0">
                                <DeviceListCard
                                  deleteDevice={deleteDevice}
                                  updateCurrentState={updateCurrentState}
                                  device={{
                                    ...spaceClusterPowerConsumer.powerConsumer,
                                    clusterId:
                                      spaceClusterPowerConsumer.spaceClusterId,
                                    spaceId: spaceClusterPowerConsumer.spaceId,
                                  }}
                                  mainSpaceClusterId={
                                    spaceClusterPowerConsumer.spaceClusterId
                                  }
                                  mainSpaceId={
                                    spaceClusterPowerConsumer.spaceId
                                  }
                                  path={spaceClusterPowerConsumer.path}
                                  updateDeviceStatus={updateDeviceStatus}
                                  setIsPowerConsumerUpdated={
                                    setIsPowerConsumerUpdated
                                  }
                                  smartDevices={spaceClusterPowerConsumer.linkedSmartDevices.map(
                                    (lsd) => {
                                      return {
                                        ...lsd,
                                        linkedPowerConsumer: lsd
                                          ? spaceClusterPowerConsumer.powerConsumer
                                          : null,
                                        clusterId:
                                          spaceClusterPowerConsumer.spaceClusterId,
                                        spaceId:
                                          spaceClusterPowerConsumer.spaceId,
                                      };
                                    }
                                  )}
                                  isDirectly={true}
                                  isUpdateDeviceStateLoading={
                                    isLoadingSwitchPowerConsumerState
                                  }
                                  powerConsumerIdOfOpenedViewDeviceModal={
                                    filtersStore.powerConsumerIdOfOpenedViewDeviceModal
                                  }
                                  onViewDeviceModalClose={
                                    onViewDeviceModalClose
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  {!isFetchingListPowerConsumers && (
                    <div className="container-dash mt-4">
                      <Col className="text-center text-light font-size-12">
                        There are no devices available for the selected filters.
                      </Col>
                    </div>
                  )}
                </>
              )}
            </Row>
          )}
      </div>
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceCreationType={"spaceCluster"}
        updateCurrentState={updateCurrentState}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        closeAddUpdateSpaceModal={closeAddUpdateSpaceModal}
        spaceClusterId={""}
        parentSpaceId={""}
      />
      <SpinnerModal
        show={
          isFetchingGetSpaceCluster ||
          isLoadingRemoveDevice ||
          isFetchingProfile ||
          isLoadingSwitchPowerConsumerState ||
          (isFetchingListPowerConsumers && isFirstTime)
        }
      />
    </div>
  );
};

export default SpaceClusters;
