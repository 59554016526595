import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import AddTimeRange, {
  ITimeRangeProps,
} from "../../../../../../shared/components/add-time-range/add-time-range";
import AddSemiAutomatedScheduledRequestDTO from "../../../../../../shared/oversight-core/dtos/request-dtos/add-semi-automated-scheduled-request-dto";
import { ERepetitionMode } from "../../../../../../shared/oversight-core/enums/repetition-mode";
import { ISchedulingPeriod } from "../../../../../../shared/oversight-core/interfaces/scheduling-period";
import AppDatePicker from "../../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppSwitch from "../../../../../../shared/oversight-core/ui-elements/app-switch/app-switch";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import { noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex } from "../../../../../../shared/oversight-core/utils/regex";
import { generateTimeRangeList } from "../../../../../../shared/oversight-core/utils/time-utils";

interface IFormInput
  extends Omit<AddSemiAutomatedScheduledRequestDTO, "dateTimeSlots"> {
  schedulingPeriods: ISchedulingPeriod[] | [];
}

interface IProps {
  times: ITimeRangeProps[];
  setTimes: React.Dispatch<React.SetStateAction<ITimeRangeProps[]>>;
  errors: FieldErrors;
  register: UseFormRegister<IFormInput>;
  isApplyForChildSpaces: boolean;
  setIsApplyForChildSpaces: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  selectedEndDate: Date;
  setSelectedEndDate: (data: Date) => void;
  selectedRepetition: Option;
  setSelectedRepetition: React.Dispatch<React.SetStateAction<Option>>;
  repetitionOptions: Option[];
  isErrors: boolean;
  setIsErrors: React.Dispatch<React.SetStateAction<boolean>>;
  isShowApplyForChildSpacesButton: boolean;
  endDateError: string;
}

const ScheduleInformation = (props: IProps) => {
  const {
    isApplyForChildSpaces,
    errors,
    selectedDate,
    selectedEndDate,
    selectedRepetition,
    repetitionOptions,
    isErrors,
    isShowApplyForChildSpacesButton,
    endDateError,
    setSelectedRepetition,
    setSelectedDate,
    setSelectedEndDate,
    setIsApplyForChildSpaces,
    register,
    setIsErrors,
  } = props;

  const startTime = new Date("2000-01-01T00:00:00");
  const endTime = new Date("2000-01-02T00:00:00");
  const intervalMinutes = 15;

  const [showExpireDate, setShowExpireDate] = useState(true);

  useEffect(() => {
    if (selectedRepetition.value === ERepetitionMode.DO_NOT_REPEAT) {
      setShowExpireDate(false);
    } else {
      setShowExpireDate(true);
    }
  }, [selectedRepetition]);

  return (
    <>
      <AppInput
        label="Title"
        placeholder="Title"
        name="title"
        register={register("title", {
          required: "Title is required",
          minLength: {
            value: 2,
            message: "Title must contain at least 2 characters",
          },
          maxLength: {
            value: 30,
            message:
              "You have exceeded the maximum number of 30 characters for the title",
          },
          pattern: {
            value: noSpecialCharsNoWhitespaceStartEndAndNotAllowCommaRegex,
            message:
              "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
          },
        })}
        errors={errors}
      />
      <div className="mt-4">
        <label>
          Date<span style={{ color: "red" }}>*</span>
        </label>
        <AppDatePicker
          dateFormat="dd MMMM yyyy"
          selectedDate={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          isInput={true}
        />
      </div>
      <div className="mt-4">
        <AddTimeRange
          times={props.times}
          setTimes={props.setTimes}
          timeOptions={generateTimeRangeList(
            startTime,
            endTime,
            intervalMinutes
          )}
          isErrors={isErrors}
          setIsErrors={setIsErrors}
        />
      </div>
      <AppSelect
        className="mt-4"
        label="Repetition"
        placeholder="Weekly on monday"
        selectedValue={selectedRepetition}
        onChangeOption={(selectedOption) =>
          setSelectedRepetition(selectedOption)
        }
        options={repetitionOptions}
        isRequired={true}
        id="schedule-repetition"
      />
      {showExpireDate && (
        <div className="mt-4">
          <label>
            Expire date<span style={{ color: "red" }}>*</span>
          </label>
          <AppDatePicker
            dateFormat="dd MMMM yyyy"
            selectedDate={selectedEndDate}
            onChange={(date) => setSelectedEndDate(date)}
            isInput={true}
            error={endDateError}
          />
        </div>
      )}
      {isShowApplyForChildSpacesButton && (
        <Row className="align-items-center mt-4">
          <Col xs={"auto"} className="text-dark font-weight-400">
            Apply for child spaces?
          </Col>
          <Col>
            <AppSwitch
              isOn={isApplyForChildSpaces}
              size="sm"
              onSwitch={() => setIsApplyForChildSpaces((ps) => !ps)}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ScheduleInformation;
