import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLazyUnLinkSmartPlugListQuery,
  useUpdateAcControllerMutation,
  useUpdateControllerMutation,
} from "../../../../../../redux/api/controller/controllerAPI";
import { useLazyGetSpacesQuery } from "../../../../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import SocketCard from "../../../../../../shared/components/socket-card/socket-card";
import { EConnectionStatus } from "../../../../../../shared/oversight-core/enums/connection-status";
import { OvstErrorCode } from "../../../../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../../../../shared/oversight-core/enums/smart-controller-type";
import { ESmartPlugType } from "../../../../../../shared/oversight-core/enums/smart-plug-type";
import { AppRoute } from "../../../../../../shared/oversight-core/interfaces/app-routes";
import { IPowerConsumerView } from "../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import { ISpaceView } from "../../../../../../shared/oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../../../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceClusterSmartPlugViews } from "../../../../../../shared/oversight-core/interfaces/space-cluster-smart-plug-views";
import ActiveInactiveIndicator from "../../../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppButton from "../../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import Pagination from "../../../../../../shared/oversight-core/ui-elements/pagination/pagination";
import Search from "../../../../../../shared/oversight-core/ui-elements/search/search";
import SpaceSelectorDropdown from "../../../../../../shared/oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../../../../../shared/oversight-core/utils/findIcon";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";
import GeneralControllerLinkConfirmModal from "../general-controller-link-confirm-modal/general-controller-link-confirm-modal";
import styles from "./controller-link-modal.module.scss";
interface IProps extends ModelContainerProps {
  show: boolean;
  device: IPowerConsumerView;
  setIsPowerConsumerUpdated:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  deviceConnectionState: EConnectionStatus | undefined;
  smartDeviceType: ESmartControllerType;
}

const ITEM_PER_PAGE = 3;

const ControllerLinkModal = (props: IProps) => {
  const navigate = useNavigate();

  const { deviceConnectionState, smartDeviceType, show, ...rest } = props;

  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);

  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    searchValue: "",
    pageNumber: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [allSpaceTotalElements, setAllSpacesTotalElements] = useState(0);
  const [selectedController, setSelectedController] = useState<
    ISpaceClusterSmartPlugViews | undefined
  >(undefined);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    id: billingSpaceFilter.spaceCluster.rootSpace.id,
    name: billingSpaceFilter.spaceCluster.label,
    clusterId: billingSpaceFilter.spaceCluster.id,
  });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [filteredController, setFilteredController] = useState<
    ISpaceClusterSmartPlugViews[]
  >([]);
  const [
    showGeneralControllerLinkConfirmModal,
    setShowGeneralControllerLinkConfirmModal,
  ] = useState(false);
  const [showFilterElements, setShowFilterElements] = useState(false);

  const [triggerGetSpaces] = useLazyGetSpacesQuery();
  const [
    triggerUnlinkedSmartPlugList,
    { isFetching: isLoadingUnlinkSmartPlugList },
  ] = useLazyUnLinkSmartPlugListQuery();

  const [updateController, { isLoading: isLoadingUpdateController }] =
    useUpdateControllerMutation();

  const [updateAcController, { isLoading: isLoadingUpdateAcController }] =
    useUpdateAcControllerMutation();

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        let spaceClusterView: ISpaceView[] = [];

        for (const spaceCluster of response.spaceClusters) {
          spaceClusterView.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        spaceClusterView = spaceClusterView.sort((a, b) =>
          a.name.trim().localeCompare(b.name.trim())
        );
        setSpaceClusters([...spaceClusterView]);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  useEffect(() => {
    if (selectedSpace.clusterId && selectedSpace.id) {
      triggerUnlinkedSmartPlugList({
        searchField: "name",
        spaceClusterId: selectedSpace.clusterId,
        subRootSpaceId: selectedSpace.id,
        pageNumber: filters.pageNumber,
        searchValue: filters.searchValue,
        ascending: true,
        pageSize: ITEM_PER_PAGE,
        smartDeviceType,
      })
        .unwrap()
        .then((res) => {
          setFilteredController(res.smartDevices.elements);
          setTotalElements(res.smartDevices.totalElements);
          if (
            selectedSpace.id === billingSpaceFilter.spaceCluster.rootSpace.id &&
            selectedSpace.clusterId === billingSpaceFilter.spaceCluster.id
          ) {
            setAllSpacesTotalElements(res.smartDevices.totalElements);
          }
        })
        .catch(() => {
          setFilteredController([]);
          setTotalElements(0);
          setAllSpacesTotalElements(0);
        });
    }
  }, [selectedSpace.clusterId, selectedSpace.id, filters, show]);

  useEffect(() => {
    if (show) {
      setSelectedSpace({
        id: billingSpaceFilter.spaceCluster.rootSpace.id,
        name: billingSpaceFilter.spaceCluster.label,
        clusterId: billingSpaceFilter.spaceCluster.id,
      });
      setSelectedController(undefined);
      setSearchInput("");
    }
  }, [show, billingSpaceFilter]);

  useEffect(() => {
    setShowFilterElements(allSpaceTotalElements > 0 || searchInput !== "");
  }, [allSpaceTotalElements]);

  const linkHandler = () => {
    if (selectedController) {
      if (smartDeviceType === ESmartControllerType.SMART_PLUG) {
        updateController({
          spaceClusterId: selectedController.spaceClusterId,
          spaceId: selectedController.spaceId,
          smartPlugId: selectedController.smartDevice.id,
          name: selectedController.smartDevice.name,
          linkedPowerConsumerId: props.device.id,
          smartPlugType: ESmartPlugType.SPECIFIC,
          isLinkOnly: true,
        })
          .unwrap()
          .then(() => {
            showSuccessMessage("Device Linked Successfully");
            props.onClose && props.onClose();
            props.setIsPowerConsumerUpdated &&
              props.setIsPowerConsumerUpdated((ps) => !ps);
          })
          .catch(() => {
            showErrorMessage("Device Linked Unsuccessful");
          });

        return;
      }

      updateAcController({
        spaceClusterId: selectedController.spaceClusterId,
        spaceId: selectedController.spaceId,
        acControllerId: selectedController.smartDevice.id,
        name: selectedController.smartDevice.name,
        linkedAcId: props.device.id,
        isLinkOnly: true,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Linked Successfully");
          props.onClose && props.onClose();
          props.setIsPowerConsumerUpdated &&
            props.setIsPowerConsumerUpdated((ps) => !ps);
        })
        .catch((error) => {
          if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
          ) {
            showErrorMessage(
              "Failure in one of the services. Please try again later."
            );
            return;
          }
          showErrorMessage("Device Linked Unsuccessful");
        });
    }
  };

  return (
    <>
      <ModalContainer
        {...rest}
        title={"Link Device to"}
        show={show}
        isLoading={isLoadingUpdateController || isLoadingUpdateAcController}
        size="modal-lg"
        confirmButtonText="Link"
        disabled={filteredController.length < 1}
        cancelButtonText="Cancel"
        className="mt-0"
        onConfirm={() => {
          if (
            selectedController?.smartDevice.smartPlugType ==
            ESmartPlugType.GENERAL
          ) {
            setShowGeneralControllerLinkConfirmModal(true);
            return;
          }
          linkHandler();
        }}
      >
        <>
          <Row className="align-items-center mx-0">
            <Col
              className={`${props.device.deviceType} p-3 rounded`}
              xs={"auto"}
            >
              <MaterialIcon icon={findIcon(props.device.deviceType)} />
            </Col>
            <Col>
              <Row>
                <Col
                  className="font-size-14 font-weight-500 text-dark"
                  xs={"auto"}
                >
                  {props.device.name}
                </Col>
              </Row>
              <Row className="align-items-center g-2">
                <Col
                  className="font-size-12 font-weight-400 text-light"
                  xs={"auto"}
                >
                  {props.device.powerUsageInWatt}W
                </Col>
                {deviceConnectionState && (
                  <Col>
                    <ActiveInactiveIndicator
                      isActive={
                        deviceConnectionState === EConnectionStatus.CONNECTED
                      }
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {showFilterElements && (
            <>
              <Row className="mt-4">
                <Col xs={12} sm={8} md={6} className="mt-3 mt-sm-0">
                  <Search
                    placeholder="Search by Controller name"
                    onSearch={() => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        searchValue: searchInput,
                        pageNumber: 0,
                      }));
                    }}
                    handleSearchChange={(input) => {
                      setSearchInput(input);
                      if (!input) {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          searchValue: input,
                          pageNumber: 0,
                        }));
                      }
                    }}
                    value={searchInput}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <SpaceSelectorDropdown
                    dropdownOptions={[
                      ...spaceClusters
                        .filter(
                          (space) =>
                            space.clusterId ===
                            billingSpaceFilter.spaceCluster.id
                        )
                        .map((space) => {
                          return {
                            clusterId: space.clusterId,
                            id: space.id,
                            name: space.name,
                            childSpaces: space.childSpaces,
                          };
                        }),
                    ]}
                    disabled={false}
                    selectedSubSpace={selectedSpace.name}
                    updateSelectedSubSpaceId={(
                      selectedSubSpaceId: string,
                      selectedSubSpaceName: string,
                      clusterId?: string
                    ) => {
                      setSelectedSpace({
                        id: selectedSubSpaceId,
                        name: selectedSubSpaceName,
                        clusterId: clusterId || "",
                      });
                      setFilters({
                        searchValue: "",
                        pageNumber: 0,
                      });
                    }}
                    label="Space"
                    className="font-weight-500 text-light"
                    maxHeight={"250px"}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row className="mt-2 mx-0">
            {!isLoadingUnlinkSmartPlugList ? (
              <>
                {filteredController.length > 0 ? (
                  filteredController.map((controller, index) => (
                    <div key={index} className="mt-2">
                      <SocketCard
                        controller={controller}
                        onChange={(controller) =>
                          setSelectedController(controller)
                        }
                        selectedController={selectedController}
                      />
                    </div>
                  ))
                ) : (
                  <Row
                    className={`mt-3 mx-1 justify-content-center align-items-center container-dash ${styles.containerDash}`}
                  >
                    <Col
                      xs="12"
                      sm="auto"
                      className="font-size-14 p-0 text-center text-light"
                    >
                      There are no available{" "}
                      {smartDeviceType === ESmartControllerType.AC_CONTROLLER
                        ? `AC`
                        : `smart`}{" "}
                      controllers.
                    </Col>
                    <Col xs="12" sm="auto" className="ps-1 text-center mt-0">
                      <AppButton
                        text={`Add ${
                          smartDeviceType === ESmartControllerType.AC_CONTROLLER
                            ? `AC`
                            : `Smart`
                        } Controller`}
                        variant="transparent"
                        size="medium"
                        onClick={() => navigate(AppRoute.ADD_CONTROLLER)}
                        className=" p-0"
                      />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <SpinnerModal show={isLoadingUnlinkSmartPlugList} />
            )}
          </Row>
          <Pagination
            itemsPerPage={ITEM_PER_PAGE}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                pageNumber: pn - 1,
              }));
            }}
          />
        </>
      </ModalContainer>
      <GeneralControllerLinkConfirmModal
        show={showGeneralControllerLinkConfirmModal}
        onClose={() => setShowGeneralControllerLinkConfirmModal(false)}
        onCancel={() => setShowGeneralControllerLinkConfirmModal(false)}
        onConfirm={() => {
          linkHandler();
          setShowGeneralControllerLinkConfirmModal(false);
        }}
      />
    </>
  );
};

export default ControllerLinkModal;
